/* eslint-disable */
/* eslint-disable react/forbid-prop-types */
import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import queryString from 'query-string'
import { Snackbar } from '@minha-biblioteca/saraiva-ui'
import Loading from '../../../components/Loading/index.js'
import services from '../../../services'
import { setSessionToken, parseJwt } from '../../../auth'

class LtiAuthentication extends Component {
  constructor(props) {
    super(props)

    this.state = {
      showError: false,
      authenticated: false,
      bookId: null
    }
  }

  async componentDidMount() {
    try {
      const parsed = queryString.parse(window.location.search)
      const response = await services.Sessions.loginByLti(parsed.id_token)
      const customCaeIdKey = 'https://purl.imsglobal.org/spec/lti/claim/custom'

      let parsedJwt = null
      let caeId = null

      if (parsed.id_token) {
        parsedJwt = parseJwt(parsed.id_token)
      }

      if (parsedJwt.hasOwnProperty(customCaeIdKey)) {
        caeId = parsedJwt[customCaeIdKey].cae_id
      }

      if (!response.ok) {
        this.setState({ showError: true})
        const errors = await response.json()
        throw errors
      }

      const result = await response.json()

      this.setAuthenticationDataAndUpdateState(
        result.tokens.local_token,
        caeId
      )

      services.Trackings.integrationLogin()
    } catch (err) {
      console.error(err)
    }
  }

  setAuthenticationDataAndUpdateState(local_token, caeId) {
    setSessionToken(local_token)
    this.setState({ authenticated: true, bookId: caeId })
  }

  closeSnackbar() {
    this.setState({ showError: false })
  }

  render() {
    const { authenticated, bookId, showError } = this.state

    if (authenticated && !bookId) {
      return <Redirect to="/books" />
    }

    if (authenticated && bookId) {
      return <Redirect to={`/books/${bookId}`} />
    }

    return (
      <>
        <Snackbar
          show={showError}
          severity="error"
          handleClose={() => this.closeSnackbar()}
          autoHideDuration={3000}
        >
          Ocorreu um erro no login.
        </Snackbar>

        <Loading />
      </>
    )
  }
}

export default LtiAuthentication
