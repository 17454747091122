import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  recentOpened: {
    marginBottom: 12,
    marginTop: 0,
    fontSize: 20,
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.6,
    letterSpacing: '0.15px',
    color: 'rgba(33, 37, 41, 1)',
    [theme.breakpoints.down(576)]: {
      fontSize: 16,
      lineHeight: 1.5
    }
  },
  divider: {
    marginBottom: 24,
    [theme.breakpoints.down(769)]: {
      marginBottom: 48.5
    },
    [theme.breakpoints.down(1024)]: {
      marginBottom: 24
    },
    [theme.breakpoints.down(426)]: {
      marginBottom: 24
    },
    [theme.breakpoints.down(348)]: {
      marginBottom: 24
    }
  },

  recentOpenedContainer: {
    display: 'flex',
    gap: 20,
    overflowY: 'auto',
    padding: 2,
    [theme.breakpoints.down(768)]: {
      '&::-webkit-scrollbar': {
        background: 'transparent'
      }
    },
    '& .MuiCard-root': {
      margin: '0px !important',
      minWidth: 156,
      '& .MuiTypography-h6': {
        fontSize: 15
      },
      [theme.breakpoints.down(426)]: {
        minWidth: 128
      }
    }
  }
}))

export default useStyles
