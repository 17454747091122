"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _Fusion = _interopRequireDefault(require("../request/Fusion"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
function setPage(applicationToken, sessionToken, uuid, userId, payload) {
  return _Fusion.default.patch("/booknotes/api/v1/pages/".concat(uuid, "?&user_id=").concat(userId), payload, {
    headers: {
      'application-token': applicationToken,
      'session-token': sessionToken
    }
  });
}
function getPage(applicationToken, sessionToken, uuid, userId) {
  return _Fusion.default.get("/booknotes/api/v1/pages/".concat(uuid, "?&user_id=").concat(userId), {
    headers: {
      'application-token': applicationToken,
      'session-token': sessionToken
    }
  }).then(response => response.data);
}
var Page = {
  setPage,
  getPage
};
var _default = exports.default = Page;