"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.usePopperIconStyles = exports.useModalStyles = exports.useDefaultTooltipStyles = exports.useDefaultButtonStyles = exports.useCustomTooltipStyles = exports.useCustomButtonStyles = void 0;
var _styles = require("@material-ui/core/styles");
const useDefaultTooltipStyles = exports.useDefaultTooltipStyles = (0, _styles.makeStyles)(() => ({
  tooltip: {
    margin: '-5px 0 0 0',
    height: '24px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    borderRadius: '4px'
  }
}));
const useCustomTooltipStyles = exports.useCustomTooltipStyles = (0, _styles.makeStyles)(theme => {
  const colorsList = {
    primary: theme.palette.primary.main,
    secondary: theme.palette.secondary.main,
    grey: theme.palette.grey[600]
  };
  return {
    tooltip: {
      backgroundColor: props => colorsList[props.color],
      maxWidth: props => props.width,
      width: '100%',
      padding: theme.spacing(0)
    },
    arrow: {
      color: props => colorsList[props.color]
    }
  };
});
const useDefaultButtonStyles = exports.useDefaultButtonStyles = (0, _styles.makeStyles)(() => ({
  root: {
    '&:hover': {
      backgroundColor: 'initial'
    }
  }
}));
const useCustomButtonStyles = exports.useCustomButtonStyles = (0, _styles.makeStyles)(theme => ({
  root: {
    border: 'none',
    background: 'none',
    outline: 'transparent',
    WebkitTapHighlightColor: 'transparent',
    padding: 0,
    lineHeight: 0,
    minWidth: 'initial',
    '&:hover': {
      backgroundColor: 'initial'
    }
  },
  sizeSmall: {
    '& svg': {
      fontSize: theme.typography.pxToRem(20)
    }
  }
}));
const usePopperIconStyles = exports.usePopperIconStyles = (0, _styles.makeStyles)(theme => ({
  fontSizeSmall: {
    fontSize: theme.typography.pxToRem(20)
  }
}));
const useModalStyles = exports.useModalStyles = (0, _styles.makeStyles)(() => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}));