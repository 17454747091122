/* eslint-disable */
/* eslint-disable react/forbid-prop-types */
import React from 'react'
import PropTypes from 'prop-types'
import PdfReader from '@minha-biblioteca/pdf-reader'
import { getSessionToken, getApplicationToken } from '../../auth'
import Users from '../../services/accounts/users'
import { toCamelCase } from '../../utils'
import ErrorBoundary from './ErrorBoundary'

const bookTitleFromSearch = (search) => {
  const filteredSearch = search.split("?title=")[1]
                             .replaceAll("%20", " ")
                             .replaceAll("%C3%87", "ç")

  return toCamelCase(filteredSearch)
}

export default function Reader({ history, match }) {
  const { uuid } = match.params
  const { search } = history.location

  return (
    <ErrorBoundary>
      <PdfReader
        bookTitle={bookTitleFromSearch(search)}
        uuid={uuid}
        userId={Users.getUserId()}
        applicationToken={getApplicationToken()}
        sessionToken={getSessionToken()}
      />
    </ErrorBoundary>
  )
}

Reader.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired
}
