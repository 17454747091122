"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _styles = require("@material-ui/core/styles");
var useStyles = (0, _styles.makeStyles)(theme => ({
  root: {
    padding: '0 24px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  rootMobile: {
    padding: '0 24px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  top: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginBottom: 8
  },
  title: {
    margin: '0 16px 0 0',
    color: theme.palette.text.secondary,
    fontSize: 20,
    fontWeight: 500
  },
  titleMobile: {
    margin: '0 8px 0 0',
    color: theme.palette.text.secondary,
    fontSize: 16,
    fontWeight: 500
  },
  label: {
    margin: 0,
    color: theme.palette.text.secondary,
    fontSize: 20,
    fontWeight: 500,
    marginBottom: 16
  },
  labelMobile: {
    margin: 0,
    color: theme.palette.text.secondary,
    fontSize: 16,
    fontWeight: 500,
    marginBottom: 8
  },
  divider: {
    borderTop: '1px solid #E0E0E0',
    width: '100%',
    margin: '24px 0'
  },
  dividerMobile: {
    borderTop: '1px solid #E0E0E0',
    width: '100%',
    margin: '24px 0'
  },
  reference: {
    margin: 0,
    fontSize: 16,
    outline: '0px solid transparent'
  },
  referenceMobile: {
    margin: 0,
    fontSize: 12,
    outline: '0px solid transparent'
  },
  snackBar: {
    width: 279,
    position: 'fixed',
    zIndex: 1000,
    top: 16,
    right: 12
  }
}));
var _default = exports.default = useStyles;