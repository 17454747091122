import React, { useState, useEffect } from 'react';
import Joyride from 'react-joyride';
import { useMediaQuery } from '@mantine/hooks';
import CustomTooltip from './customTooltip';
import BREAKPOINTS from '../../constants/Breakpoints';

export default function GuidedTour() {
  const [tourVisible, setTourVisible] = useState(false);
  const isDesktop = useMediaQuery(`(min-width: ${BREAKPOINTS.md})`);

  const steps = [
    {
      target: '#leme-navbar',
      disableBeacon: true,
      placement: 'bottom',
    },
  ];

  useEffect(() => {
    const isFirstAccess = !localStorage.getItem('tourCompleted');

    if (isFirstAccess && isDesktop) {
        setTourVisible(true);
    }
  }, [isDesktop]);

  const handleJoyrideCallback = (data) => {
    const { status } = data;
    const finishedStatuses = ['finished', 'skipped'];

    if (finishedStatuses.includes(status)) {
      localStorage.setItem('tourCompleted', 'true');
      setTourVisible(false);
    }
  };

  return (
    <>
      {tourVisible && (
        <Joyride
          steps={steps}
          continuous={true}
          showSkipButton={true}
          callback={handleJoyrideCallback}
          tooltipComponent={CustomTooltip}
          styles={{
            options: {
              arrowColor: 'transparent',
              zIndex: 10000,
            },
            tooltip: {
              padding: 0,
            },
            arrow: {
              display: 'none',
            },
          }}
        />
      )}
    </>
  );
}