/* eslint-disable react/prop-types */
import React, { createContext, useState } from 'react'
import jwtDecode from 'jwt-decode'

const LibraryContext = createContext()

const LibraryContextProvider = ({ children }) => {
  const [libraryCode, setLibraryCode] = useState(() => {
    const tokenDegustacao = sessionStorage.getItem('tokenDesgutacao')
    const decode = tokenDegustacao ? jwtDecode(tokenDegustacao) : {}

    if (decode?.library_code === 'amostra_escritorio') {
      setTimeout(() => {
        localStorage.removeItem('sessionToken')
      }, 1000)
      return true
    }
    return false
  })

  return (
    <LibraryContext.Provider value={{ libraryCode, setLibraryCode }}>
      {children}
    </LibraryContext.Provider>
  )
}

export { LibraryContext, LibraryContextProvider }
