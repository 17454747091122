/* eslint-disable react/forbid-prop-types */
import React from 'react'
import { makeStyles, Typography } from '@material-ui/core'
import { Alert, Button } from '@minha-biblioteca/saraiva-ui'
import { useHistory } from 'react-router-dom'

import imgUnavailable from './homem-livro-indisponivel.png'

const useStyle = makeStyles((theme) => ({
  page: {
    position: 'absolute',
    display: 'flex',
    top: '50%',
    transform: 'translateY(-50%)',
    maxWidth: '931px',
    [theme.breakpoints.down(576)]: {
      paddingRight: '16px'
    }
  },
  mainContent: {
    width: '60%',
    display: 'flex',
    flexFlow: 'column',
    [theme.breakpoints.down(576)]: {
      width: '100%'
    }
  },
  backButton: {
    marginTop: 24,
    alignSelf: 'flex-end',
    [theme.breakpoints.down(576)]: {
      width: '100%',
      display: 'flex',
      flexFlow: 'column'
    }
  },
  message: {
    color: theme.palette.text.primary,
    marginBottom: 32,
    fontSize: theme.typography.h4.fontSize,
    [theme.breakpoints.down(576)]: {
      marginBottom: 24,
      fontSize: theme.typography.h5.fontSize
    }
  },
  imageWrapper: {
    width: '40%',
    paddingLeft: 64,
    [theme.breakpoints.down(576)]: {
      display: 'none',
      padding: 0
    }
  },
  image: {
    maxHeight: 333,
    maxWidth: 237
  }
}))

export default function Unavailable() {
  const classes = useStyle()
  const history = useHistory()

  return (
    <div className={classes.page}>
      <div className={classes.mainContent}>
        <Typography className={classes.message}>
          Olá, este livro está indisponível no momento.
        </Typography>
        <Alert severity="info">
          Reporte a situação para a administração de sua Biblioteca Digital e
          busque por outro título, autor, ISBN ou assunto.
        </Alert>
        <div className={classes.backButton}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => history.goBack()}
          >
            Voltar
          </Button>
        </div>
      </div>

      <div className={classes.imageWrapper}>
        <img
          src={imgUnavailable}
          alt="Ilustração de uma pessoa andando para a esquerda"
          className={classes.image}
        />
      </div>
    </div>
  )
}
