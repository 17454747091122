/* eslint-disable */
import React, { useContext, useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Snackbar } from '@minha-biblioteca/saraiva-ui'
import {SnackBar as Snack} from '../../components/SnackBar'
import { makeStyles } from '@material-ui/core'

import Content from './Content/index.js'
import BackLink from '../../components/BackLink/index.js'
import Loading from '../../components/Loading/index.js'
import services from '../../services'
import {
  redirectToLogin,
  isUnauthorized,
  isInvalidTicket,
  setSessionToken
} from '../../auth'
import { BookContext } from '../../contexts/BookContext'
import { setScrollPostion } from '../../utils/index.js'
import { CustomSnackBar } from '../../components/SnackBar/styles.js'

const notificationStyle = makeStyles((theme) => ({
  root: {
    top: 0,
    right: 0,
    marginTop: 88,
    marginRight: 8,
    [theme.breakpoints.down(835)]: {
      marginTop: 20,
      marginRight: 16
    }
  },
  wrapper: {
    paddingTop: 72,
    [theme.breakpoints.down(576)]: {
      paddingTop: 32
    }
  }
}))

export default function Book({ match }) {
  const notificationClasses = notificationStyle()
  const { showingCopyMessage, setCopyMessage } = useContext(BookContext)
  const history = useHistory()
  const [book, setBook] = useState({})
  const [loading, setLoading] = useState(true)
  const { uuid } = match.params
  const [showAlert, setShowAlert] = useState(false)
  const [message, setMessage] = useState('Livro adicionado aos favoritos')
  const [severity, setSeverity] = useState('success')

  useEffect(() => {
    const findBook = async () => {
      setLoading(true)

      try {
        const response = await services.Books.getBook(uuid)

        setSessionToken(response.headers.get('session-token'))

        if (isUnauthorized(response.status)) {
          redirectToLogin()
        }

        if (isInvalidTicket(response.status)) {
          history.push('/tickets/activate?no-access=true')
        }

        if (!response.ok) {
          const errors = await response.json()
          throw errors
        }

        const result = await response.json()

        setBook(result)
      } catch (err) {
        history.push('/BookError')
      } finally {
        setLoading(false)
      }
    }

    findBook()
    setScrollPostion(0, 0)
  }, [uuid])

  const renderCopyMessage = () => {
    return (
      <Snackbar
        handleClose={() => setCopyMessage(false)}
        severity="success"
        classes={() => notificationClasses}
        show={showingCopyMessage}
        autoHideDuration={5000}
      >
        Referência bibliográfica copiada para área de transferência.
      </Snackbar>
    )
  }
  const bookCallback = (message) => {
    setMessage(message)
    setShowAlert(true)
    setTimeout(async() => {
      setShowAlert(false)
    }, 2000)
  }

  const renderFavoritesMessages = () => {
    return (
        <div style={{top:0}}>
          {
            showAlert && (
                <Snack show={false} severity={severity} variant="standart" noClose>{message}</Snack>
              )
          }
        </div>
    )
  }

  if (loading) return <Loading />

  return (
    <div>
      {renderFavoritesMessages()}
      <div className={notificationClasses.wrapper}>
        {renderCopyMessage()}


        <BackLink />

        <Content book={book} bookCallback={(message) => bookCallback(message)} fluxo='book' />
      </div>
    </div>
  )
}

Book.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
}
