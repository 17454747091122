"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _styles = require("@material-ui/core/styles");
var _default = exports.default = (0, _styles.makeStyles)(theme => ({
  wrapper: {
    display: 'flex',
    maxHeight: 56,
    width: '100%',
    position: 'fixed',
    padding: '12px 0',
    left: 0,
    top: 0,
    zIndex: 1000,
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: 'var(--leme-color-gray-1)',
    [theme.breakpoints.up(770)]: {
      display: 'none'
    }
  },
  icon: {
    marginRight: 8,
    color: theme.palette.text.secondary
  },
  mi: {
    padding: '0 16px',
    minHeight: 36
  },
  btn: {
    fontSize: 16,
    paddingLeft: 0,
    fontWeight: 400
  }
}));