/* eslint-disable */
/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { SaraivaTheme } from '@minha-biblioteca/saraiva-ui'
import Header from '../components/Header'
import { BooksProvider } from '../contexts/BooksContext'
import Drawer from '../components/Drawer/'
import { PrivacyFooter } from '@minha-biblioteca/saraiva-ui'
import { Footer } from '@minha-biblioteca/saraiva-ui/core/v2'
import { CookieBar } from '@minha-biblioteca/saraiva-ui'
import { isIOS } from 'react-device-detect'
import services from '../services/'
import { useLocation } from 'react-router'
import Logotipo from '../assets/logo.svg'
import { useNavigation } from '../hooks/useNavigation'
import Trackings from '../services/trackings/trackings'
import GuidedTour from '../components/GuidedTour'
import 'material-symbols/outlined.css'
import MobileNavbar from '../components/MobileNavbar'
import { SearchProvider } from '../contexts/SearchContext'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    paddingTop: 64,
    backgroundColor: 'var(--leme-color-gray-1)',
    height: '100vh',
    [theme.breakpoints.down(600)]: {
      paddingTop: 72
    }
  },
  layoutContainer: {
    maxHeight: 'calc(100vh - 72px)',
    overflowY: 'auto',
    overflowX: 'hidden',
    marginTop: '0.5rem',
    width: '100%',
    [theme.breakpoints.down(600)]: {
      maxHeight: isIOS
        ? 'calc(100vh - 72px - 96px)'
        : 'calc(100vh - 72px - 72px)'
    }
  },

  contentContainer: {
    maxWidth: 931,
    width: '100%',
    margin: 'auto',
    minHeight: 'calc(100vh - 72px)',
    textAlign: 'left',
    [theme.breakpoints.down(1300)]: {
      padding: '0 20px'
    },
    [theme.breakpoints.down(600)]: {
      minHeight: isIOS
        ? 'calc(100vh - 72px - 96px)'
        : 'calc(100vh - 72px - 72px)'
    }
  },
  contentContainerNoGutters: {
    padding: 0
  },
  footer: {
    width: '100%',
    marginTop: 106,
    zIndex: 999,
    textAlign: 'left',
    '& .MuiAppBar-root': {
      zIndex: '0 !important'
    }
  },
  cookie: {
    right: 73,
    left: 166,
    position: 'fixed',
    bottom: 32,
    zIndex: 9999,
    [theme.breakpoints.down('xs')]: {
      right: 10,
      left: 10,
      bottom: isIOS ? 82 : 62
    }
  },
  cookiebar: {
    '& button': {
      minWidth: 65
    },
    '& a': {
      color: '#245A93'
    },
    [theme.breakpoints.down('xs')]: {
      '& button': {
        minWidth: 65
      }
    }
  }
}))

export default function Layout({
  children,
  onSearch,
  searchPlaceHolder,
  noGutters,
  noFooter
}) {
  const classes = useStyles()
  const [lgpd, setLgpd] = useState(false)
  const location = useLocation()
  const [swithPage] = useNavigation()

  useEffect(() => {
    setLgpd(localStorage.getItem('lgpd'))
  }, [])

  function onConfirmCookies() {
    services.Trackings.trackCookies()
    setLgpd(true)
    localStorage.setItem('lgpd', true)
  }

  const footerItens = [
    [
      {
        label: 'Política de Privacidade',
        link: 'https://minhabiblioteca.com.br/politica-de-privacidade/',
        id: 'privacidade'
      }
    ]
  ]

  return (
    <SaraivaTheme>
      <BooksProvider>
        <SearchProvider>
          <Header onSearch={onSearch} searchPlaceHolder={searchPlaceHolder} />
          <GuidedTour />
          <div className={classes.container}>
            <div id="scroll" className={classes.layoutContainer}>
              <div
                className={`${classes.contentContainer} ${
                  noGutters ? classes.contentContainerNoGutters : undefined
                }`}
              >
                {children}
              </div>
              {!noFooter && (
                <div className={classes.footer}>
                  <Footer
                    itens={footerItens}
                    logoImg={Logotipo}
                    handleLogo={() => swithPage('/')}
                    onClickAcessibilityManual={() =>
                      Trackings.acessibilityManualTracking()
                    }
                    onClickUseManual={() => Trackings.manualTracking()}
                  />
                </div>
              )}
            </div>
          </div>
          {location.pathname !== '/tickets/activate' && !lgpd && (
            <div className={classes.cookie}>
              <CookieBar
                className={classes.cookiebar}
                onClick={onConfirmCookies}
              />
            </div>
          )}
          <MobileNavbar />
        </SearchProvider>
      </BooksProvider>
    </SaraivaTheme>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
}
