import React from 'react'
import { Anchor, Text, TextInput, Popover, Navbar, Container, Avatar, Group, FocusTrap } from '@mantine/core'
import { Link } from 'react-router-dom'
import { useAuthentication } from '../../hooks/useAuthentication'
import classes from './Header.module.css'
import Links from '../../constants/Links'
import { useSearch } from '../../contexts/SearchContext'
import { useMediaQuery } from '@mantine/hooks';
import BREAKPOINTS from '../../constants/Breakpoints'
import LemeLogo from '../LemeLogo'

export default function Header({ onSearch }) {
  const [logout] = useAuthentication()
  const { searchTerm, setSearchTerm, redirectToSearchResults, showMobileSearch, toggleShowMobileSearch } = useSearch();
  const isDesktop = useMediaQuery(`(min-width: ${BREAKPOINTS.md})`);

  const handleClear = () => {
    setSearchTerm('')
  }

  const handleSearchSubmit = (e) => {
    e.preventDefault()

    if (onSearch) {
      onSearch(searchTerm)
    } else if (searchTerm !== '') {
      redirectToSearchResults()
    }
  }

  const OVERLAY_ID = 'search-overlay'

  const handleOverlay = () => {
    toggleShowMobileSearch()
    const overlay = document.getElementById(OVERLAY_ID)
    if (overlay) {
      overlay.style.display = 'none'
    }
  }

  const navbarStyles = {
    backgroundColor: 'var(--leme-color-gray-0)',
    padding: 0,
    border:'none',
    left: 0,
    right: 0,
    top: 0,
    bottom: 'unset',
    position: 'fixed',
    zIndex: 1300,
  }

  const containerStyles = {
    display: 'flex',
    alignItems: 'center',
    gap: '1rem',
    maxWidth: '1440px',
    width: '100%',
    padding: '16px 32px',
    justifyContent: 'space-between'
  }

  const groupStyles = {
    display: { base: 'none', md: 'flex' },
    flexDirection: 'row',
    alignItems: 'center',
    gap: '1rem',
    flexGrow: 1,
    width: '100%',
    height: '40px',
    border: 'none',
    maxWidth: '372px',
    flexWrap: 'nowrap',
  }

  const searchOverlayStyles = {
    position: "absolute",
    top: "72px",
    left: 0,
    right: 0,
    width: "100vw",
    height: "calc(100vh - 72px - 72px)",
    backgroundColor: "#000",
    opacity: 0.5
  }

  return (
    <Navbar height={72} sx={navbarStyles} id='leme-navbar'>
      <Container sx={containerStyles}>
        {(isDesktop || !showMobileSearch) &&  <LemeLogo />}
        {isDesktop && (
          <Group sx={groupStyles}>
            <Anchor component={Link} to="/" style={{ textDecoration: 'none' }} className={classes.navLink}>
              <span className="material-symbols-outlined">browse</span>
              <Text>Catálogo</Text>
            </Anchor>
            <Anchor component={Link} to="/favorites" style={{ textDecoration: 'none' }} className={classes.navLink}>
              <span className="material-symbols-outlined">favorite</span>
              <Text>Favoritos</Text>
            </Anchor>
            <Anchor component={Link} to="/periodics" style={{ textDecoration: 'none' }} className={classes.navLink}>
              <span className="material-symbols-outlined">menu_book</span>
              <Text>Periódicos</Text>
            </Anchor>
        </Group>
        )}
        {(isDesktop || showMobileSearch) && (
          <form className={classes.searchForm} onSubmit={handleSearchSubmit} style={{width: "100%", maxWidth: "832px"}}>
            <FocusTrap active={showMobileSearch}>
              <TextInput
                  placeholder="Busque pelo nome do livro, autor, ISBN..."
                  value={searchTerm}
                  classNames={{
                    root: classes.searchRoot,
                    input: classes.searchInput,
                    icon: classes.searchIcon,
                    rightSection: classes.searchClearButton,
                  }}
                  type="text"
                  onChange={(e) => { setSearchTerm(e.target.value) }}
                  icon={<span className="material-symbols-outlined" aria-label="icone de busca">search</span>}
                  rightSection={
                    (searchTerm ? <button
                      className={classes.clearButton}
                      onClick={handleClear}
                      type="button"
                    >
                      <span className="material-symbols-outlined" aria-label="cancela busca">cancel</span>
                    </button> : null)
                  }
                />
              </FocusTrap>
          </form>
        )}
        {(isDesktop || !showMobileSearch) &&  (
          <div className={classes.profileLinks}>
            <Popover position="bottom" shadow="sm" classNames={{ dropdown: classes.dropdown }}>
              <Popover.Target>
                <Avatar src="profile.png" alt="Perfil do usuário" size={40} styles={() => ({
                  placeholder: {
                    backgroundColor: "var(--leme-color-gray-)",
                    border: "none"
                  }
                })} />
              </Popover.Target>
              <Popover.Dropdown>
                <Anchor component={Link} to={Links.PAGE_ACTIVATE_CODE} className={classes.profileLink}>
                  <Text>Ativar código</Text>
                </Anchor>
                <Anchor href={Links.EXTERNAL_HELP_CENTER} className={classes.profileLink} target='blank'>
                  <Text>Ajuda</Text>
                </Anchor>
                <Text className={classes.profileLink} onClick={() => logout()}>Sair</Text>
              </Popover.Dropdown>
            </Popover>
          </div>
       )}
       {showMobileSearch && <div id={OVERLAY_ID} style={searchOverlayStyles} onClick={handleOverlay}></div>}
      </Container>
    </Navbar>
  )
}
