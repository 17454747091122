/* eslint-disable */
import { stopwords, siglas } from './exceptions'
import { FORMAT as BOOK_FORMAT } from '../constants/Book'

function testSpecialChars(param, list) {
  if (param.includes(',') && list.includes(param.replace(/[.,()]/g, ''))) return true
  if (param.includes('.') && list.includes(param.replace(/[.,()]/g, ''))) return true
  if (param.includes('(') && list.includes(param.replace(/[.,()]/g, ''))) return true
  if (param.includes(')') && list.includes(param.replace(/[.,()]/g, ''))) return true
  if (param.includes(':') && list.includes(param.replace(/[.,()]/g, ''))) return true
  return false
}

function toCamelCase(string) {
  const words = string.split(' ')
  const lWords = words.map((word) => word.toLowerCase())

  const formatedTitle = lWords
    .map((word) => {
      if (siglas.includes(word) || testSpecialChars(word, siglas))
        return word.toUpperCase()
      return stopwords.includes(word)
        ? word
        : word && word[0].toUpperCase() + word.substring(1)
    })
    .join(' ')

  return formatedTitle.charAt(0).toUpperCase() + formatedTitle.slice(1)
}

function formatAbntText(abnt) {
  const bookTitle = abnt.match(/<strong[^>]*>([^<]+)<\/strong>/)[1]
  const bookTitleFormated = `<strong>${toCamelCase(bookTitle)}</strong>`
  return abnt.replace(
    /<strong[^>]*>([^<]+)<\/strong>/,
    `<strong>${bookTitleFormated}</strong>`
  )
}

function getEdition(book) {
  if (book.edition && !book.editionYear) return `${book.edition}ª edição`

  if (!book.edition && book.editionYear) return book.editionYear

  return `${book.edition}ª edição | ${book.editionYear}`
}

function getAuthors(book, params = {}) {
  let authors = book.authors || []
  authors = authors.filter((author) => author !== '')

  if (authors.length === 0) return ''

  if (authors.length === 1) return toCamelCase(authors[0])

  if (params.displayAll) {
    return `${authors.map((author) => toCamelCase(author)).join(', ')}`
  }

  return 'Vários autores'
}

function copyTextToClipBoard(text) {
  const el = document.createElement('textarea')
  el.setAttribute('id', 'abnt-text')
  el.value = text
  document.body.appendChild(el)
  el.select()
  document.execCommand('copy')
  document.body.removeChild(el)
}

function stripHtml(html) {
  const tmp = document.createElement('div')
  tmp.innerHTML = html
  return tmp.textContent || tmp.innerText || ''
}

function bookUrl(book) {
  return `/books/${book.uuid}`
}


function getBookReaderUrl(book) {
  switch (book.format) {
    case BOOK_FORMAT.EPUB:
      return `/epub/${book.uuid}?title=${book.titleCover}`
    case BOOK_FORMAT.PDF:
      return `/pdf/${book.uuid}?title=${book.titleCover}`
    default:
      return '/books/unavailable'
  }
}

function getDeviceType() {
  const ua = navigator.userAgent

  if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
    return 'tablet'
  }

  if (
    /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
      ua
    )
  ) {
    return 'mobile'
  }
  return 'desktop'
}

async function getUserIp() {
  return fetch('https://api.ipify.org/?format=json')
    .then((res) => res.json())
    .then((data) => data.ip)
}

function saveLastScrollPosition() {
  localStorage.setItem('lastScrollPosition', document.querySelector('#scroll').scrollTop)
}

function getLastScrollPosition() {
  return localStorage.getItem('lastScrollPosition')
}

function setScrollPostion(x, y) {
  document.querySelector('#scroll').scrollTo(x, y)
}

export {
  toCamelCase,
  getAuthors,
  getEdition,
  copyTextToClipBoard,
  stripHtml,
  bookUrl,
  getBookReaderUrl,
  getDeviceType,
  getUserIp,
  formatAbntText,
  saveLastScrollPosition,
  getLastScrollPosition,
  setScrollPostion,
}
