import Favorites from '../services/favorites/index'

export const useFavorite = () => {
  async function favoritesRequests(path, payload) {
    let request = ''
    try {
      request = await Favorites.favoritesRequests(path, payload)
    } catch (err) {
      request = err
    }

    return request
  }

  return [favoritesRequests]
}
