"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
const useStyles = () => ({
  backdrop: {
    color: 'rgba(73, 80, 87, 1)',
    zIndex: 500
  }
});
var _default = exports.default = useStyles;