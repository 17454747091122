"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TextItem = exports.LogoTypeBox = exports.ListLi = exports.Item = exports.CustomDivider = exports.CopyRightText = exports.CopyRightBox = exports.CopyIcon = exports.ContainerList = exports.ContainerItens = exports.ContainerContent = exports.Container = void 0;
var _core = require("@material-ui/core");
var _styledComponents = _interopRequireWildcard(require("styled-components"));
var _Copyright = _interopRequireDefault(require("@material-ui/icons/Copyright"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
/* eslint-disable */

const Container = exports.Container = _styledComponents.default.div`
  ${_ref => {
  let {
    mobile,
    itens,
    theme
  } = _ref;
  return (0, _styledComponents.css)`
    padding: ${mobile & !itens ? '32px 16px' : mobile & itens ? '32px 28px 32px' : '32px'};
    border-top: ${`1px solid ${theme.palette.divider || '#E0E0E0'}`};
    display: flex;
    background: #ffffff;
    width: 100%;
    justify-content: space-between;
    flex-direction: ${mobile ? 'column' : 'unset'};
  `;
}}
`;
const ContainerContent = exports.ContainerContent = (0, _styledComponents.default)('div')`
  ${_ref2 => {
  let {
    mobile
  } = _ref2;
  return (0, _styledComponents.css)`
    display: flex;
    flex-direction: column;
  `;
}}
`;
const Item = exports.Item = (0, _styledComponents.default)('div')`
  ${_ref3 => {
  let {
    mobile,
    itens
  } = _ref3;
  return (0, _styledComponents.css)`
    box-shadow: unset !important;
    align-items: flex-end !important;
    border-radius: 0 !important ;
    display: flex;
    width: ${mobile && itens ? '100%' : 'unset'};
    margin-right: ${itens ? '29px' : 0};
    height:100%;
  `;
}}
`;
const ContainerList = exports.ContainerList = (0, _styledComponents.default)('ul')`
  ${_ref4 => {
  let {
    mobile
  } = _ref4;
  return (0, _styledComponents.css)`
    margin:0!important;
    list-style:none;
    padding-inline-start: 0!important ;
 
  `;
}}
`;
const ListLi = exports.ListLi = (0, _styledComponents.default)('li')`
  ${_ref5 => {
  let {
    itens
  } = _ref5;
  return (0, _styledComponents.css)`
    padding: ${itens ? '0px 16px 12px 0px !important' : '0px 0px 12px 0px !important'};
  `;
}}
`;
const ContainerItens = exports.ContainerItens = (0, _styledComponents.default)('div')`
  ${_ref6 => {
  let {
    mobile,
    itens
  } = _ref6;
  return (0, _styledComponents.css)`
    display: flex;
    flex-direction: ${mobile ? 'column-reverse' : 'row-reverse'};
    margin-right: ${itens ? '34px' : 0};
    padding-top: ${mobile ? '24px' : 0} ;
  `;
}}
`;
const LogoTypeBox = exports.LogoTypeBox = (0, _styledComponents.default)('div')`
  ${_ref7 => {
  let {
    mobile
  } = _ref7;
  return (0, _styledComponents.css)`
    align-items: center;
    display: flex;
    cursor:pointer;
  `;
}}
`;
const CopyRightBox = exports.CopyRightBox = (0, _styledComponents.default)('div')`
  ${_ref8 => {
  let {
    mobile
  } = _ref8;
  return (0, _styledComponents.css)`
    justify-content: ${mobile ? 'left' : 'right'};
    display: flex;
    align-items: center ;
  `;
}}
`;
const CopyRightText = exports.CopyRightText = (0, _styledComponents.default)(_core.Typography)`
 ${_ref9 => {
  let {
    mobile,
    itens
  } = _ref9;
  return (0, _styledComponents.css)`
    display: flex;
    white-space: nowrap;
    align-items: center;
    text-align: right;
    letter-spacing: 0.15px;
    color: 'secondary';
    padding-top: ${mobile && itens ? '18px' : '12px'};
 `;
}} 
`;
const TextItem = exports.TextItem = (0, _styledComponents.default)(_core.Link)`
${_ref10 => {
  let {
    mobile,
    itens,
    theme
  } = _ref10;
  return (0, _styledComponents.css)`
  display: flex;
  text-decoration-line: underline !important;
  justify-content: ${mobile || itens ? 'start' : 'end'};
  color: 'primary'!important;
`;
}}
 `;
const CustomDivider = exports.CustomDivider = (0, _styledComponents.default)(_core.Divider)`
 margin-top: 15px!important ;
`;
const CopyIcon = exports.CopyIcon = (0, _styledComponents.default)(_Copyright.default)`
 padding-left: 5px;
 padding-right: 5px;
 font-size:27px ;
`;