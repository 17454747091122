import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  recentOpened: {
    marginTop: 72,
    marginBottom: 6,
    fontSize: 20,
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.6,
    letterSpacing: '0.15px',
    color: 'rgba(33, 37, 41, 1)',
    [theme.breakpoints.down(576)]: {
      marginTop: 32,
      fontSize: 16,
      lineHeight: 1.5
    }
  },
  divider: {
    marginBottom: 24,
    [theme.breakpoints.down(769)]: {
      marginBottom: 48.5
    },
    [theme.breakpoints.down(1024)]: {
      marginBottom: 24
    },
    [theme.breakpoints.down(426)]: {
      marginBottom: 24
    },
    [theme.breakpoints.down(348)]: {
      marginBottom: 24
    }
  },
  recentOpenedContainer: {
    display: 'flex',
    height: 347,
    '& > div:first-child .MuiPaper-root': {
      marginLeft: '1px !important'
    },
    [theme.breakpoints.down(426)]: {
      overflow: 'auto',
      maxWidth: '100%',
      height: 359,
      '&::-webkit-scrollbar': {
        background: 'transparent'
      },
      '& > div:first-child .MuiPaper-root': {
        marginLeft: '1px !important'
      }
    },
    [theme.breakpoints.down(348)]: {
      overflow: 'auto',
      maxWidth: '100%',
      height: 362,
      '&::-webkit-scrollbar': {
        background: 'transparent'
      }
    }
  }
}))

export default useStyles
