"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _core = require("@material-ui/core");
var useStyles = (0, _core.makeStyles)(() => ({
  container: {
    display: 'flex',
    paddingTop: '10px',
    justifyContent: 'center',
    width: '100%'
  }
}));
var _default = exports.default = useStyles;