import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 16,
    padding: 32,
    [theme.breakpoints.down(769)]: {
      padding: '24px 16px',
      marginTop: 8,
      marginBottom: 14
    }
  },

  top: {
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: 36,
    [theme.breakpoints.down(769)]: {
      flexDirection: 'column',
      marginBottom: 20
    }
  },

  mainInfoContainer: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up(600)]: {
      minWidth: 200,
      flex: 1
    },
    '& > span': {
      marginBottom: 4,
      [theme.breakpoints.down(769)]: {
        color: 'rgba(73, 80, 87, 1)'
      }
    },
    '& > span:last-child': {
      marginBottom: 0
    }
  },

  imageContainer: {
    cursor: 'pointer',
    marginRight: '32.6px',
    position: 'relative',
    '&:hover': {
      '&::after': {
        borderRadius: 8,
        display: 'block',
        position: 'absolute',
        content: '""',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        backgroundColor: 'rgba(73, 80, 87, 1)',
        height: '100%'
      }
    },
    '&:hover $imageLabel': {
      display: 'block'
    },
    [theme.breakpoints.down(769)]: {
      margin: 'auto',
      textAlign: 'center',
      position: 'relative',
      '&:hover': {
        '&::after': {
          borderRadius: 8,
          display: 'block',
          position: 'absolute',
          content: '""',
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          backgroundColor: 'rgba(73, 80, 87, 1)',
          height: '100%'
        }
      },
      '&:hover $imageLabel': {
        display: 'block'
      }
    },
    [theme.breakpoints.up(1200)]: {
      position: 'relative',
      '&:hover': {
        '&::after': {
          borderRadius: 8,
          display: 'block',
          position: 'absolute',
          content: '""',
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          backgroundColor: 'rgba(73, 80, 87, 1)',
          height: '100%'
        }
      },
      '&:hover $imageLabel': {
        display: 'block'
      }
    }
  },

  image: {
    width: 188,
    height: 264,
    borderRadius: 8
  },

  imageLabel: {
    display: 'none',
    position: 'absolute',
    textTransform: 'uppercase',
    fontSize: 15,
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.73,
    letterSpacing: '0.43px',
    textAlign: 'center',
    color: '#fafafa',
    zIndex: 99,
    top: '44%',
    left: '30%'
  },

  title: {
    margin: 0,
    padding: 0,
    fontSize: 24,
    fontWeight: 'normal',
    lineHeight: 1.33,
    letterSpacing: 'normal',
    color: 'rgba(33, 37, 41, 1)',
    marginBottom: 24,
    [theme.breakpoints.down(769)]: {
      fontSize: 16,
      lineHeight: 1.5,
      marginBottom: 16
    }
  },

  subtitle: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: 0,
    padding: 0,
    fontSize: 20,
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.6,
    letterSpacing: '0.15px',
    color: 'rgba(73, 80, 87, 1)',
    marginBottom: 8,
    [theme.breakpoints.down(769)]: {
      fontSize: 16,
      fontWeight: 'normal',
      lineHeight: 1.75,
      marginBottom: 4
    }
  },

  text: {
    display: 'block',
    fontSize: 16,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.5,
    letterSpacing: '0.15px',
    color: 'rgba(33, 37, 41, 1)',
    outline: '0px solid transparent',
    [theme.breakpoints.down(769)]: {
      fontSize: 14,
      lineHeight: 1.43
    }
  },

  content: {
    marginTop: 24,
    maxWidth: 808,
    [theme.breakpoints.down(769)]: {
      width: '100%',
      marginTop: 16
    }
  },

  copyBtn: {
    marginLeft: 17,
    fontSize: 15,
    fontWeight: 500,
    letterSpacing: '0.43px',
    textTransform: 'uppercase',
    [theme.breakpoints.down(769)]: {
      marginLeft: 5
    }
  },

  copyBtnLabel: {
    [theme.breakpoints.down(769)]: {
      display: 'none'
    }
  },

  favoriteContainer: {
    display: 'flex',
    justifyContent: 'space-between'
  },

  favoriteButton: {
    height: 36,
    minWidth: 36,
    width: 36,
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
    borderRadius: '100%',
    marginTop: -8,
    marginRight: -8,
    '& .MuiButton-label': {
      alignSelf: 'center',
      width: '22px !important'
    },
    '& .MuiButton-startIcon': {
      margin: '0px 0px'
    },
    '& .MuiButton-iconSizeLarge > *:first-child': {
      fontSize: 24
    },
    [theme.breakpoints.down(769)]: {
      display: 'flex',
      alignSelf: 'flex-end',
      marginRight: 0
    }
  },

  favoriteColor: {
    color: '#212529',
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
    borderRadius: '100%',
    height: 36,
    minWidth: 36,
    width: 36,
    marginTop: -8,
    marginRight: -8,
    '& .MuiButton-label': {
      alignSelf: 'center',
      width: '22px !important'
    },
    '& .MuiButton-startIcon': {
      margin: '0px 0px'
    },
    '& .MuiButton-iconSizeLarge > *:first-child': {
      fontSize: 24
    },
    [theme.breakpoints.down(769)]: {
      display: 'flex',
      alignSelf: 'flex-end',
      marginRight: 0
    }
  },
  recommendation: {
    backgroundColor: '#ffffff',
    border: '1px solid rgba(0, 0, 0, 0.12)',
    borderRadius: 8,
    marginTop: 32,
    padding: '23px 32px 32px 32px',
    [theme.breakpoints.down(769)]: {
      padding: '24px 16px',
      marginTop: 8,
      marginBottom: 14
    }
  },
  externalInfo: {
    backgroundColor: '#245A9314',
    marginBottom: 24,
    height: 62,
    borderRadius: 8,
    display: 'flex',
    alignItems: 'center'
  },
  externalInfoMobile: {
    [theme.breakpoints.down(860)]: {
      backgroundColor: '#245A9314',
      marginBottom: 36,
      height: 'auto',
      borderRadius: 8,
      display: 'flex',
      alignItems: 'center'
    }
  },
  externalInfoIcon: {
    color: '#245A93',
    margin: '16px',
    height: 36,
    width: 36
  },
  externalInfoIconMobile: {
    [theme.breakpoints.down(860)]: {
      color: '#245A93',
      margin: '16px 16px 32px',
      height: 32,
      width: 38
    }
  },
  externalInfoLink: {
    color: '#245A93',
    margin: 'auto 4px',
    whiteSpace: 'pre',
    fontSize: 14
  },
  externalTextBody1: {
    display: 'flex',
    margin: '16px 16px 16px 0',
    [theme.breakpoints.down(860)]: {
      display: 'block'
    }
  },
  externalTextBody2: {
    alignSelf: 'self-end',
    fontSize: 14,
    color: 'rgba(73, 80, 87, 1)'
  },
  buttons: {
    display: 'flex',
    justifySelf: 'flex-end',
    gap: 24,
    marginTop: 'auto'
  },
  buttonsMobile: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: 186,
    margin: '0 auto',
    marginTop: 24,
    gap: 24,
    marginBottom: 40
  }
}))

export default useStyles
