import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    maxWidth: '103%',
    width: 1118,
    margin: 'auto',
    [theme.breakpoints.down(769)]: {
      flexDirection: 'column-reverse'
    }
  },

  titleContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '25px',
    paddingTop: 24,
    [theme.breakpoints.up('md')]: {
      justifyContent: 'right',
      marginBottom: 'initial'
    }
  },
  title: {
    marginBottom: 16,
    fontSize: 24,
    fontWeight: 400,
    [theme.breakpoints.down(769)]: {
      fontSize: '16px',
      lineHeight: '24px',
      fontWeight: 400
    }
  },
  pictureContainer: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down(769)]: {
      paddingTop: 32
    }
  },
  img: {
    maxWidth: '86%',
    maxHeight: '86%'
  },
  text: {
    fontSize: '34px',
    lineHeight: '42px',
    fontWeight: 400,
    [theme.breakpoints.down(769)]: {
      fontSize: '20px',
      lineHeight: '32px',
      fontWeight: 500
    },

    '& br': {
      display: 'none',

      [theme.breakpoints.up('xs')]: {
        display: 'block'
      }
    }
  }
}))
