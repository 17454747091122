/* eslint-disable react/forbid-prop-types */
import React, { useState, createContext, useContext } from 'react'
import PropTypes from 'prop-types'

export const MenuContext = createContext()

export default function MenuProvider({ children }) {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <MenuContext.Provider value={{ isOpen, setIsOpen }}>
      {children}
    </MenuContext.Provider>
  )
}

export function useMenu() {
  return useContext(MenuContext)
}

MenuProvider.propTypes = {
  children: PropTypes.node.isRequired
}
