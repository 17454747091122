"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
function flattenArray(array, field) {
  var newArray = [...array];
  for (var i = 0; i < newArray.length; i++) {
    if (newArray[i][field].length > 0) {
      newArray = [...newArray.slice(0, i + 1), ...newArray[i][field], ...newArray.slice(i + 1, newArray.length)];
    }
  }
  return newArray;
}
var Array = {
  flattenArray
};
var _default = exports.default = Array;