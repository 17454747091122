import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '998',
    margin: 'auto',

    [theme.breakpoints.up('md')]: {
      flexDirection: 'row'
    }
  },
  content: {
    [theme.breakpoints.up('md')]: {
      order: 2,
      marginLeft: theme.spacing(6),
      flex: '0 1 543px'
    }
  },
  image: {
    [theme.breakpoints.up('md')]: {
      order: 1,
      flex: '0 1 406px'
    }
  },
  img: {
    maxWidth: '100%'
  },
  title: {
    marginBottom: theme.spacing(1),
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '24px',

    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(2),
      fontSize: '24px',
      fontWeight: '400',
      lineHeight: '32.02px'
    }
  },
  body: {
    marginBottom: theme.spacing(2),
    fontSize: '20px',
    fontWeight: '500',
    lineHeight: '32px',

    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(2),
      fontSize: '34px',
      fontWeight: '400',
      lineHeight: '41.99px'
    }
  },
  footer: {
    marginBottom: theme.spacing(4),
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '21.98px',

    [theme.breakpoints.up('md')]: {
      fontSize: '20px',
      fontWeight: '500',
      lineHeight: '32px'
    }
  }
}))
