"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useLocalStorage = void 0;
var _react = require("react");
var useLocalStorage = exports.useLocalStorage = function useLocalStorage(key) {
  var initialValue = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
  var [state, setState] = (0, _react.useState)(() => {
    try {
      var storedValue = localStorage.getItem(key);
      return storedValue ? JSON.parse(storedValue) : initialValue;
    } catch (error) {
      return initialValue;
    }
  });
  var setValue = (0, _react.useCallback)(value => {
    try {
      setState(value);
      localStorage.setItem(key, JSON.stringify(value));
    } catch (error) {
      /* eslint-disable-next-line no-console */
      console.log(error);
    }
  }, [key]);
  return [state, setValue];
};