"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.THEME = exports.FORMAT_TYPE = void 0;
var _logo = _interopRequireDefault(require("../assets/logo.svg"));
var _logoColor = _interopRequireDefault(require("../assets/logo-color.svg"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
var FORMAT_TYPE = exports.FORMAT_TYPE = 'PDF';
var THEME = exports.THEME = {
  DEFAULT: {
    label: 'Padrão',
    logo: _logo.default,
    reader: {
      backgroundColor: '#F5F5F5',
      color: '#616161'
    },
    sidebar: {
      backgroundColor: '#212529',
      color: '#FFFFFF'
    }
  },
  LIGHT: {
    label: 'Claro',
    logo: _logoColor.default,
    reader: {
      backgroundColor: '#FFFFFF',
      color: '#616161'
    },
    sidebar: {
      backgroundColor: '#FFFFFF',
      color: '#424242'
    }
  },
  SEPIA: {
    label: 'Sépia',
    logo: _logoColor.default,
    reader: {
      backgroundColor: '#FBF0D9',
      color: '#616161'
    },
    sidebar: {
      backgroundColor: '#FBF0D9',
      color: '#424242'
    }
  },
  DARK: {
    label: 'Escuro',
    logo: _logo.default,
    reader: {
      backgroundColor: '#424242',
      color: '#BDBDBD'
    },
    sidebar: {
      backgroundColor: '#424242',
      color: '#FFFFFF'
    }
  }
};