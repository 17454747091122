"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
var httpRequest = (applicationToken, sessionToken) => {
  function RequestHeaderBuilder() {
    var DEFAULT_REQUEST_HEADER = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'application-token': applicationToken,
      'session-token': sessionToken
    };
    function decorate() {
      var customRequestHeader = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return _objectSpread(_objectSpread({}, DEFAULT_REQUEST_HEADER), customRequestHeader);
    }
    return {
      buildHeader(headers) {
        return decorate(headers);
      }
    };
  }
  function RequestBuilder() {
    var {
      REACT_APP_FUSION_ORIGIN
    } = process.env;
    function normalizePath(path) {
      if (path.startsWith('/')) {
        return path.slice(1);
      }
      return path;
    }
    function buildUrl(path) {
      return "".concat(REACT_APP_FUSION_ORIGIN, "/").concat(normalizePath(path));
    }
    function buildBody(payload) {
      if (!payload) {
        return {};
      }
      return {
        body: JSON.stringify(payload)
      };
    }
    function build(path, method, payload, headers) {
      var options = {
        method,
        headers,
        mode: 'cors'
      };
      if (method !== 'GET') {
        options = _objectSpread(_objectSpread({}, options), buildBody(payload));
      }
      return fetch(buildUrl(path), options);
    }
    return {
      get(path, payload, headers) {
        return build(path, 'GET', payload, headers);
      },
      post(path, payload, headers) {
        return build(path, 'POST', payload, headers);
      },
      patch(path, payload, headers) {
        return build(path, 'PATCH', payload, headers);
      },
      deleteMethod(path, payload, headers) {
        return build(path, 'DELETE', payload, headers);
      }
    };
  }
  return _objectSpread(_objectSpread({}, RequestHeaderBuilder()), RequestBuilder());
};
var _default = exports.default = httpRequest;