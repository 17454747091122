/* eslint-disable */
// example from https://pt-br.reactjs.org/docs/error-boundaries.html
/* eslint-disable react/forbid-prop-types */

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Redirect } from 'react-router-dom'
import { redirectToLogin } from '../../auth'

const isUnauthorized = (error) => {
  return error.message.includes('401')
}

const isInvalidTicket = (error) => {
  return error.message.includes('403')
}

const isInvalidEpub = (error) => {
  return error.message.includes('404')
}

export default class ErrorBoundary extends Component {
  constructor(props) {
    super(props)
    this.state = { error: null }
  }

  componentDidCatch(error, _errorInfo) {
    this.setState({ error })
  }

  renderError() {
    const { error } = this.state

    if (isUnauthorized(error)) {
      redirectToLogin()
    }

    if (isInvalidTicket(error)) {
      return <Redirect to="/tickets/activate?no-access=true" />
    }

    if (isInvalidEpub(error)) {
      return <Redirect to="/books/unavailable" />
    }

    // TO DO: replace with standard error page
    return (
      <div>
        <h2>Something went wrong.</h2>
        <details style={{ whiteSpace: 'pre-wrap' }}>
          {error && error.toString()}
          <br />
        </details>
      </div>
    )
  }

  render() {
    const { error } = this.state
    const { children } = this.props

    if (error) {
      return this.renderError()
    }

    return children
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.object.isRequired
}
