/* eslint-disable */
/* eslint-disable react/forbid-prop-types */
import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import queryString from 'query-string'
import { Snackbar } from '@minha-biblioteca/saraiva-ui'
import Bugsnag from '@bugsnag/js'
import Loading from '../../../components/Loading/index.js'
import services from '../../../services'
import { setSessionToken } from '../../../auth'
import { getBookReaderUrl } from '../../../utils'


class MarcAuthentication extends Component {
  constructor(props) {
    super(props)

    this.state = {
      showError: false,
      authenticated: false,
      book: null
    }
  }

  async componentDidMount() {
    try {
      const libraryCode = window.location.pathname.split("/")[2]
      const parsed = queryString.parse(window.location.search)
      const response = await services.Sessions.loginByMarc(libraryCode, parsed.token)

      if (!response.ok) {
        const errors = await response.json()
        throw errors
      }

      const result = await response.json()

      this.setAuthenticationDataAndUpdateState(result)
      services.Trackings.integrationLogin()
    } catch (err) {
      this.setState({ showError: true})
      Bugsnag.notify(err);
    }
  }

  setAuthenticationDataAndUpdateState({ tokens: { local_token }, book }) {
    setSessionToken(local_token)
    this.setState({ authenticated: true, book })
  }

  closeSnackbar() {
    this.setState({ showError: false })
  }

  render() {
    const { authenticated, book, showError } = this.state

    if (authenticated && book) {
      services.Trackings.trackOpenBook(book, {
        viewMode: 'integração Marc21'
      })
      return <Redirect to={getBookReaderUrl(book)} />
    }

    return (
      <>
        <Snackbar
          show={showError}
          severity="error"
          handleClose={() => this.closeSnackbar()}
          autoHideDuration={3000}
        >
          Ocorreu um erro no login.
        </Snackbar>

        <Loading />
      </>
    )
  }
}

export default MarcAuthentication
