"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useStyles = void 0;
var _styles = require("@material-ui/core/styles");
var _tinycolor = _interopRequireDefault(require("tinycolor2"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
const useStyles = exports.useStyles = (0, _styles.makeStyles)(theme => ({
  wrapper: {
    borderRadius: 8,
    padding: 20,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: 16
  },
  text: {
    fontSize: 14
  },
  infoButton: {
    color: '#fff',
    height: 42,
    backgroundColor: '#616161',
    fontSize: 15,
    '&:hover': {
      backgroundColor: '#595959'
    }
  },
  primaryVariant: {
    backgroundColor: _tinycolor.default.mix('FFF', theme.palette.primary.main, 10)
  },
  secondaryVariant: {
    backgroundColor: _tinycolor.default.mix('FFF', theme.palette.secondary.main, 10)
  },
  infoVariant: {
    backgroundColor: '#EEEEEE'
  }
}));