"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.reducer = exports.initialState = exports.actionTypes = void 0;
const actionTypes = exports.actionTypes = {
  clickLogo: 'CLICK_LOGO',
  clickBottomHome: 'CLICK_BOTTOM_HOME',
  clickBottomSearch: 'CLICK_BOTTOM_SEARCH',
  clickCloseModal: 'CLICK_CLOSE_MODAL',
  clickClearSearchbar: 'CLICK_CLEAR_SEARCHBAR',
  typing: 'TYPING',
  submitSearch: 'SUBMIT_SEARCH',
  openDrawer: 'OPEN_DRAWER',
  closeDrawer: 'CLOSE_DRAWER',
  backToSearchResult: 'BACK_TO_SEARCH_RESULT',
  openNavigateMenu: 'OPEN_MENU',
  closeNavigateMenu: 'CLOSE_MENU'
};
const initialState = exports.initialState = {
  initial: true,
  searching: false,
  typing: false,
  final: false,
  fromHome: true,
  bottomButton: 0,
  openDrawer: false,
  inputValue: '',
  openNavigateMenu: false
};
const openModalHash = '#openModal';
const reducer = function () {
  let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  let {
    type,
    newInputValue
  } = arguments.length > 1 ? arguments[1] : undefined;
  switch (type) {
    case actionTypes.clickLogo:
      return {
        ...state,
        initial: true,
        searching: false,
        typing: false,
        final: false,
        fromHome: true,
        bottomButton: 0
      };
    case actionTypes.clickBottomHome:
      return {
        ...state,
        initial: true,
        searching: false,
        typing: false,
        final: false,
        fromHome: true,
        bottomButton: 0,
        inputValue: ''
      };
    case actionTypes.clickBottomSearch:
      if (!window.history.state || window.history.state !== '#openModal') {
        window.history.pushState(openModalHash, '', window.location.pathname);
      }
      return {
        ...state,
        initial: false,
        searching: true,
        typing: false,
        final: false,
        bottomButton: 1
      };
    case actionTypes.clickCloseModal:
      return {
        ...state,
        searching: false,
        typing: false
      };
    case actionTypes.clickClearSearchbar:
      return {
        ...state,
        initial: false,
        searching: true,
        typing: false,
        final: false,
        inputValue: ''
      };
    case actionTypes.typing:
      const isTyping = newInputValue !== '';
      return {
        ...state,
        initial: false,
        searching: true,
        typing: isTyping,
        final: false,
        inputValue: newInputValue,
        bottomButton: 1
      };
    case actionTypes.submitSearch:
      return {
        ...state,
        initial: false,
        searching: false,
        typing: false,
        final: true,
        fromHome: false,
        inputValue: newInputValue,
        bottomButton: 1
      };
    case actionTypes.backToSearchResult:
      return {
        ...state,
        initial: false,
        searching: false,
        typing: false,
        final: true,
        fromHome: false
      };
    case actionTypes.openDrawer:
      return {
        ...state,
        openDrawer: true
      };
    case actionTypes.closeDrawer:
      return {
        ...state,
        openDrawer: false
      };
    case actionTypes.openNavigateMenu:
      return {
        ...state,
        openNavigateMenu: true
      };
    case actionTypes.closeNavigateMenu:
      return {
        ...state,
        openNavigateMenu: false
      };
    default:
      return state;
  }
};
exports.reducer = reducer;