/* eslint-disable react/forbid-prop-types */
import React from 'react'
import LeftArrowIcon from '@material-ui/icons/NavigateBefore'
import { Button } from '@minha-biblioteca/saraiva-ui'
import { useHistory } from 'react-router-dom'

export default function BackLink() {
  const history = useHistory()
  const { goBack } = history

  return (
    <Button
      color="primary"
      onClick={() => {
        if (localStorage.getItem('lastAccessFromEpubReader')) {
          localStorage.removeItem('lastAccessFromEpubReader')
          window.location.assign('/')
        } else {
          goBack()
        }
      }}
      startIcon={<LeftArrowIcon />}
    >
      Voltar
    </Button>
  )
}
