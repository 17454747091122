import React from 'react'
import { Anchor, Avatar } from '@mantine/core';
import { Link } from 'react-router-dom';
import logo from '../../assets/logo.svg'

const LemeLogo = ({ size }) => {
  return (
    <Anchor component={Link} to="/" style={{ textDecoration: 'none' }}>
      <Avatar src={logo} alt="Logo do Leme" size={ size || 40} />
    </Anchor>
  )
}

export default LemeLogo
