"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _Fusion = _interopRequireDefault(require("../request/Fusion"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
function getAll(applicationToken, sessionToken, uuid, userId) {
  return _Fusion.default.get("/booknotes/api/v1/bookmarks?book_id=".concat(uuid, "&user_id=").concat(userId), {
    headers: {
      'application-token': applicationToken,
      'session-token': sessionToken
    }
  }).then(response => response.data);
}
function create(applicationToken, sessionToken, payload) {
  return _Fusion.default.post('/booknotes/api/v1/bookmarks', payload, {
    headers: {
      'application-token': applicationToken,
      'session-token': sessionToken
    }
  });
}
function remove(applicationToken, sessionToken, bookmarkId) {
  return _Fusion.default.delete("/booknotes/api/v1/bookmarks/".concat(bookmarkId), {
    headers: {
      'application-token': applicationToken,
      'session-token': sessionToken
    }
  });
}
var Bookmark = {
  getAll,
  create,
  remove
};
var _default = exports.default = Bookmark;