"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.theme = exports.default = void 0;
var _styles = require("@material-ui/core/styles");
require("fontsource-roboto/400.css");
require("fontsource-roboto/500.css");
const theme = exports.theme = {
  name: 'LFG',
  palette: {
    primary: {
      light: '#6666B4',
      main: '#4040A1',
      dark: '#000082'
    },
    secondary: {
      light: '#5ECFB5',
      main: '#1F8879',
      dark: '#0A5A51',
      contrastText: '#FFFFFF'
    },
    error: {
      light: '#DE4D54',
      main: '#A42C31',
      dark: '#781F22'
    },
    warning: {
      light: '#FFB400',
      main: '#D89800',
      dark: '#976A00'
    },
    info: {
      light: '#3988DC',
      main: '#245A93',
      dark: '#194069'
    },
    success: {
      light: '#32CD99',
      main: '#1F8563',
      dark: '#13533E'
    },
    text: {
      primary: 'rgba(33, 37, 41, 1)',
      secondary: 'rgba(73, 80, 87, 1)',
      disabled: 'rgba(0, 0, 0, 0.38)'
    },
    action: {
      active: 'rgba(73, 80, 87, 1)',
      hover: 'rgba(0, 0, 0, 0.04)',
      selected: 'rgba(0, 0, 0, 0.08)',
      disabled: 'rgba(0, 0, 0, 0.26)',
      disabledBackground: 'rgba(0, 0, 0, 0.12)'
    },
    background: {
      default: '#fafafa',
      paper: '#fff',
      hover: '#E0E0E0'
    },
    divider: 'rgba(0, 0, 0, 0.12)',
    other: {
      activeRating: '#FFB400'
    }
  },
  shape: {
    borderRadius: 8
  },
  overrides: {
    MuiButton: {
      contained: {
        boxShadow: 'none'
      }
    },
    MuiCssBaseline: {
      '@global': {
        body: {
          fontFamily: 'Roboto, Helvica, sans-serif',
          fontWeight: 400
        }
      }
    }
  }
};
var _default = exports.default = (0, _styles.createTheme)(theme);