import { getSessionToken } from '../../auth'
import httpClient from '../../gateway/http_request'

const Favorites = {
  async favoritesRequests(path, payload) {
    let response = ''
    let headers = { 'session-token': getSessionToken() }

    headers = httpClient.buildHeader(headers)

    switch (payload.requestType) {
      case 'POST':
        try {
          const body = {
            favorite_id: payload.favorite_id,
            favorite_type: payload.favorite_type,
            user_id: payload.user_id
          }
          response = await httpClient.post(path, body, headers)
          if (response.ok) response = await response.json()
        } catch (err) {
          response = err
        }

        break
      case 'GET':
        try {
          response = await httpClient.get(path, null, headers)
          if (response.ok) response = await response.json()
        } catch (err) {
          response = err
        }
        break
      case 'DELETE':
        try {
          response = await httpClient.delete(path, null, headers)
          if (response.ok) response = await response.json()
        } catch (err) {
          response = err
        }
        break
      default:
    }
    return response
  }

}

export default Favorites
