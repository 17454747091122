"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _styles = require("@material-ui/core/styles");
var useStyles = (0, _styles.makeStyles)(theme => ({
  bottomWrapper: {
    position: 'fixed',
    width: '100%',
    bottom: 0,
    boxShadow: '0px 6px 8px 4px',
    zIndex: 1000
  },
  bottomButtons: {
    maxWidth: 298,
    '&:disabled': {
      cursor: 'not-allowed !important',
      pointerEvents: 'auto !important'
    }
  },
  bottomButtonsDM: {
    maxWidth: 298,
    color: 'rgba(255, 255, 255, 0.7)',
    '&:disabled': {
      cursor: 'not-allowed !important',
      pointerEvents: 'auto !important'
    }
  },
  bottomIOS: {
    position: 'fixed',
    width: '100%',
    bottom: 0,
    boxShadow: '0px 6px 8px 4px',
    zIndex: 1000,
    height: 80,
    alignItems: 'start'
  },
  selectedButton: {
    color: theme.palette.primary.main
  },
  selectedButtonDM: {
    color: 'rgba(255, 255, 255, 0.7)'
  },
  tooltip: {
    maxWidth: 154
  }
}));
var _default = exports.default = useStyles;