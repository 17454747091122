"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "BookmarksService", {
  enumerable: true,
  get: function get() {
    return _bookmarks.default;
  }
});
Object.defineProperty(exports, "CharactersCopyService", {
  enumerable: true,
  get: function get() {
    return _charactersCopy.default;
  }
});
Object.defineProperty(exports, "CopyrightService", {
  enumerable: true,
  get: function get() {
    return _copyright.default;
  }
});
Object.defineProperty(exports, "EpubService", {
  enumerable: true,
  get: function get() {
    return _epubs.default;
  }
});
Object.defineProperty(exports, "HighlightsService", {
  enumerable: true,
  get: function get() {
    return _highlights.default;
  }
});
Object.defineProperty(exports, "PagesService", {
  enumerable: true,
  get: function get() {
    return _pages.default;
  }
});
Object.defineProperty(exports, "ReaderPreferencesService", {
  enumerable: true,
  get: function get() {
    return _readerPreferences.default;
  }
});
Object.defineProperty(exports, "TrackingsService", {
  enumerable: true,
  get: function get() {
    return _trackings.default;
  }
});
var _bookmarks = _interopRequireDefault(require("./booknotes/bookmarks"));
var _highlights = _interopRequireDefault(require("./booknotes/highlights"));
var _readerPreferences = _interopRequireDefault(require("./booknotes/readerPreferences"));
var _epubs = _interopRequireDefault(require("./contents/epubs"));
var _pages = _interopRequireDefault(require("./booknotes/pages"));
var _trackings = _interopRequireDefault(require("./trackings/trackings"));
var _charactersCopy = _interopRequireDefault(require("./booknotes/charactersCopy"));
var _copyright = _interopRequireDefault(require("./booknotes/copyright"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }