"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.checkTextColor = exports.checkSizeListHeight = exports.checkSizeDialog = exports.checkSizeCard = exports.checkSize = exports.checkSheetColor = void 0;
exports.truncateString = truncateString;
function truncateString(str, num) {
  if (str.length <= num) return str;
  return `${str.slice(0, num)}...`;
}
const checkSize = param => {
  if (param === 'md') return 416;
  if (param === 'xs') return 196;
  if (param === 'sm') return 278;
  if (param === 'lg') return 580;
  if (param === 'xl') return 856;
  if (param === 'full') return '100%';
  return 416;
};
exports.checkSize = checkSize;
const checkSizeDialog = param => {
  if (param === 'md') return 600;
  if (param === 'xs') return 296;
  if (param === 'sm') return 444;
  if (param === 'lg') return 960;
  if (param === 'xl') return 1280;
  if (param === 'full') return '100%';
  return 600;
};
exports.checkSizeDialog = checkSizeDialog;
const checkSizeCard = param => {
  const size = {
    lg: 216,
    md: 156,
    sm: 128
  };
  return size[param] || size.lg;
};
exports.checkSizeCard = checkSizeCard;
const checkSizeListHeight = param => {
  const size = {
    lg: 'auto',
    sm: 'auto'
  };
  return size[param] || size.lg;
};
exports.checkSizeListHeight = checkSizeListHeight;
const checkSheetColor = color => {
  if (color === 'primary') {
    return '#212529';
  }
  if (color === 'secondary') {
    return '#245A93';
  }
  if (color === 'dark') {
    return '#757575';
  }
  if (color === 'light') {
    return '#E0E0E0';
  }
  if (color === 'sepia') {
    return '#757575';
  }
  return '#245A93';
};
exports.checkSheetColor = checkSheetColor;
const checkTextColor = color => {
  if (color === 'primary' || color === 'secodary' || color === 'dark') {
    return '#ffffff';
  }
  if (color === 'light') {
    return '#424242';
  }
  if (color === 'sepia') {
    return '#FBF0D9';
  }
  return '#ffffff';
};
exports.checkTextColor = checkTextColor;