"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _styles = require("@material-ui/core/styles");
var useStyles = (0, _styles.makeStyles)(theme => ({
  root: {
    width: '100%',
    padding: 0,
    paddingTop: 8
  },
  chapterName: {
    color: 'rgba(33, 37, 41, 1)',
    fontSize: 14,
    padding: 0,
    display: 'block'
  },
  item: {
    display: 'flex',
    padding: '0 10px 0 0',
    marginTop: 16,
    maxHeight: 66
  },
  listItemText: {
    marginLeft: 0,
    '& span': {
      padding: 0
    }
  },
  placeholder: {
    padding: '24px 16px'
  },
  icon: {
    color: '#000'
  },
  markIcon: {
    color: '#000',
    marginRight: 16,
    marginTop: 4
  },
  iconMenu: {
    backgroundColor: 'transparent !important',
    padding: 4
  },
  summaryContainer: {
    display: 'flex',
    margin: '24px 0',
    padding: 0,
    paddingRight: 10
  },
  summaryInfo: {
    display: 'flex',
    alignItems: 'flex-start',
    margin: 0,
    padding: 0,
    paddingLeft: 12
  },
  summary: {
    overflow: 'hidden',
    display: '-webkit-box',
    '-webkit-line-clamp': 3,
    '-webkit-box-orient': 'vertical',
    fontSize: 12,
    color: 'rgba(33, 37, 41, 1)',
    letterSpacing: 0.4
  },
  pageDiv: {
    padding: '0 0 0 16px',
    marginBottom: 12
  },
  page: {
    fontSize: 12,
    color: theme.palette.action.active,
    fontWeight: 400
  }
}));
var _default = exports.default = useStyles;