"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _Fusion = _interopRequireDefault(require("../request/Fusion"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
function getUrl(applicationToken, sessionToken, uuid) {
  return _Fusion.default.get("/contents/api/v1/pdfs/".concat(uuid), {
    responseType: 'blob',
    headers: {
      'application-token': applicationToken,
      'session-token': sessionToken
    }
  }).then(response => window.URL.createObjectURL(response.data));
}
function getBook(applicationToken, sessionToken, uuid) {
  return _Fusion.default.get("/contents/api/v1/books/".concat(uuid), {
    headers: {
      'application-token': applicationToken,
      'session-token': sessionToken
    }
  }).then(response => response.data);
}
var Pdf = {
  getUrl,
  getBook
};
var _default = exports.default = Pdf;