"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.setStoredPerPageValue = exports.getStoredPerPageValue = exports.computeHeaderWidth = exports.computeHeaderMapping = void 0;
const ROWS_PER_PAGE_KEY = 'rowsPerPage';
const DEFAULT_PER_PAGE_VALUE = 10;
const computeHeaderWidth = function (headers) {
  let hasCheckbox = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  if (!headers || headers?.length === 0) return [];
  const {
    headers: tempHeaders,
    frCount = 0,
    percentageTotal = 0,
    fixedTotal = 0
  } = headers.reduce((prev, curr) => {
    const widthValue = Number(curr?.columnWidth?.replace(/\D/g, '') ?? 0);
    if (curr?.columnWidth?.includes('%')) {
      const newState = {
        ...prev,
        headers: [...prev.headers, {
          ...curr,
          widthPercentage: widthValue
        }],
        percentageTotal: prev.percentageTotal += widthValue
      };
      return newState;
    }
    if (curr?.columnWidth?.includes('px')) {
      const newState = {
        ...prev,
        headers: [...prev.headers, {
          ...curr,
          widthFixed: widthValue
        }],
        fixedTotal: prev.fixedTotal += widthValue
      };
      return newState;
    }
    return {
      ...prev,
      headers: [...prev.headers, {
        ...curr,
        widthFraction: 1
      }],
      frCount: prev.frCount + 1
    };
  }, {
    headers: [],
    frCount: 0,
    percentageTotal: 0,
    fixedTotal: 0
  });
  const computedHeaders = tempHeaders.map(ea => {
    const {
      widthFraction = 0,
      columnWidth,
      ...rest
    } = ea;
    let computedWidth = '';
    if (widthFraction !== 0) {
      const baseLine = frCount === 0 ? 1 : frCount;
      computedWidth = `calc((( 100% - ${hasCheckbox ? '56px' : '0px'} - ${percentageTotal}% - ${fixedTotal}px ) / ${baseLine}) * ${widthFraction})`;
    }
    return {
      ...rest,
      computedWidth: computedWidth || columnWidth
    };
  });
  return computedHeaders;
};
exports.computeHeaderWidth = computeHeaderWidth;
const computeHeaderMapping = headers => {
  return headers.reduce((prev, curr, currentIndex) => {
    if (!curr?.id) return prev;
    return {
      ...prev,
      [curr.id]: currentIndex
    };
  }, {});
};
exports.computeHeaderMapping = computeHeaderMapping;
const getStoredPerPageValue = () => {
  return Number(localStorage.getItem(ROWS_PER_PAGE_KEY) ?? DEFAULT_PER_PAGE_VALUE);
};
exports.getStoredPerPageValue = getStoredPerPageValue;
const setStoredPerPageValue = newValue => {
  localStorage.setItem(ROWS_PER_PAGE_KEY, newValue);
};
exports.setStoredPerPageValue = setStoredPerPageValue;