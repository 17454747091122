"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useStyles = void 0;
var _styles = require("@material-ui/core/styles");
const useStyles = exports.useStyles = (0, _styles.makeStyles)(theme => ({
  root: {
    flexGrow: 1
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  title: {
    flexGrow: 1,
    marginLeft: 16,
    [theme.breakpoints.down('xs')]: {
      display: 'block',
      left: 0,
      right: 0,
      bottom: 0
    }
  },
  titleCentralized: {
    flexGrow: 1,
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0
  },
  link: {
    color: 'white',
    marginRight: 16,
    [theme.breakpoints.down('xs')]: {
      marginRight: 0,
      width: '100%',
      marginBottom: 15,
      display: 'block'
    }
  },
  linkCentralized: {
    color: 'white',
    position: 'relative',
    top: 0,
    bottom: 0,
    left: 20
  },
  hiddenLink: {
    display: 'none'
  },
  divCentralizedLinks: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-around'
  },
  appBar: {
    minHeight: 108,
    position: 'relative',
    bottom: 0,
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center'
    }
  },
  appBarCentralized: {
    minHeight: 108,
    position: 'relative',
    bottom: 0,
    justifyContent: 'center',
    textAlign: 'center'
  },
  containerTitles: {
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column-reverse'
    }
  }
}));