"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _styles = require("@material-ui/core/styles");
var useStyles = (0, _styles.makeStyles)(() => ({
  textField: {
    marginBottom: '16px'
  },
  placeholder: {
    padding: '24px 16px'
  },
  noteButtons: {
    float: 'right',
    right: 16,
    bottom: 12,
    height: 42
  },
  noteDivider: {
    marginTop: 60,
    width: '100%'
  },
  savedTextField: {
    backgroundColor: 'var(--leme-color-gray-1)',
    borderRadius: '8px !important',
    minHeight: 56,
    justifyContent: 'center',
    '& .MuiInput-underline:before': {
      borderBottom: 'none'
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottom: 'none'
    },
    '& fieldset': {
      border: 'none'
    }
  },
  icon: {
    color: '#000'
  },
  menuItemCascade: {
    '& .MuiListItem-root > .MuiBadge-root': {
      marginRight: 21
    }
  },
  iconPopover: {
    color: 'rgba(0,0,0,0.54)',
    minWidth: '24px',
    marginRight: '21px'
  },
  iconMenuPopover: {
    color: 'rgba(0,0,0,0.54)'
  },
  iconMenu: {
    backgroundColor: 'transparent !important',
    float: 'right',
    padding: 0,
    marginRight: 16
  },
  chapterTitleInHighlight: {
    margin: 16,
    '& span': {
      marginBottom: 2
    }
  },
  popup: {
    zIndex: 2000
  },
  menuItemDivider: {
    marginTop: '5px',
    marginBottom: '5px'
  },
  popupDesktop: {
    width: '146px'
  },
  textHighlighted: {
    margin: 0
  }
}));
var _default = exports.default = useStyles;