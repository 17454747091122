"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _styles = require("@material-ui/core/styles");
var useStyles = (0, _styles.makeStyles)(theme => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    width: '100%'
  },
  title: {
    color: theme.palette.text.secondary,
    fontSize: 20,
    margin: 0,
    fontWeight: 500,
    marginRight: 16
  },
  titleMobile: {
    color: theme.palette.text.secondary,
    fontSize: 14,
    margin: 0,
    fontWeight: 500
  },
  top: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start'
  },
  divider: {
    height: '0.1px',
    borderTop: '0.1px solid #E0E0E0',
    width: '100%',
    marginTop: 24,
    marginBottom: 0
  },
  copyDiv: {
    marginTop: 16
  },
  reference: {
    margin: 0,
    color: theme.palette.text.primary,
    fontSize: 14
  },
  referenceMobile: {
    margin: 0,
    color: theme.palette.text.primary,
    fontSize: 12
  },
  snackBar: {
    minWidth: 340,
    position: 'fixed',
    zIndex: 1000,
    top: 24,
    right: 24
  },
  snackBarMobile: {
    width: '96%',
    position: 'fixed',
    zIndex: 1000,
    top: 98,
    left: 7
  },
  label: {
    margin: '16px 0',
    color: theme.palette.text.primary,
    fontSize: 14,
    fontWeight: 500
  },
  labelMobile: {
    margin: '16px 0',
    color: theme.palette.text.primary,
    fontSize: 14,
    fontWeight: 500,
    marginBottom: 8
  }
}));
var _default = exports.default = useStyles;