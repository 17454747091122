"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _styles = require("@material-ui/core/styles");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
var drawerWidth = 360;
var useStyles = (0, _styles.makeStyles)(theme => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    [theme.breakpoints.down(835)]: {
      width: '100%'
    },
    '& .MuiBackdrop-root': {
      backgroundColor: 'transparent'
    }
  },
  drawerPaper: {
    width: drawerWidth,
    overflowY: 'hidden',
    [theme.breakpoints.down(835)]: {
      width: '100%'
    }
  },
  title: {
    whiteSpace: 'nowrap',
    width: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  drawerClose: {
    color: 'rgba(33, 37, 41, 1)'
  },
  drawerHeader: _objectSpread(_objectSpread({
    display: 'flex',
    alignItems: 'center',
    height: 64,
    padding: theme.spacing(2, 2)
  }, theme.mixins.toolbar), {}, {
    justifyContent: 'space-between'
  }),
  drawerContent: {
    overflowY: 'auto',
    height: '100vh'
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginLeft: 0
  },
  tab: {
    minWidth: 'auto',
    width: '120px',
    letterSpacing: '0.02250em'
  }
}));
var _default = exports.default = useStyles;