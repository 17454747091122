/* eslint-disable */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useContext } from 'react'
import PropTypes from 'prop-types'
import { useHistory, NavLink } from 'react-router-dom'
import { Menu, MenuItem } from '@material-ui/core'
import { Card } from '@minha-biblioteca/saraiva-ui'
import { useMediaQuery } from 'react-responsive'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import services from '../../../services'
import { ORIGIN as BOOK_ORIGIN } from '../../../constants/Book'
import { BooksContext } from '../../../contexts/BooksContext'

import useStyles from './styles'
import {
  toCamelCase,
  getEdition,
  getAuthors,
  bookUrl,
  getBookReaderUrl,
  saveLastScrollPosition
} from '../../../utils'

export default function BookGalleryMode({ book, favoriteClicked, fluxo, favorites }) {
  const classes = useStyles()
  const history = useHistory()
  const [anchorEl, setAnchorEl] = useState(null)
  const popoverOpened = window.Boolean(anchorEl)
  const isResponsible = useMediaQuery({ query: `(max-width: 425px)` });
  const {
    getFavoriteBooks
  } = useContext(BooksContext)

  const handleOpenPopover = (evt) => {
    setAnchorEl(evt.currentTarget)
  }

  const handleClosePopover = () => {
    setAnchorEl(null)
  }

  const onClickFavorite = (book, event, tracking) => {
    favoriteClicked(book, event, tracking)
  }

  const isFavorite = (uuid) => {
    const data = favorites
    return data.some((item) => {
      return item.id === uuid
    })
  }

  const onClickDetalhes = (book) => {
    saveLastScrollPosition()
    services.Trackings.lastBookDetails(book, {
      origin: 'Capa',
      viewMode: 'Abertos Recentemente',
      format: book.format
    })
    history.push({pathname: bookUrl(book), state:{type: BOOK_ORIGIN.LAST_BOOK}})
  }

  const onClickEvent = (book) => {
    saveLastScrollPosition()
    const favorited = isFavorite(book.uuid)
    if(favorited) {
      services.Trackings.trackOpenFavorites(book, {
        event: 'acessou_livro_favoritos',
        fluxo: fluxo
      })
    }

    services.Trackings.trackOpenLastBook(book, {
      origin: 'Dropdown',
      viewMode: 'Livro Aberto Recentemente',
      format: book.format
    })
    history.push(getBookReaderUrl(book))
  }

  return (
    <div className={classes.root}>
      <Card
        onFavorite={handleOpenPopover}
        onClick={() => onClickDetalhes(book)}
        iconImage={<MoreVertIcon style={{fontSize: 18, color: 'black'}} />}
        classNameImage={classes.image}
        classNameIconButton={classes.icon}
        imageHoverText={'DETALHES'}
        image={book.imageUrl}
        showIconButton
        favorite={() => isFavorite(book.uuid)}
        handleFavorite={() => onClickFavorite(book, "favorite", "favoritou_livro")}
        handleUnfavorite={() => onClickFavorite(book, "unfavorite", "desfavoritou_livro")}
        size={ isResponsible ? 'sm' : 'md'}
      >
        <NavLink
          className={classes.mainContent}
          onClick={() => {
            saveLastScrollPosition()
            services.Trackings.lastBookDetails(book, {
            origin: 'Capa',
            viewMode: 'Abertos Recentemente',
            format: book.format
            })
          }}
          to={{
            pathname: bookUrl(book),
            state: { type: BOOK_ORIGIN.LAST_BOOK }
          }}

        >
          <div className={classes.mainContent}>
            <div className={classes.titleContainer}>
              <h1 className={classes.title}>{toCamelCase(book.titleCover)}</h1>
            </div>

            <div className={classes.subtitleContainer}>
              <span className={classes.subtitle}>{getEdition(book)}</span>
            </div>

            <div className={classes.subtitleContainer}>
              <span className={classes.subtitle}>{getAuthors(book)}</span>
            </div>

            <div className={classes.subtitleContainer}>
              <span className={classes.subtitle}>{book.format}</span>
            </div>

          </div>
        </NavLink>
      </Card>
      <Menu
          id="long-menu"
          className={classes.popoverContainer}
          anchorEl={anchorEl}
          keepMounted
          open={popoverOpened}
          onClose={handleClosePopover}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          PaperProps={{
            style: {
              maxHeight: 200,
              width: '20ch',
              borderRadius: 4
            }
          }}
        >
          <MenuItem
            onClick={() => {
              onClickEvent(book)
               services.Trackings.trackOpenBook(book, {
               viewMode: 'Detalhes do Livro',
               format: book.format
              })
            }}
          >
            Ler livro
          </MenuItem>

          <MenuItem
            onClick={() => {
                services.Trackings.lastBookDetails(book, {
                origin: 'Dropdown',
                viewMode: 'Abertos Recentemente',
                format: book.format
              })
              history.push({
                pathname: bookUrl(book),
                state: { type: BOOK_ORIGIN.LAST_BOOK }
              })
            }}
          >
            Detalhes
          </MenuItem>
        </Menu>
    </div>
  )
}

BookGalleryMode.propTypes = {
  book: PropTypes.shape({
    titleCover: PropTypes.string.isRequired,
    uuid: PropTypes.string.isRequired,
    imageUrl: PropTypes.string.isRequired,
    authors: PropTypes.arrayOf(PropTypes.string)
  }).isRequired
}