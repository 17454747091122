"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _styles = require("@material-ui/core/styles");
var styles = (0, _styles.makeStyles)(theme => ({
  appBar: {
    height: '100vh',
    left: 0,
    width: '120px',
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  toolbar: {
    padding: 0,
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  },
  logo: {
    marginTop: '12px',
    marginBottom: '24px',
    width: '48px',
    height: '40px',
    position: 'absolute',
    bottom: 0
  },
  backButton: {
    margin: '12px 8px 22px ',
    height: 42,
    width: 105
  },
  toolbarNavigationContainer: {
    backgroundColor: '#212529',
    flexDirection: 'column',
    height: 'auto',
    width: '100%'
  },
  toolbarNavigation: {
    color: '#fff',
    marginBottom: '16px'
  },
  tollbarNavigationDisabled: {
    color: '#8C7BAE',
    marginBottom: '16px',
    cursor: 'not-allowed !important',
    pointerEvents: 'auto !important'
  },
  toolbarNavigationSelected: {
    color: '#fff !important'
  },
  tooltip: {
    maxWidth: 154
  }
}));
var _default = exports.default = styles;