"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.sepiaTheme = exports.defaultTheme = exports.darkTheme = exports.brighterTheme = exports.READER_HIGHLIGHT_COLORS = exports.HIGHLIGHT_COLORS = exports.EPUB_READER_THEMES = exports.DEFAULT_PREFERENCES = exports.DEFAULT_HIGHLIGHT_COLOR = exports.COLORS = void 0;
var EPUB_READER_THEMES = exports.EPUB_READER_THEMES = {
  default: {
    body: {
      color: '#000000',
      backgroundColor: 'var(--leme-color-gray-1)'
    }
  },
  brighter: {
    body: {
      color: '#000000',
      backgroundColor: '#FFFFFF'
    }
  },
  dark: {
    body: {
      color: 'var(--leme-color-gray-1)',
      backgroundColor: '#424242'
    },
    p: {
      color: 'rgba(255, 255, 255, 0.7) !important'
    },
    h1: {
      color: 'rgba(255, 255, 255, 0.7) !important'
    },
    h3: {
      color: 'rgba(255, 255, 255, 0.7) !important'
    },
    h4: {
      color: 'rgba(255, 255, 255, 0.7) !important'
    },
    h5: {
      color: 'rgba(255, 255, 255, 0.7) !important'
    },
    span: {
      color: 'rgba(255, 255, 255, 0.7) !important'
    },
    a: {
      color: '#816AB1'
    }
  },
  sepia: {
    body: {
      color: '#000000',
      backgroundColor: '#FBF0D9'
    }
  }
};
var defaultTheme = exports.defaultTheme = EPUB_READER_THEMES.default.body;
var darkTheme = exports.darkTheme = EPUB_READER_THEMES.dark.body;
var sepiaTheme = exports.sepiaTheme = EPUB_READER_THEMES.sepia.body;
var brighterTheme = exports.brighterTheme = EPUB_READER_THEMES.brighter.body;
var DEFAULT_PREFERENCES = exports.DEFAULT_PREFERENCES = {
  backgroundStyle: 'default',
  columns: 'one',
  fontSize: 20
};
var HIGHLIGHT_COLORS = exports.HIGHLIGHT_COLORS = {
  green: '#9ec3b7',
  yellow: '#f0d894',
  red: '#f1acae',
  purple: '#c0acf1'
};
var READER_HIGHLIGHT_COLORS = exports.READER_HIGHLIGHT_COLORS = {
  green: '#1F8563',
  yellow: '#FFBE00',
  red: '#FF000B',
  purple: '#5000FF'
};
var DEFAULT_HIGHLIGHT_COLOR = exports.DEFAULT_HIGHLIGHT_COLOR = 'green';
var COLORS = exports.COLORS = {
  GREEN: 'green',
  YELLOW: 'yellow',
  RED: 'red',
  PURPLE: 'purple'
};