"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _styles = require("@material-ui/core/styles");
var useStyles = (0, _styles.makeStyles)(() => ({
  root: {
    marginTop: '16px',
    width: '100%'
  },
  chapterItem: {
    '& span': {
      marginBottom: 2
    }
  }
}));
var _default = exports.default = useStyles;