"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _styles = require("@material-ui/core/styles");
var useStyles = (0, _styles.makeStyles)(() => ({
  placeholder: {
    padding: '24px 16px'
  }
}));
var _default = exports.default = useStyles;