"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useStyles = void 0;
var _styles = require("@material-ui/core/styles");
const useStyles = exports.useStyles = (0, _styles.makeStyles)(() => {
  return {
    root: {
      flexGrow: props => {
        return props.fullWidth ? 1 : 'initial';
      }
    }
  };
});