import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    marginBottom: 32,
    display: 'flex',
    [theme.breakpoints.down(769)]: {
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginBottom: 32,
      height: '100%'
    },
    [theme.breakpoints.down(426)]: {
      marginTop: '13px !important',
      height: 290
    },
    '& .MuiCardContent-root': {
      padding: '0px 16px 16px 16px'
    },
    '& .MuiTypography-h6': {
      fontSize: '15px'
    },
    '& .MuiPaper-root': {
      marginTop: '0px !important',
      height: '100%'
    }
  },
  noContent: {
    display: 'none'
  },

  mainContent: {
    minHeight: '100px',
    textDecoration: 'none'
  },

  titleContainer: {
    overflow: 'hidden',
    marginBottom: 8,
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    [theme.breakpoints.down(469)]: {
      whiteSpace: 'break-spaces'
    }
  },

  title: {
    display: 'block',
    margin: 0,
    fontWeight: 400,
    lineHeight: 1.5,
    letterSpacing: '0.15px',
    textAlign: 'left',
    color: 'rgba(33, 37, 41, 1)',
    fontSize: '14px !important',
    [theme.breakpoints.down(1200)]: {
      fontSize: '14px !important'
    },
    [theme.breakpoints.down(348)]: {
      fontSize: '14px !important'
    }
  },

  subtitleContainer: {
    overflow: 'hidden',
    marginBottom: 4,
    display: '-webkit-box',
    '-webkit-line-clamp': 1,
    '-webkit-box-orient': 'vertical'
  },

  subtitle: {
    display: 'block',
    textAlign: 'left',
    color: 'rgba(73, 80, 87, 1)',
    fontSize: '12px !important',
    [theme.breakpoints.down(1200)]: {
      fontSize: '12px !important'
    },
    [theme.breakpoints.down(348)]: {
      fontSize: '12px !imporntat'
    }
  },

  icon: {
    backgroundColor: '#E0E0E0 !important',
    width: 26,
    height: 26
  },

  popoverContainer: {
    marginTop: 53,
    [theme.breakpoints.up(1200)]: {
      marginTop: 47
    }
  },
  image: {
    width: '124px !important',
    height: '175px !important',
    cursor: 'pointer',
    marginBottom: 16,
    [theme.breakpoints.down(426)]: {
      height: '129px !important'
    }
  }
}))

export default useStyles
