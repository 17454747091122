"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useStyles = void 0;
var _styles = require("@material-ui/core/styles");
var _tinycolor = _interopRequireDefault(require("tinycolor2"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
const useStyles = exports.useStyles = (0, _styles.makeStyles)(theme => ({
  root: {
    width: '100%',
    borderRadius: '8px',
    '&.MuiLinearProgress-colorPrimary': {
      backgroundColor: (0, _tinycolor.default)(theme.palette.primary.main).setAlpha(0.2)
    },
    '&.MuiLinearProgress-barColorPrimary': {
      backgroundColor: theme.palette.primary.main
    },
    '&.MuiLinearProgress-colorSecondary': {
      backgroundColor: (0, _tinycolor.default)(theme.palette.secondary.main).setAlpha(0.2)
    },
    '&.MuiLinearProgress-barColorSecondary': {
      backgroundColor: theme.palette.secondary.main
    }
  },
  bar: {
    borderRadius: '8px'
  },
  barSuccess: {
    backgroundColor: theme.palette.success.light,
    borderRadius: '8px'
  },
  label: {
    fontSize: 16,
    margin: '16px 0',
    color: theme.palette.text.secondary
  },
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  readerBarP: {
    backgroundColor: theme.palette.text.disabled
  },
  comment: {
    fontSize: 12,
    color: theme.palette.text.secondary,
    marginTop: 10
  }
}));