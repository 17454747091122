"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _styles = require("@material-ui/core/styles");
const useStyles = (0, _styles.makeStyles)(theme => ({
  bottomWrapper: {
    position: 'fixed',
    width: '100%',
    bottom: 0,
    boxShadow: '0px 6px 8px 4px',
    zIndex: 2000
  },
  bottomButtons: {
    maxWidth: 298
  },
  bottomIOS: {
    position: 'fixed',
    width: '100%',
    bottom: 0,
    boxShadow: '0px 6px 8px 4px',
    zIndex: 2000,
    height: 56,
    alignItems: 'start'
  },
  icon: {
    color: theme.palette.text.secondary
  },
  iconActive: {
    color: theme.palette.primary.main
  },
  paragraph: {
    margin: 0,
    fontSize: 12,
    color: theme.palette.text.secondary
  },
  paragraphActive: {
    margin: 0,
    fontSize: 12,
    color: theme.palette.primary.main
  }
}));
var _default = exports.default = useStyles;