"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _styles = require("@material-ui/core/styles");
const useStyles = (0, _styles.makeStyles)(theme => ({
  searchWrapper: {
    display: 'flex',
    width: '100%',
    height: 36,
    backgroundColor: theme.palette.common.white,
    borderTop: `1px solid #E5E5E5`,
    borderRight: `1px solid #E5E5E5`,
    borderBottom: `1px solid #E5E5E5`,
    borderRadius: 4
  },
  inputContainer: {
    backgroundColor: theme.palette.primary.main,
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    margin: 0,
    padding: 0,
    '& form': {
      backgroundColor: theme.palette.common.white,
      margin: '14px 24px'
    },
    '&:hover': {
      backgroundColor: theme.palette.primary.main
    }
  },
  item: {
    maxHeight: 36,
    maxWidth: 86
  },
  options: {
    marginTop: 34,
    boxShadow: '0px 5px 5px -3px rgba (0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12)!important',
    borderRadius: '4px!important',
    maxWidth: 86,
    '& .MuiList-padding': {
      paddingTop: 8,
      paddingBottom: 9,
      fontSize: 16
    }
  },
  centralized: {
    position: 'absolute',
    left: '50%',
    transform: 'translateX(-50%)'
  },
  adornment: {
    minWidth: 24,
    width: 24,
    color: theme.palette.text.primary,
    verticalAlign: 'middle',
    marginLeft: 12
  },
  adornmentRight: {
    minWidth: 24,
    width: 24,
    color: theme.palette.text.primary,
    verticalAlign: 'middle',
    marginLeft: 24,
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)'
    }
  },
  adornmentBack: {
    minWidth: 24,
    width: 24,
    color: theme.palette.text.primary,
    verticalAlign: 'middle',
    marginLeft: 24
  },
  buttonAdornment: {
    minWidth: 24,
    width: 24,
    padding: 0,
    marginRight: 12
  },
  closeButton: {
    '&:hover': {
      background: 'none'
    }
  },
  input: {
    width: '100%'
  },
  filterLabel: {
    color: theme.palette.text.secondary,
    fontSize: 12
  },
  menuContainer: {
    background: theme.palette.background.default,
    width: '100%',
    height: '100vh',
    marginTop: '2.8rem',
    flexDirection: 'column',
    position: 'absolute',
    left: 0,
    right: 0,
    display: 'flex'
  },
  filterItem: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    '&:hover': {
      backgroundColor: '#EEEEEE'
    }
  },
  filterValue: {
    fontSize: 14,
    fontWeight: 400,
    letterSpacing: 0.15,
    color: theme.palette.text.primary
  },
  divider: {
    width: '100%',
    marginTop: 16,
    marginBottom: 16
  },
  filterContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  errorForm: {
    border: `1px solid ${theme.palette.error.main} !important`,
    borderRadius: '2px'
  },
  search: {
    border: `1px solid ${theme.palette.action.disabled}`,
    borderRadius: 4,
    transition: 'all .3s',
    '&:hover': {
      border: `1px solid ${theme.palette.text.primary}`
    }
  },
  searchActive: {
    border: `2px solid ${theme.palette.primary.main}`,
    '&:hover': {
      border: `2px solid ${theme.palette.primary.main}`
    }
  }
}));
var _default = exports.default = useStyles;