import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& .MuiPaper-root': {
      height: '100% !important',
      width: '100% !important',
      alignItems: 'flex-start !important',
      marginTop: '24px !important',
      marginLeft: '0px !important',
      [theme.breakpoints.down(768)]: {
        marginRight: '0px !important'
      }
    },
    '& .MuiCardMedia-root': {
      width: '120px !important',
      marginTop: '24px !important',
      marginRight: '24px !important',
      marginLeft: '24px !important',
      marginBottom: '24px !important'
    },
    '& .MuiTypography-h6': {
      fontSize: '15px'
    },
    '& .MuiCardContent-root': {
      paddingLeft: '0px !important',
      paddingTop: '24px !important',
      [theme.breakpoints.down(576)]: {
        width: '100%'
      }
    }
  },
  desktopMode: {
    display: 'none',
    width: '100%',
    [theme.breakpoints.up(576)]: {
      display: 'block'
    }
  },
  mobileMode: {
    display: 'none',
    width: '100%',
    [theme.breakpoints.down(576)]: {
      display: 'block',
      textDecoration: 'none'
    }
  },
  card: {
    display: 'flex',
    padding: 24,
    minHeight: 188,
    marginBottom: 24,
    [theme.breakpoints.down(576)]: {
      padding: 16,
      minHeight: 120,
      marginBottom: 16
    }
  },
  content: {
    marginTop: '-0.2rem',
    [theme.breakpoints.down(576)]: {
      width: '90%',
      marginRight: 10,
      '& .MuiCardContent-root': {
        width: '90% !important'
      }
    }
  },
  titleContainer: {
    marginBottom: 6,
    [theme.breakpoints.down(576)]: {
      marginBottom: 8,
      overflow: 'hidden',
      display: '-webkit-box',
      '-webkit-line-clamp': 3,
      '-webkit-box-orient': 'vertical'
    }
  },
  title: {
    display: 'block',
    margin: 0,
    padding: 0,
    paddingRight: 60,
    fontSize: 16,
    fontWeight: 400,
    color: 'rgba(33, 37, 41, 1)',
    lineHeight: '24px',
    [theme.breakpoints.down(645)]: {
      fontSize: 14,
      fontWeight: 400,
      lineHeight: '20px',
      letterSpacing: '0.15px',
      color: 'rgba(33, 37, 41, 1)'
    },
    [theme.breakpoints.down(576)]: {
      fontSize: 14,
      fontWeight: 400,
      lineHeight: '20px',
      letterSpacing: '0.15px',
      color: 'rgba(33, 37, 41, 1)'
    }
  },
  subtitleContainer: {
    [theme.breakpoints.down(576)]: {
      overflow: 'hidden',
      display: '-webkit-box',
      '-webkit-line-clamp': 1,
      '-webkit-box-orient': 'vertical'
    }
  },
  subtitle: {
    display: 'block',
    margin: 0,
    padding: 0,
    marginBottom: 4,
    fontSize: 14,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.43,
    letterSpacing: '0.15px',
    color: 'rgba(73, 80, 87, 1)',
    [theme.breakpoints.down(645)]: {
      display: 'block',
      marginBottom: 4,
      fontSize: 14,
      color: 'rgba(73, 80, 87, 1)'
    },
    [theme.breakpoints.down(576)]: {
      marginBottom: 4,
      fontSize: 12,
      color: 'rgba(73, 80, 87, 1)'
    }
  },
  truncate: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: 'block'
  },
  img: {
    objectFit: 'fill',
    cursor: 'pointer',
    marginBottom: '0px !important',
    height: '250px!important',
    '& .makeStyles-mediaChildren-192': {
      bottom: '16px!important',
      left: '16px!important'
    },
    [theme.breakpoints.down(961)]: {
      width: '154px !important',
      height: '200px !important',
      '& .makeStyles-mediaChildren-192': {
        bottom: '16px!important',
        left: '16px!important'
      }
    }
  },
  buttons: {
    marginTop: '1.2rem',
    [theme.breakpoints.down(576)]: {
      display: 'none'
    }
  },
  primaryButton: {
    marginRight: 16
  },
  secondaryButton: {
    marginRight: 0,
    [theme.breakpoints.down(820)]: {
      marginRight: 44
    }
  },
  iconButton: {
    backgroundColor: '#E0E0E0 !important',
    width: 26,
    height: 26
  },
  icon: {
    width: 18,
    height: 18,
    color: 'black'
  },
  popoverLink: {
    background: 'transparent',
    border: 'none',
    color: 'black',
    fontSize: 16,
    cursor: 'pointer',
    outline: 0,
    '& > a': {
      textDecoration: 'none',
      color: 'black'
    }
  },
  contentContainer: {
    width: '100%',
    display: 'flex',
    [theme.breakpoints.up(576)]: {
      display: 'none'
    }
  },
  linkContainer: {
    [theme.breakpoints.down(576)]: {
      flex: 1
    }
  }
}))

export default useStyles
