import queryString from 'query-string'
import services from '../services'
import { saveLastScrollPosition } from '../utils'

export const useAuthentication = () => {
  async function logout() {
    const { REACT_APP_SSO_URL } = process.env

    await services.Trackings.logout()

    localStorage.removeItem('sessionToken')

    window.location = `${REACT_APP_SSO_URL}/logout`
  }

  function activateCoupom() {
    const queryStrings = queryString.parse(window.location.search)
    let url = '/tickets/activate'

    if (queryStrings['no-access']) {
      url = '/tickets/activate?no-access=true'
    }

    saveLastScrollPosition()
    window.location.assign(url)
  }

  return [logout, activateCoupom]
}
