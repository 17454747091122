import Users from '../accounts/users'

const Suggestions = {
  async listSuggestions(type) {
    const payload = {
      action: 'recommend',
      app_name: 'bds',
      users: [Users.getUserId()],
      model: type.suggestionType,
      k: type.suggestionsNumber,
      cae: type.suggestionCae
    }
    const resultProd = await fetch(
      'https://us-central1-symbolic-wind-235918.cloudfunctions.net/baas_recommender',
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload)
      }
    )
    const resultSTG = await fetch(
      'https://us-central1-stone-facility-241418.cloudfunctions.net/baas_recommender',
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload)
      }
    )
    if (process.env.REACT_APP_TOKEN === 'a6bc757d2df972ab8f7e3cfe499d147') {
      return resultSTG
    }
    return resultProd
  }
}
export default Suggestions
