import { getSessionToken, parseJwt } from '../../auth'

const Users = {
  getFullName() {
    const token = getSessionToken()
    if (!token) { return '' }
    const { user } = parseJwt(token)

    return `${user.first_name} ${user.last_name}`
  },

  getEmail() {
    const token = getSessionToken()
    if (!token) { return '' }
    const { user } = parseJwt(token)

    return user.email
  },

  getUserId() {
    const token = getSessionToken()
    if (!token) { return '' }
    const { user } = parseJwt(token)

    return user.id
  },

  getIntegrationType() {
    const token = getSessionToken()
    if (!token) { return '' }
    const { user } = parseJwt(token)

    return user.integration_type
  }

}

export default Users
