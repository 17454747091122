import React from 'react'
import { Navbar, UnstyledButton, Text, Group, MediaQuery, Flex, Anchor } from '@mantine/core';
import { Link } from 'react-router-dom';
import { useSearch } from '../../contexts/SearchContext'

const MobileNavbar = () => {

  const { toggleShowMobileSearch } = useSearch();

  const textStyles = {
    fontWeight: 'var(--leme-typography-weight-medium)',
    color: 'var(--leme-color-gray-9)',
    fontSize: '0.625rem',
    lineHeight: '1rem',
  }

  const navBarStyles = {
    backgroundColor: 'var(--leme-color-gray-0)',
    padding: 0,
    border:'none',
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 1300,
    width: '100vw',
    gap: 0,
    justifyContent: 'space-between',
  }

  const buttonStyles = {
    height: '40px',
    color: 'var(--leme-color-gray-6)'
  }

  return (
    <MediaQuery largerThan="md" styles={{ display: 'none' }}>
      <Navbar height={72} style={navBarStyles} id='leme-mobile-navbar'>
        <Group position="apart" style={{ padding: '1rem 1.5rem' }}>
          <Anchor component={Link} to="/" style={{ textDecoration: 'none' }}>
            <Flex justify="center" align="center" direction="column" style={buttonStyles}>
              <span className="material-symbols-outlined" aria-label="icone de catálogo">browse</span>
              <Text style={textStyles}>Catálogo</Text>
            </Flex>
          </Anchor>
          <UnstyledButton onClick={toggleShowMobileSearch}>
            <Flex justify="center" align="center" direction="column" style={buttonStyles}>
              <span className="material-symbols-outlined" aria-label="icone de busca">search</span>
              <Text style={textStyles}>Busca</Text>
            </Flex>
          </UnstyledButton>
          <Anchor component={Link} to="/favorites" style={{ textDecoration: 'none' }}>
            <Flex justify="center" align="center" direction="column" style={buttonStyles}>
              <span className="material-symbols-outlined" aria-label="icone de favoritos">favorite</span>
              <Text style={textStyles}>Favoritos</Text>
            </Flex>
          </Anchor>
        </Group>
      </Navbar>
    </MediaQuery>
  );
};

export default MobileNavbar;
