import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  mainContent: {
    height: '100%',
    textDecoration: 'none',
    paddingRight: 8,
    paddingLeft: 5,
    '& .MuiCardContent-root': {
      paddingTop: '0px !important'
    }
  },

  titleContainer: {
    overflow: 'hidden',
    marginBottom: 8,
    display: '-webkit-box',
    '-webkit-line-clamp': 3,
    '-webkit-box-orient': 'vertical'
  },

  title: {
    display: 'block',
    margin: 0,
    fontWeight: 400,
    lineHeight: 1.5,
    letterSpacing: '0.15px',
    textAlign: 'left',
    color: 'rgba(33, 37, 41, 1)',
    fontSize: '16px !important',
    [theme.breakpoints.down(1200)]: {
      fontSize: '16px !important'
    },
    [theme.breakpoints.down(348)]: {
      fontSize: '16px !important'
    }
  },

  subtitleContainer: {
    overflow: 'hidden',
    marginBottom: 4,
    display: '-webkit-box',
    '-webkit-line-clamp': 1,
    '-webkit-box-orient': 'vertical'
  },

  subtitle: {
    display: 'block',
    textAlign: 'left',
    color: 'rgba(73, 80, 87, 1)',
    fontSize: '14px !important',
    [theme.breakpoints.down(1200)]: {
      fontSize: '14px !important'
    },
    [theme.breakpoints.down(348)]: {
      fontSize: '14px important'
    }
  },

  img: {
    objectFit: 'fill',
    cursor: 'pointer',
    marginBottom: '0px !important',
    height: '250px!important',
    [theme.breakpoints.down(961)]: {
      width: '154px !important',
      height: '200px !important'
    }
  },

  icon: {
    backgroundColor: '#E0E0E0 !important',
    width: 26,
    height: 26
  },

  popoverContainer: {
    marginTop: 53,
    [theme.breakpoints.up(1200)]: {
      marginTop: 47
    }
  }
}))

export default useStyles
