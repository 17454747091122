/* eslint-disable */
import httpClient from '../../gateway/http_request'
import { getSessionToken } from '../../auth'

const Categories = {
  async listCategories() {
    try {
      let headers = { 'session-token': getSessionToken() }
      const path = '/contents/api/v1/fetch_categories'
  
      headers = httpClient.buildHeader(headers)
  
      const response = await httpClient.get(path, null, headers)
      return response
    } catch(err) {
      // eslint-disable-next-line
      console.log(err)
    }
  }
}

export default Categories
