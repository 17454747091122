"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useStyles = void 0;
var _styles = require("@material-ui/core/styles");
const useStyles = exports.useStyles = (0, _styles.makeStyles)(theme => ({
  root: {
    borderRadius: '0px 0px 4px 4px',
    cursor: 'pointer',
    position: 'absolute',
    top: 0,
    right: 32,
    boxShadow: '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12)'
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    padding: '6px 8px',
    justifyContent: 'center',
    gap: 8,
    margin: 0,
    fontSize: 14,
    minWidth: 194,
    minHeight: 36
  },
  children: {
    backgroundColor: '#ffffff',
    padding: 16,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'black',
    transition: 'all 2s',
    boxShadow: 'rgba(0, 0, 0, 0.16) 0px 0px 2px'
  },
  primaryVariant: {
    backgroundColor: `${theme.palette.primary.main} !important`
  },
  secondaryVariant: {
    backgroundColor: `${theme.palette.secondary.main} !important`
  },
  primaryLightVariant: {
    backgroundColor: `${theme.palette.primary.light} !important`
  },
  primaryDarkVariant: {
    backgroundColor: `${theme.palette.primary.dark} !important`
  },
  secondaryLightVariant: {
    backgroundColor: `${theme.palette.secondary.light} !important`
  },
  secondaryDarkVariant: {
    backgroundColor: `${theme.palette.secondary.dark} !important`
  }
}));