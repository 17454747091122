"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _styles = require("@material-ui/core/styles");
var useStyles = (0, _styles.makeStyles)(() => ({
  rootPopover: {
    width: 312,
    height: 'auto',
    borderRadius: 8
  },
  rootPopoverReference: {
    width: 600,
    height: 'auto',
    borderRadius: 8
  },
  title: {
    margin: '16px 24px',
    height: 32,
    display: 'flex',
    justifyContent: 'space-between'
  },
  divider: {
    marginBottom: 24
  },
  closePopover: {
    padding: 0
  },
  iconButtonLabel: {
    display: 'flex',
    flexFlow: 'column'
  },
  mobile: {
    display: 'none'
  },
  bottom: {
    width: '100%',
    paddingRight: 8,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    minHeight: 53
  }
}));
var _default = exports.default = useStyles;