"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useStyles = exports.alertStyles = void 0;
var _styles = require("@material-ui/core/styles");
const alertStyles = exports.alertStyles = (0, _styles.makeStyles)(theme => ({
  root: {
    '& .MuiCheckbox-root': {
      color: theme.palette.primary.main,
      [theme.breakpoints.down('xs')]: {
        paddingTop: 0,
        top: 0,
        alignSelf: 'flex-start'
      }
    },
    '& .MuiFormControlLabel-label': {
      color: theme.palette.text.primary
    },
    '& .MuiDialogContent-root': {
      padding: 24,
      color: theme.palette.text.secondary,
      fontSize: 16,
      display: 'flex',
      alignItems: 'center',
      minHeight: 72,
      [theme.breakpoints.up('xs')]: {
        maxWidth: '100%'
      }
    }
  },
  tooltipcontent: {
    width: '90%',
    overflowWrap: 'break-word',
    marginLeft: 8
  },
  primaryButton: {
    color: 'theme.palette.background.paper !important',
    backgroundColor: theme.palette.primary.main,
    transition: 'all .3s',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark
    },
    '&:disabled': {
      color: theme.palette.text.disabled,
      backgroundColor: theme.palette.action.disabled
    }
  },
  icon: {
    color: theme.palette.primary.main,
    marginTop: 5,
    marginLeft: 8
  },
  warning: {
    color: theme.palette.primary.main,
    alignSelf: 'flex-start',
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    }
  },
  checkBox: {
    marginLeft: 0,
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      alignItems: 'flex-start',
      paddingTop: 9
    }
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'flex-start'
  },
  contentActions: {
    minWidth: 180,
    justifyContent: 'end',
    display: 'flex'
  },
  buttonClose: {
    float: 'right',
    position: 'absolute',
    right: 8,
    top: 8,
    color: theme.palette.grey[500]
  },
  primaryButtonStepper: {
    gap: 8,
    marginLeft: 8
  },
  stepper: {
    backgroundColor: theme.palette.background.paper,
    marginRight: 16,
    '& .MuiMobileStepper-dot': {
      marginRight: 8,
      width: 10,
      height: 10
    }
  }
}));
const useStyles = exports.useStyles = (0, _styles.makeStyles)(theme => ({
  secondaryButton: {
    color: theme.palette.text.secondary
  },
  primaryButton: {
    color: theme.palette.primary.main
  },
  root: {
    '& .MuiDialogContent-root': {
      boxSizing: 'border-box',
      [theme.breakpoints.up('xs')]: {
        maxWidth: '100%'
      }
    },
    '& .MuiDialogContent-dividers': {
      padding: 24,
      color: theme.palette.text.secondary,
      fontSize: 16,
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'flex-start'
    },
    '& .MuiDialogActions-root': {
      height: '58px !important',
      boxSizing: 'border-box'
    }
  }
}));