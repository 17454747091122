"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getContentLines = exports.formatAbntText = void 0;
exports.getDeviceType = getDeviceType;
exports.getIntegrationType = getIntegrationType;
exports.stripHtmlTags = void 0;
var _exceptions = require("./exceptions");
function getDeviceType() {
  var ua = navigator.userAgent;
  if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
    return 'tablet';
  }
  if (/Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)) {
    return 'mobile';
  }
  return 'desktop';
}
function parseJwt(token) {
  if (!token) {
    return {};
  }
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(atob(base64).split('').map(c => "%".concat("00".concat(c.charCodeAt(0).toString(16)).slice(-2))).join(''));
  return JSON.parse(jsonPayload);
}
function getIntegrationType() {
  var token = localStorage.getItem('sessionToken');
  if (!token) {
    return '';
  }
  var {
    user
  } = parseJwt(token);
  return user.integration_type;
}
var stripHtmlTags = text => text.replace(/<\/?[^>]+(>|$)/g, '');
exports.stripHtmlTags = stripHtmlTags;
var testSpecialChars = (param, list) => {
  if (param.includes(',') && list.includes(param.replace(/[.,()]/g, ''))) return true;
  if (param.includes('.') && list.includes(param.replace(/[.,()]/g, ''))) return true;
  if (param.includes('(') && list.includes(param.replace(/[.,()]/g, ''))) return true;
  if (param.includes(')') && list.includes(param.replace(/[.,()]/g, ''))) return true;
  if (param.includes(':') && list.includes(param.replace(/[.,()]/g, ''))) return true;
  return false;
};
var toCamelCase = string => {
  var words = string.split(' ');
  var lowerWords = words.map(word => word.toLowerCase());
  var formatedString = lowerWords.map(word => {
    if (_exceptions.siglas.includes(word) || testSpecialChars(word, _exceptions.siglas)) {
      return word.toUpperCase();
    }
    return _exceptions.stopwords.includes(word) ? word : word && word[0].toUpperCase() + word.substring(1);
  }).join(' ');
  return formatedString.charAt(0).toUpperCase() + formatedString.slice(1);
};
var formatAbntText = abnt => {
  var reference = abnt.match(/<strong[^>]*>([^<]+)<\/strong>/)[1];
  var referenceFormated = "<strong>".concat(toCamelCase(reference), "</strong>");
  return "".concat(abnt.replace(/<strong[^>]*>([^<]+)<\/strong>/, "<strong style=\"letter-spacing:0.5px\">".concat(referenceFormated, "</strong>")));
};
exports.formatAbntText = formatAbntText;
var getContentLines = (element, document) => {
  var height = element.scrollHeight;
  var lineHeight = parseInt(document.defaultView.getComputedStyle(element, null).lineHeight, 10);
  return height / lineHeight;
};
exports.getContentLines = getContentLines;