/* eslint-disable */
import React from 'react'
import { CardExtraContent } from '@minha-biblioteca/saraiva-ui'

import WorkRoundedIcon from '@material-ui/icons/WorkRounded'
import PieChartIcon from '@material-ui/icons/PieChart'
import GavelRoundedIcon from '@material-ui/icons/GavelRounded'

import admPDF from '../../utils/periodics/administracao.pdf'
import cbPDF from '../../utils/periodics/cienciasContabeis.pdf'
import direitoPDF from '../../utils/periodics/direito.pdf'

import { useStyles } from './styles'
import Trackings from '../../services/trackings/trackings'
import { Divider } from '@material-ui/core'

export default function Periodics() {
  const classes = useStyles()

  const openArchive = (param, periodicName) => {
    window.open(param)
    Trackings.trackOpenPDF({
      card: periodicName
    })
  }

  return (
    <div className={classes.wrapper}>
      <h6 className={classes.title}>Periódicos</h6>
      <Divider className={classes.divider} />
      <p className={classes.label}>
        Para facilitar seus estudos, aqui está uma curadoria de diversos links
        de periódicos feita por especialistas da Saraiva. Neles, você vai
        encontrar diversos artigos, resumos e resenhas publicadas por e para a
        comunidade acadêmica.
      </p>
      <p className={classes.labelMobile}>
        Nos periódicos você vai encontrar diversos artigos, resumos e resenhas
        publicadas por e para a comunidade acadêmica.
      </p>
      <div className={classes.cards}>
        <CardExtraContent
          title="Administração"
          hoverTitle="Acessar link"
          icon={<WorkRoundedIcon className={classes.icon} />}
          onClick={() => openArchive(admPDF, 'Administração')}
        />
        <CardExtraContent
          title="Ciências contábeis"
          hoverTitle="Acessar link"
          icon={<PieChartIcon className={classes.icon} />}
          onClick={() => openArchive(cbPDF, 'Ciências Contábeis')}
        />
        <CardExtraContent
          title="Direito"
          hoverTitle="Acessar link"
          icon={<GavelRoundedIcon className={classes.icon} />}
          onClick={() => openArchive(direitoPDF, 'Direito')}
        />
      </div>
    </div>
  )
}
