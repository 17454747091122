import React from 'react'
import { Box, Text, Button, Group } from '@mantine/core'
import { ProfileIcon } from './styles'

export default function CustomTooltip({ step, closeProps }) {
  return (
    <Box
      sx={(theme) => ({
        padding: theme.spacing.xl,
        borderRadius: theme.radius.lg,
        backgroundColor: theme.colors.gray[1],
        boxShadow: theme.shadows.md,
        maxWidth: 500
      })}
    >
      <Group position="apart" mb="lg" mt="lg">
        <Text
          sx={{
            fontFamily: 'Poppins, sans-serif',
            fontSize: '16px',
            fontWeight: 600,
            lineHeight: '24px',
            textAlign: 'left',
            color: '#212529'
          }}
        >
          {step.title || 'Menu, pesquisa e perfil'}
        </Text>
        <Button
          variant="subtle"
          size="xs"
          {...closeProps}
          data-testid="skip-button"
          sx={{
            fontFamily: 'Poppins, sans-serif',
            fontSize: '14px',
            fontWeight: 600,
            lineHeight: '24px',
            textAlign: 'left',
            color: '#212529'
          }}
        >
          Fechar
        </Button>
      </Group>

      <Group
        spacing="none"
        sx={{
          fontFamily: 'Poppins, sans-serif',
          fontSize: '14px',
          fontWeight: 400,
          lineHeight: '24px',
          color: '#212529'
        }}
      >
        <Text size="sm" mb="md" sx={{ color: '#212529' }}>
          Use o menu para navegar pelo catálogo, ver sua lista de favoritos e
          conhecer nossos periódicos.
        </Text>
        <Text size="sm" mb="md" sx={{ color: '#212529' }}>
          Pesquise pelo nome do livro, autor, ISBN ou palavra-chave para
          encontrar seu livro.
        </Text>
        <Group mb="md" spacing="lg" sx={{ color: '#212529' }}>
          <Text size="sm">Acesse seu perfil pelo ícone</Text>
          <ProfileIcon />
        </Group>
      </Group>
    </Box>
  )
}
