/* eslint-disable */

import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  root: {
    flex: 1
  },
  list: {
    flex: 1
  }
}))

export default useStyles
