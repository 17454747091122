"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _styles = require("@material-ui/core/styles");
const useStyles = (0, _styles.makeStyles)(theme => ({
  header: {
    height: 56,
    zIndex: 2000
  },
  toolbar: {
    [theme.breakpoints.down(835)]: {
      minHeight: '56px !important'
    }
  },
  searchButtons: {
    minWidth: 24,
    padding: 0,
    height: 24,
    margin: '6px 8px 6px 16px'
  },
  search: {
    display: 'flex',
    margin: 'auto',
    backgroundColor: theme.palette.common.white,
    height: 38,
    minWidth: '100%',
    border: '1px solid',
    borderColor: 'rgba(0, 0, 0, 0.23)',
    borderRadius: 4,
    [theme.breakpoints.down(835)]: {
      backgroundColor: theme.palette.common.white,
      position: 'relative',
      margin: 'auto',
      border: '1px solid',
      borderColor: 'rgba(0, 0, 0, 0.23)',
      height: 38,
      borderRadius: 4
    }
  },
  inputBaseRoot: {
    padding: '6px 0'
  },
  inputBase: {
    height: '100%',
    padding: 0
  }
}));
var _default = exports.default = useStyles;