import { Box, Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useStyles } from './style'
import badRequestImageMobile from '../../assets/logo/bad-request-error-image-mobile.png'
import badRequest from '../../assets/logo/bad-request.png'

export default function MaintenancePage() {
  const classes = useStyles()
  const location = useLocation()
  const [statusCode, setStatusCode] = useState()

  useEffect(() => {
    const code = location.state ? Number(location.state.code) : 0
    if (code !== 200) {
      setStatusCode(true)
    } else {
      setStatusCode(false)
    }
  }, [location])

  return (
    <>
      {statusCode && (
        <>
          <span className={classes.container}>
            <Box className={classes.pictureContainer}>
              <picture>
                <source className={classes.img} media="(min-width: 770px)" srcSet={badRequest} />
                <img className={classes.img} src={badRequestImageMobile} alt="" />
              </picture>
            </Box>
            <Box className={classes.titleContainer}>
              <Box className={classes.titleContent}>
                <Typography variant="h5" className={classes.title}>
                  Oops! Parece que algo deu errado.
                </Typography>
                <Typography variant="h4" className={classes.text}>
                  Nossa equipe técnica já foi notificada e está trabalhando para corrigir o problema. Por favor, tente novamente mais tarde.
                </Typography>
                <Typography variant="body1">
                  Em nome de toda a equipe Minha Biblioteca, pedimos desculpas pelo inconveniente!
                </Typography>
              </Box>
            </Box>
          </span>
        </>
      )}
    </>
  )
}
