import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      top: 56,
      left: 0,
      padding: 16,
      paddingTop: 0
    },
    marginTop: 0
  },
  title: {
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
      marginTop: 32
    },
    fontSize: 20,
    margin: 0,
    fontWeight: 500,
    marginTop: 72
  },
  label: {
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    },
    fontWeight: 400,
    fontSize: 14,
    margin: 0,
    marginBottom: 24
  },
  labelMobile: {
    [theme.breakpoints.down('xs')]: {
      fontWeight: 400,
      fontSize: 14,
      margin: 0,
      marginBottom: 24,
      color: theme.palette.text.secondary,
      display: 'flex'
    },
    display: 'none'
  },
  cards: {
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      gap: 24
    },
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%'
  },
  icon: {
    [theme.breakpoints.down('xs')]: {
      fontSize: 78
    },
    fontSize: 112
  },
  divider: {
    [theme.breakpoints.down('xs')]: {
      margin: '6px 0 14px 0'
    },
    margin: '6px 0 16px 0'
  }
}))
