"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _styles = require("@material-ui/core/styles");
var useStyles = (0, _styles.makeStyles)(() => ({
  item: {
    marginTop: 5
  },
  itemHeader: {
    padding: 0
  },
  itemMenu: {
    color: '#000000'
  },
  menu: {
    zIndex: 2000
  },
  menuItemDivider: {
    marginTop: 5,
    marginBottom: 5
  },
  menuIcon: {
    color: 'rgba(0,0,0,0.54)'
  },
  title: {
    marginLeft: 16
  },
  actions: {
    justifyContent: 'flex-end'
  },
  savedTextField: {
    backgroundColor: 'var(--leme-color-gray-1)',
    borderRadius: '8px !important',
    minHeight: 56,
    justifyContent: 'center',
    '& .MuiInput-underline:before': {
      borderBottom: 'none'
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottom: 'none'
    },
    '& fieldset': {
      border: 'none'
    }
  }
}));
var _default = exports.default = useStyles;