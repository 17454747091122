"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _styles = require("@material-ui/core/styles");
var useStyles = (0, _styles.makeStyles)(() => ({
  ellipsis: {
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden'
  },
  link: {
    background: 'none',
    border: 'none',
    color: '#069',
    cursor: 'pointer',
    padding: 0
  },
  detailText: {
    display: 'inline',
    paddingRight: 8
  }
}));
var _default = exports.default = useStyles;