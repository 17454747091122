/* eslint-disable */
import { getSessionToken } from '../../auth'
import httpClient from '../../gateway/http_request'

const Pages = {
  async getPages() {
    let headers = { 'session-token': getSessionToken()}
    const path = `/booknotes/api/v1/pages`

    headers = httpClient.buildHeader(headers)

    const response = await httpClient.get(path, null, headers)
    return response
  }
}

export default Pages
