"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _styles = require("@material-ui/core/styles");
var useStyles = (0, _styles.makeStyles)(() => ({
  root: {
    width: '100%',
    padding: 0
  },
  chapterName: {
    color: 'rgba(33, 37, 41, 1)',
    fontSize: 14,
    float: 'left'
  },
  item: {
    display: 'flex',
    paddingRight: 10,
    paddingTop: 10,
    marginBottom: -10
  },
  placeholder: {
    padding: '24px 16px'
  },
  icon: {
    color: '#000'
  },
  markIcon: {
    color: '#000',
    marginRight: 20
  },
  iconMenu: {
    backgroundColor: 'transparent !important',
    padding: 4
  },
  summaryContainer: {
    display: 'flex',
    paddingRight: 10
  },
  summary: {
    overflow: 'hidden',
    display: '-webkit-box',
    '-webkit-line-clamp': 3,
    '-webkit-box-orient': 'vertical',
    fontSize: 12,
    color: 'rgba(33, 37, 41, 1)',
    letterSpacing: 0.4
  },
  divider: {
    marginTop: 10
  }
}));
var _default = exports.default = useStyles;