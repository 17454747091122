"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getInitials = getInitials;
exports.listUser = listUser;
function listUser(userText, length) {
  let text = userText;
  if (text.length <= length) {
    return text;
  }
  text = text.substring(0, length);
  return `${text}...`;
}
function getInitials(username) {
  const splittedName = username.trim().split(' ');
  if (splittedName[0] === '' || splittedName[0] === null) return null;
  if (splittedName.length > 1) {
    const firstInitial = splittedName[0][0];
    const lastInitial = splittedName.pop()[0];
    return (firstInitial + lastInitial).toUpperCase();
  }
  return username.substring(0, 2).toUpperCase();
}