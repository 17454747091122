"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _styles = require("@material-ui/core/styles");
var useStyles = (0, _styles.makeStyles)(() => ({
  rootPopover: {
    width: 312,
    borderRadius: 8,
    zIndex: 1500
  },
  popoverTitle: {
    margin: '12px auto 11px 16px',
    height: 32,
    display: 'flex'
  },
  popoverClose: {
    position: 'relative',
    left: 180,
    bottom: 8,
    height: 48,
    marginRight: 4
  },
  editButton: {
    width: 280,
    height: 42,
    margin: 16
  },
  popoverText: {
    marginLeft: 16,
    marginBottom: 16
  },
  noteColor: {
    display: 'flex',
    margin: 16,
    justifyContent: 'space-around'
  },
  colorSpacing: {
    marginRight: 8
  },
  popoverActions: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: 16,
    justifyContent: 'flex-start',
    alignItems: 'flex-start'
  },
  icon: {
    marginRight: 12
  },
  btn: {
    width: '100%',
    justifyContent: 'flex-start',
    textTransform: 'none',
    fontSize: 16
  }
}));
var _default = exports.default = useStyles;