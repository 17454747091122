/* eslint-disable react/forbid-prop-types */
import React, { useState, createContext } from 'react'
import PropTypes from 'prop-types'

const BookContext = createContext()

function BookProvider({ children }) {
  const [showingCopyMessage, setCopyMessage] = useState(false)

  return (
    <BookContext.Provider
      value={{
        showingCopyMessage,
        setCopyMessage
      }}
    >
      {children}
    </BookContext.Provider>
  )
}

BookProvider.propTypes = {
  children: PropTypes.node.isRequired
}

export { BookContext, BookProvider }
