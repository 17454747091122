import { styled } from '@material-ui/core/styles'
import {
  Snackbar,
  Typography
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'

export const CustomSnackBar = styled(Snackbar)`
    position:absolute;
    z-index: 999999999999999!important;
    &.MuiPaper-root {
        margin-top: 25px ;
        z-index: 999999999999999!important;
    }
`
export const CustomAlert = styled(Alert)`
  .MuiAlert-root {
        margin-top: 25px ;
        z-index: 999999999999999!important;
    }
`

export const Text = styled(Typography)((theme) => ({
  color: theme.palette.text.primary,
  fontSize: 14,
  zIndex: '999999999999999!important'
}))
