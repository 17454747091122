"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
function replaceSpecialChars(string, replacer) {
  return string.replace(/[^A-Z0-9]/ig, replacer);
}
function capitalize(string) {
  var splitStr = string.toLowerCase().split(' ');
  for (var i = 0; i < splitStr.length; i++) {
    splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  return splitStr.join(' ');
}
var String = {
  replaceSpecialChars,
  capitalize
};
var _default = exports.default = String;