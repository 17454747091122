/* eslint-disable */
/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useContext, useState } from 'react'
import PropTypes from 'prop-types'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { Paper, Divider, Typography } from '@material-ui/core'
import { Button } from '@minha-biblioteca/saraiva-ui'
import CopyIcon from '@material-ui/icons/FileCopyOutlined'
import services from '../../../services'
import { BookContext } from '../../../contexts/BookContext'

import {
  FORMAT as BOOK_FORMAT,
  ORIGIN as BOOK_ORIGIN
} from '../../../constants/Book'
import { ORIGIN as COPY_ORIGIN } from '../../../constants/Copy'
import useStyles from '../styles'
import {
  toCamelCase,
  getEdition,
  getAuthors,
  copyTextToClipBoard,
  stripHtml,
  getBookReaderUrl,
  formatAbntText,
  decodeBookDescription
} from '../../../utils'
import { useMediaQuery } from 'react-responsive'
import BooksRecommendation from '../../../components/BooksRecommendation'
import { Favorite, FavoriteBorder, MenuBook } from '@material-ui/icons'
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart'
import SnackBar from '../../../components/SnackBar'
import { LibraryContext } from '../../../contexts/LibraryContext'
import { BooksContext } from '../../../contexts/BooksContext'

export default function Content({ book, bookCallback, fluxo}) {
  const classes = useStyles()
  const history = useHistory()
  const location = useLocation()
  const { setCopyMessage } = useContext(BookContext)
  const { getLastBooks, lastBooks, getFavoriteBooks, unfavoriteBook, favoriteBook } = useContext(BooksContext)
  const [severity, setSeverity] = useState('success')
  const [alertMessage, setAlertMessage] = useState('Livro adicionado aos favoritos')
  const [clicked, setClicked] = useState(false)
  const [favorites, setFavorites] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [isFavorite, setIsFavorite] = useState(false)
  const isResponsible = useMediaQuery({ query: `(max-width: 768px)` })
  const [externalContent, setExternalContent] = useState(true)
  const { libraryCode } = useContext(LibraryContext)

  const [redirectTo, setRedirectTo] = useState(
    'https://conteudo.saraivaconecta.com.br/livro/666998'
    )
    useEffect(() => {
    document.addEventListener('copy', (event) => {
      if (event.target.id === 'abnt-text') {
        setCopyMessage(true)
      }
    })
  }, [])
  useEffect(() => {
    getLastBooks()
  }, [])
  const renderCategories = () => {
    return `${toCamelCase(book.category)}, ${toCamelCase(book.subcategory)}`
  }
  const onClickFavorite = async (book, event) => {
    switch (event) {
      case "favorite":
          const favorite = await favoriteBook(book.uuid)
          if (favorite) {
            setIsFavorite(true)
            bookCallback('Livro adicionado aos favoritos', false)
            await services.Trackings.favoritesTracking(book, {event: "favoritou_livro"})
          }
      break;
      case "unfavorite":
        const data = await getFavoriteBooks(2)
        const id = data.find((item) => {
          return item.favoriteId === book.uuid
        })
        const unfavorite = await unfavoriteBook(id.id)
        if (unfavorite) {
          setIsFavorite(false)
          bookCallback('Livro removido dos favoritos', false)
          await services.Trackings.favoritesTracking(book, {event: "desfavoritou_livro"})
        }
      break;
      default:
    }
  }
  async function handleFavorites () {
    setIsLoading(true)
    const data = await getFavoriteBooks(1)
    setFavorites(data)
    data.filter((item) => {
      if(item.id === book.uuid) setIsFavorite(true)
    })
    setIsLoading(false)
  }

  useEffect(() => {
    handleFavorites()
  },[clicked])

  const handleClick = () => {
    if(isFavorite)  onClickFavorite(book,"unfavorite")
    else onClickFavorite(book,"favorite")
  }

  const onClickOpenBook = () => {
    if (isFavorite) {
      services.Trackings.trackOpenFavorites(book, {
        event: 'acessou_livro_favoritos',
        fluxo
      })
    }

    if (location.state?.type === BOOK_ORIGIN.LAST_BOOK) {
      services.Trackings.trackOpenLastBook(book, {
        origin: 'Detalhes do Livro Aberto Recentemente',
        viewMode: 'Abertos Recentemente',
        format: book.format
      })
    }

    if (location.state?.type === BOOK_ORIGIN.RECOMMENDED) {
      services.Trackings.trackOpenRecommendedBook(book, {
        origin: 'Drawer',
        format: book.format
      })

    }
  }

  const handleBookCover = (book) => {
    if (isFavorite) {
      services.Trackings.trackOpenFavorites(book, {
        event: 'acessou_livro_favoritos',
        fluxo
      })
    }
  }

  const handleKeyDown = (event) => {
    event.preventDefault()
    const charCode = String.fromCharCode(event.which).toLowerCase()
    if ((event.ctrlKey || event.metaKey) && charCode === 'c') {
      services.Trackings.copyAbnt({
        uuid: book.uuid,
        origin: COPY_ORIGIN.BOOK_DETAILS
      })
    }
  }

  return (
    <>
      <Paper variant="outlined" className={classes.root}>
        <div className={classes.top}>
          {isResponsible && (
            <Button
              className={
                isFavorite ? classes.favoriteColor : classes.favoriteButton
              }
              startIcon={isFavorite ? <Favorite /> : <FavoriteBorder />}
              onClick={handleClick}
              aria-label={isFavorite ? 'Desfavoritar Livro' : 'Favoritar Livro'}
            />
          )}
          <Link
            onClick={() => {
              handleBookCover(book)
                services.Trackings.trackOpenBook(book, {
                viewMode: 'Detalhes do Livro',
                format: book.format
              })
            }}


            className={classes.imageContainer}
            to={getBookReaderUrl(book)}
          >
            <img
              src={book.imageUrl}
              alt="Capa do Livro"
              className={classes.image}
            />
            <span className={classes.imageLabel}>Ler Livro</span>
          </Link>
          {isResponsible && (
            <div className={classes.buttonsMobile}>
              <Button
                color="primary"
                variant="contained"
                fullWidth
                onClick={() => history.push(getBookReaderUrl(book))}
              >
                Ler Livro
              </Button>

              {book.physicalIsbn && (
                <>
                 <a
                  href={`https://www.editoradodireito.com.br/${book.physicalIsbn}`}
                  target="_blank"
                  style={{ textDecoration: 'none', width: '100%' }}
                >
                  <Button
                    color="secondary"
                    variant="outlined"
                    fullWidth
                    onClick={() =>
                      services.Trackings.trackECommerce(book, {
                        origin: 'Detalhes',
                        format: book.format,
                        category: book.category
                      })
                    }
                  >
                    <ShoppingCartIcon
                      fontSize="20"
                      style={{ marginRight: 6 }}
                    />
                    Livro físico
                  </Button>
                </a>
                </>
                )}
            </div>
          )}
          <div className={classes.mainInfoContainer}>
            <div className={classes.favoriteContainer}>
              <h1 className={classes.title}>{toCamelCase(book.titleCover)}</h1>
              {!isResponsible && (
                !libraryCode && <Button
                  className={
                    isFavorite ? classes.favoriteColor : classes.favoriteButton
                  }

                  startIcon= {  isFavorite ? !libraryCode && <Favorite /> :  <FavoriteBorder /> }
                  onClick= {handleClick}
                />
              )}
            </div>
            <span className={classes.text}>{getEdition(book)}</span>
            <span className={classes.text}>Autores: {getAuthors(book)}</span>
            <span className={classes.text}>
              Categorias: {renderCategories()}
            </span>
            <span className={classes.text}>Formato: {book.format}</span>
            {!isResponsible && (
              <div className={classes.buttons}>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    onClickOpenBook(book)
                    services.Trackings.trackOpenBook(book, {
                      viewMode: 'Detalhes do Livro',
                      format: book.format
                    })
                    history.push(getBookReaderUrl(book))
                  }}
                >
                  Ler Livro
                </Button>

                {book.physicalIsbn &&  book.physicalCrmStatus == 'Disponivel'&& (
                  <a
                  href={`https://www.editoradodireito.com.br/${book.physicalIsbn}`}
                  target="_blank"
                  style={{ textDecoration: 'none' }}
                >
                  <Button
                    color="secondary"
                    variant="outlined"
                    onClick={() =>
                      services.Trackings.trackECommerce(book, {
                        origin: 'Detalhes',
                        format: book.format,
                        category: book.category
                      })
                    }
                  >
                    <ShoppingCartIcon
                      fontSize="20"
                      style={{ marginRight: 6 }}
                    />
                    Livro físico
                  </Button>
                </a>
                )}
              </div>
            )}
          </div>
        </div>
        {/* {externalContent && (
          <div
            className={`${classes.externalInfo} ${classes.externalInfoMobile}`}
          >
            <MenuBook
              className={`${classes.externalInfoIcon} ${classes.externalInfoIconMobile}`}
            />
            <Typography variant="body1" className={classes.externalTextBody1}>
              Este livro possui materiais complementares!
              <a
                href={redirectTo}
                target="_blank"
                className={classes.externalInfoLink}
              >
                Clique aqui
              </a>
              <span className={classes.externalTextBody2}>
                para acessar o conteúdo.
              </span>
            </Typography>
          </div>
        )} */}
        <Divider />
        <div className={classes.content}>
          <h2 className={classes.subtitle}>Sinopse</h2>
          <span className={classes.text} dangerouslySetInnerHTML={{
              __html: book.description
            }} />
        </div>
        <div className={classes.content}>
          <h2 className={classes.subtitle}>
            Referência bibliográfica
            <Button
              color="secondary"
              onClick={() => {
                copyTextToClipBoard(
                  `${formatAbntText(book.abnt).replace(
                    /<\/?strong>/g,
                    ''
                  )}. E-book.`
                )
                services.Trackings.copyAbnt({
                  uuid: book.uuid,
                  origin: COPY_ORIGIN.BOOK_DETAILS
                })
              }}
              startIcon={<CopyIcon />}
              className={classes.copyBtn}
            >
              <span className={classes.copyBtnLabel}>Copiar</span>
            </Button>
          </h2>
          <div
            onKeyDown={handleKeyDown}
            contentEditable={Boolean(true)}
            spellcheck="false"
            className={classes.text}
            id="abnt-text"
            dangerouslySetInnerHTML={{
              __html: `${formatAbntText(book.abnt)}. <em>E-book</em>.`
            }}
          />
        </div>
        <div className={classes.content}>
          <h2 className={classes.subtitle}>ISBN</h2>
          <span className={classes.text}>Digital: {book.isbn}</span>
        </div>
        <div className={classes.content}>
          <h2 className={classes.subtitle}>Todos os autores</h2>
          <span className={classes.text}>
            {getAuthors(book, { displayAll: true })}
          </span>
        </div>
      </Paper>
      {/* <div className={classes.recommendation}>
        <BooksRecommendation recommendation={lastBooks} />
      </div> */}
    </>
  )
}
Content.propTypes = {
  book: PropTypes.shape({
    titleCover: PropTypes.string.isRequired,
    category: PropTypes.string.isRequired,
    subcategory: PropTypes.string.isRequired,
    isbn: PropTypes.string.isRequired,
    imageUrl: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    abnt: PropTypes.string.isRequired,
    uuid: PropTypes.number.isRequired
  }).isRequired,
  bookCallback: PropTypes.func,
  fluxo: PropTypes.string
}

Content.defaultProps = {
  fluxo: '' ,
  bookCallback: () => null
}