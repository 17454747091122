import React, { createContext, useState, useContext } from 'react';

const SearchContext = createContext();

export const SearchProvider = ({ children }) => {
  const [showMobileSearch, setShowMobileSearch] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  const redirectToSearchResults = () => {
    if (searchTerm) {
      window.location.assign(`/books?query=${searchTerm.trim()}`);
      setSearchTerm('');
    }
  };

  const toggleShowMobileSearch = () => {
    setShowMobileSearch((prevState) => !prevState);
  };

  return (
    <SearchContext.Provider value={{ searchTerm, setSearchTerm, redirectToSearchResults, showMobileSearch, toggleShowMobileSearch }}>
      {children}
    </SearchContext.Provider>
  );
};

export const useSearch = () => useContext(SearchContext);
