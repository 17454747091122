"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _styles = require("@material-ui/core/styles");
var styles = (0, _styles.makeStyles)(() => ({
  container: {
    display: 'flex',
    width: '100vw',
    height: '100vh',
    backgroundColor: 'var(--leme-color-gray-1)'
  },
  rightContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  },
  loading: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#707070'
  },
  navigation: {
    justifyContent: 'center'
  }
}));
var _default = exports.default = styles;