/* eslint-disable */
/* eslint-disable react/forbid-prop-types */
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Redirect } from 'react-router-dom'
import queryString from 'query-string'
import { Snackbar } from '@minha-biblioteca/saraiva-ui'
import jwtDecode from 'jwt-decode'
import Bugsnag from '@bugsnag/js'
import Loading from '../../../components/Loading/index.js'
import services from '../../../services'
import { setSessionToken, setSessionStorage } from '../../../auth'
// import { BooksContext } from '../../../contexts/BooksContext.js'
import {LibraryContext} from '../../../contexts/LibraryContext'

export default function JwtAuthentication() {
  const [authenticated, setAuthenticated] = useState(false)
  const [showError, setShowError] = useState(false)
  const { libraryCode, setLibraryCode } = useContext(LibraryContext)

  const fetchData = useCallback(async () => {

      try {
        const parsed = queryString.parse(window.location.search)
        const response = await services.Sessions.loginByJwt(parsed.client_name, parsed.token)
        const decode = jwtDecode(parsed.token)

        if (!response.ok) {
          setShowError(true)
          localStorage.removeItem('sessionToken')
          return
        }
          const result = await response.json()

          if (decode.library_code === 'amostra_escritorio' ) {
            setLibraryCode(true)
            sessionStorage.setItem('tokenDesgutacao',parsed.token)
            setAuthenticationDataAndUpdateState(result, true)
            setTimeout(()=> {
              localStorage.removeItem('sessionToken')
            }, 1000)

          } else {
            setAuthenticationDataAndUpdateState(result)
            setLibraryCode(false)
          }


          services.Trackings.integrationLogin()

      } catch (err) {
        Bugsnag.notify(err);
      }

  },[])
  console.log('libraryCode',libraryCode)
  useEffect(() => {



    fetchData()
  }, [])

  function setAuthenticationDataAndUpdateState({ tokens: { local_token } } , useSessionStorage=false) {
    if (!useSessionStorage ){
      setSessionToken(local_token)
    }  else {
      setSessionStorage(local_token)
    }
    setAuthenticated(true)
  }

  function closeSnackbar() {
    setShowError(false)
  }

  if (authenticated) {
    return <Redirect to="/books" />
  }

  return (
    <>
      <Snackbar
        show={showError}
        severity="error"
        handleClose={closeSnackbar}
        autoHideDuration={3000}
      >
        Ocorreu um erro no login.
      </Snackbar>
      <Loading />
    </>
  )
}