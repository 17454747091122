"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _Fusion = _interopRequireDefault(require("../request/Fusion"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
function getCopyright(applicationToken, sessionToken, userId, uuid) {
  return _Fusion.default.get("/booknotes/api/v1/notification/user_id/".concat(userId, "/book_id/").concat(uuid), {
    headers: {
      'application-token': applicationToken,
      'session-token': sessionToken
    }
  }).then(response => response);
}
function setCopyright(applicationToken, sessionToken, userId, uuid, warn) {
  return _Fusion.default.post('/booknotes/api/v1/notification', {
    user_id: userId,
    book_id: uuid,
    warn_copyright: warn
  }, {
    headers: {
      'application-token': applicationToken,
      'session-token': sessionToken
    }
  }).then(response => response);
}
function getCharactersRemaining(applicationToken, sessionToken, userId, uuid, totalCharBook) {
  return _Fusion.default.get("/booknotes/api/v1/charactersremaining/user_id/".concat(userId, "/book_id/").concat(uuid, "/total_char_book/").concat(totalCharBook), {
    responseType: 'json',
    headers: {
      'application-token': applicationToken,
      'session-token': sessionToken
    }
  }).then(response => response.data);
}
function getCharactersCopied(applicationToken, sessionToken, userId, uuid) {
  return _Fusion.default.get("/booknotes/api/v1/characterscopied/user_id/".concat(userId, "/book_id/").concat(uuid), {
    responseType: 'json',
    headers: {
      'application-token': applicationToken,
      'session-token': sessionToken
    }
  }).then(response => response);
}
function setCharactersCopied(applicationToken, sessionToken, payload) {
  return _Fusion.default.post('/booknotes/api/v1/characterscopy', payload, {
    headers: {
      'application-token': applicationToken,
      'session-token': sessionToken
    }
  }).then(response => response);
}
var Copy = {
  getCopyright,
  setCopyright,
  getCharactersRemaining,
  getCharactersCopied,
  setCharactersCopied
};
var _default = exports.default = Copy;