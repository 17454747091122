"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useStyles = void 0;
var _styles = require("@material-ui/core/styles");
const useStyles = exports.useStyles = (0, _styles.makeStyles)(theme => ({
  root: {
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'row',
      width: '100%',
      height: 98,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between'
    },
    border: '1px solid #e0e0e0',
    width: 264,
    height: 200,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    margin: 0,
    '&:hover': {
      cursor: 'pointer'
    },
    '& .MuiCardContent-root': {
      position: 'relative',
      padding: 0
    },
    '& .MuiCardActions-root': {
      [theme.breakpoints.down('xs')]: {
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        padding: '0 18px'
      },
      backgroundColor: theme.palette.background.paper,
      minHeight: 40,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '8px 0'
    },
    '& .MuiCardMedia-media': {
      [theme.breakpoints.down('xs')]: {
        width: 138,
        height: 98
      },
      minHeight: 160,
      opacity: 1,
      width: '100%',
      transition: '.5s ease',
      backfaceVisibility: 'hidden',
      '&:hover': {
        opacity: 0.3
      }
    }
  },
  title: {
    fontSize: 16,
    display: 'flex',
    alignItems: 'center',
    margin: 0,
    transition: '.5s ease',
    '&:hover': {
      textDecoration: 'underline'
    },
    [theme.breakpoints.down(420)]: {
      fontSize: 14
    }
  },
  icon: {
    fontSize: 14,
    transform: 'rotate(-44.02deg)',
    marginLeft: 4
  },
  hover: {
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.action.active,
    transition: '.5s ease',
    opacity: 0,
    position: ' absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&:hover': {
      opacity: 1
    }
  },
  hoverLink: {
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
      textAlign: 'center'
    },
    fontWeight: 500,
    fontSize: 20,
    color: '#ffffff',
    maxWidth: '95%',
    textAlign: 'center',
    wordBreak: 'break-word'
  },
  iconDiv: {
    [theme.breakpoints.down('xs')]: {
      width: 138,
      height: 98
    },
    minHeight: 160,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#ffffff',
    transition: '.2s ease',
    backfaceVisibility: 'hidden',
    '&:hover': {
      opacity: 0.3
    }
  },
  colorMain: {
    backgroundColor: theme.palette.primary.main
  },
  colorLight: {
    backgroundColor: theme.palette.primary.light
  }
}));