/* eslint-disable */
import Books from './contents/books'
import Sessions from './accounts/sessions'
import Users from './accounts/users'
import Tickets from './tickets/tickets'
import Trackings from './trackings/trackings'
import Pages from './booknotes/pages'
import Categories from './contents/categories'
import Suggestions from './contents/suggestions'

export default { Books, Sessions, Tickets, Users, Trackings, Pages, Categories, Suggestions }
