"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.THEMES = void 0;
exports.getTheme = getTheme;
var _theme = _interopRequireDefault(require("./theme"));
var _lfg = _interopRequireDefault(require("./lfg"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
const THEMES = exports.THEMES = Object.freeze({
  LFG: 'lfg',
  SARAIVA: 'saraiva'
});
function getTheme(theme) {
  switch (theme) {
    case THEMES.LFG:
      return _lfg.default;
    default:
      return _theme.default;
  }
}