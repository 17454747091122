/* eslint-disable */
import React, { useContext, useEffect } from 'react'
import MenuListCascade from '../../components/MenuListCascade'
import { useHistory } from 'react-router-dom'
import Layout from '../../layout'
import useStyles from './styles'
import { BooksContext } from '../../contexts/BooksContext'

export default function CategoryMenu() {
  const classes = useStyles()
  const history = useHistory()
  const { categories } = useContext(BooksContext)

  function onClickItem([item, subitem]) {
    if (subitem) {
      history.push(`/categories/${item.name}/${subitem.name.split("/").join("")}`)
    } else {
      history.push(`/categories/${item.name}`)
    }
  }

  return (
    <Layout noGutters noFooter>
      <MenuListCascade
        items={categories}
        onLabel={(item) => item.name}
        onKey={(item) => item.id}
        onChildren={(item) => item.subcategories}
        onClick={onClickItem}
        rootProps={{ className: classes.root }}
        listProps={{ className: classes.list }}
      />
    </Layout>
  )
}
