"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useSnackStyles = exports.useAlertStyles = void 0;
var _core = require("@material-ui/core");
const useSnackStyles = exports.useSnackStyles = (0, _core.makeStyles)(theme => ({
  root: {
    marginTop: 24,
    marginRight: 24
  },
  colorText: {
    color: theme.palette.text.primary,
    fontSize: 14
  }
}));
const useAlertStyles = exports.useAlertStyles = (0, _core.makeStyles)(theme => ({
  root: {
    alignItems: 'center'
  },
  standardSuccess: {
    '& .MuiAlert-icon': {
      color: theme.palette.success.dark
    },
    '& .MuiAlert-action': {
      color: theme.palette.success.dark
    }
  },
  standardInfo: {
    '& .MuiAlert-icon': {
      color: theme.palette.info.dark
    },
    '& .MuiAlert-action': {
      color: theme.palette.info.dark
    }
  },
  standardWarning: {
    '& .MuiAlert-icon': {
      color: theme.palette.warning.dark
    },
    '& .MuiAlert-action': {
      color: theme.palette.warning.dark
    }
  },
  standardError: {
    '& .MuiAlert-icon': {
      color: theme.palette.error.dark
    },
    '& .MuiAlert-action': {
      color: theme.palette.error.dark
    }
  }
}));