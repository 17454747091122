"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _styles = require("@material-ui/core/styles");
var useStyles = (0, _styles.makeStyles)(theme => ({
  topBarWrapper: {
    height: 56,
    backgroundColor: '#ffffff',
    boxShadow: 'none'
  },
  topBartoolbar: {
    justifyContent: 'space-between',
    display: 'flex'
  },
  topBarButton: {
    minWidth: 24,
    padding: '6px 0',
    color: theme.palette.text.secondary,
    '&:active': {
      color: theme.palette.primary.main
    }
  },
  topBarButtonDM: {
    minWidth: 24,
    padding: '6px 0',
    color: 'rgba(255, 255, 255, 0.7)'
  },
  locationContainer: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 12,
    color: 'rgba(73, 80, 87, 1)'
  },
  tooltipIcon: {
    marginLeft: 5
  },
  internalTooltipIcon: {
    marginRight: 10
  },
  tooltip: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#212529',
    marginTop: 5,
    color: 'white',
    fontSize: 14,
    fontWeight: 'normal',
    padding: '10px 16px'
  },
  icon: {
    marginRight: 8,
    color: theme.palette.text.secondary
  },
  mi: {
    padding: '0 16px',
    minHeight: 36
  },
  btn: {
    fontSize: 16,
    paddingLeft: 0,
    fontWeight: 400
  }
}));
var _default = exports.default = useStyles;