"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _styles = require("@material-ui/core/styles");
var _default = exports.default = (0, _styles.makeStyles)(theme => ({
  wrapper: {
    display: 'flex',
    maxHeight: 56
  },
  pages: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 16,
    letterSpacing: '0.15px',
    margin: '0 4px',
    color: theme.palette.action.active,
    [theme.breakpoints.down(770)]: {
      fontSize: 12
    }
  },
  arrowIcon: {
    fontSize: 38,
    margin: 0,
    padding: 0,
    color: theme.palette.text.secondary,
    [theme.breakpoints.down(770)]: {
      display: 'none'
    }
  },
  iconBtn: {
    [theme.breakpoints.down(770)]: {
      display: 'none'
    }
  }
}));