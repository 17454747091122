"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _styles = require("@material-ui/core/styles");
const useStyles = (0, _styles.makeStyles)(theme => ({
  table: {
    minWidth: 650
  },
  link: {
    color: '#212529'
  },
  orderedCell: {
    '& span': {
      marginLeft: 4,
      marginTop: 2,
      transition: 'all .2s',
      position: 'absolute'
    },
    '&:hover': {
      cursor: 'pointer'
    }
  },
  hide: {
    color: 'transparent'
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1
  },
  btnAction: {
    margin: '0',
    padding: '0 8px',
    minWidth: 0,
    backgroundColor: 'transparent',
    textDecoration: 'none',
    textTransform: 'none',
    color: theme.palette.info.main,
    lineHeight: '100%',
    '&:hover': {
      backgroundColor: 'transparent'
    }
  }
}));
var _default = exports.default = useStyles;