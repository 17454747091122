"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _Array = _interopRequireDefault(require("../utils/Array"));
var _String = _interopRequireDefault(require("../utils/String"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
function asyncGeneratorStep(n, t, e, r, o, a, c) { try { var i = n[a](c), u = i.value; } catch (n) { return void e(n); } i.done ? t(u) : Promise.resolve(u).then(r, o); }
function _asyncToGenerator(n) { return function () { var t = this, e = arguments; return new Promise(function (r, o) { var a = n.apply(t, e); function _next(n) { asyncGeneratorStep(a, r, o, _next, _throw, "next", n); } function _throw(n) { asyncGeneratorStep(a, r, o, _next, _throw, "throw", n); } _next(void 0); }); }; }
function getOutlineByPage(_x, _x2) {
  return _getOutlineByPage.apply(this, arguments);
}
function _getOutlineByPage() {
  _getOutlineByPage = _asyncToGenerator(function* (pdf, page) {
    var pdfOutline = (yield pdf.viewer.pdfDocument.getOutline()) || [];
    var pdfOutlineFlatten = _Array.default.flattenArray(pdfOutline, 'items');
    var pdfPage = yield pdf.viewer.pdfDocument.getPage(page);
    var pdfPageNum = pdfPage.ref.num;
    var isThisOutline = item => item.dest[0].num === pdfPageNum;
    var isWithinThisOutline = (item, nextItem) => {
      var _nextItem$dest$;
      return item.dest[0].num < pdfPageNum && (nextItem === null || nextItem === void 0 || (_nextItem$dest$ = nextItem.dest[0]) === null || _nextItem$dest$ === void 0 ? void 0 : _nextItem$dest$.num) > pdfPageNum;
    };
    return pdfOutlineFlatten.find((item, i, arr) => isThisOutline(item) || isWithinThisOutline(item, arr[i + 1]));
  });
  return _getOutlineByPage.apply(this, arguments);
}
function getContentFromPage(_x3, _x4) {
  return _getContentFromPage.apply(this, arguments);
}
function _getContentFromPage() {
  _getContentFromPage = _asyncToGenerator(function* (pdf, page) {
    var pdfPage = yield pdf.viewer.pdfDocument.getPage(page);
    return pdfPage.getTextContent();
  });
  return _getContentFromPage.apply(this, arguments);
}
function getAllContentFromPdf(_x5) {
  return _getAllContentFromPdf.apply(this, arguments);
}
function _getAllContentFromPdf() {
  _getAllContentFromPdf = _asyncToGenerator(function* (pdf) {
    if (!pdf) return;
    var {
      numPages
    } = pdf.viewer.pdfDocument;
    var promise = [...Array(numPages).keys()].map(pageIndex => getContentFromPage(pdf, pageIndex + 1));
    var pageContents = yield Promise.all(promise);
    var contents = pageContents.reduce((value, currentValue) => [...value, ...currentValue.items], []);
    return contents.reduce((value, currentValue) => value + currentValue.str, '');
  });
  return _getAllContentFromPdf.apply(this, arguments);
}
function getBookTitle(_x6) {
  return _getBookTitle.apply(this, arguments);
}
function _getBookTitle() {
  _getBookTitle = _asyncToGenerator(function* (pdf) {
    var _metadata$info;
    var metadata = yield pdf.viewer.pdfDocument.getMetadata();
    var infoTitle = (metadata === null || metadata === void 0 || (_metadata$info = metadata.info) === null || _metadata$info === void 0 ? void 0 : _metadata$info.Title) || '';
    var title = infoTitle.replace('.indb', '');
    var newTitle = _String.default.replaceSpecialChars(title, ' ');
    return _String.default.capitalize(newTitle);
  });
  return _getBookTitle.apply(this, arguments);
}
var PdfReader = {
  getOutlineByPage,
  getContentFromPage,
  getAllContentFromPdf,
  getBookTitle
};
var _default = exports.default = PdfReader;