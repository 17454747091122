"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useStyles = void 0;
var _styles = require("@material-ui/core/styles");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
var useStyles = exports.useStyles = (0, _styles.makeStyles)(theme => ({
  paper: {
    display: 'flex',
    justifyContent: 'flex-end',
    maxWidth: 300,
    padding: theme.spacing(2),
    alignSelf: 'flex-end',
    margin: '0 auto 96px',
    [theme.breakpoints.up('sm')]: {
      maxWidth: 380,
      margin: '0 100px 100px auto'
    }
  },
  dialogTitle: {
    display: 'flex',
    padding: 0,
    alignItems: 'center'
  },
  dialogContent: {
    padding: 0
  },
  title: _objectSpread(_objectSpread({}, theme.typography.body1), {}, {
    color: theme.palette.common.black,
    marginLeft: theme.spacing(1),
    [theme.breakpoints.up('sm')]: _objectSpread({}, theme.typography.h6)
  }),
  body: _objectSpread(_objectSpread({}, theme.typography.body2), {}, {
    color: theme.palette.common.black,
    paddingTop: 12,
    paddingBottom: 16,
    [theme.breakpoints.up('sm')]: _objectSpread(_objectSpread({}, theme.typography.body1), {}, {
      paddingTop: 16,
      paddingBottom: 16
    })
  }),
  button: {
    fontSize: 14,
    fontWeight: 500,
    lineHeight: '24px',
    letterSpacing: '0.4px',
    width: '100%',
    margin: '0',
    textAlign: 'right',
    padding: '0',
    justifyContent: 'end'
  },
  buttonClose: {
    marginLeft: 'auto',
    padding: 0,
    minWidth: 24
  }
}));