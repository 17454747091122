/* eslint-disable */

import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: 'center',
    backgroundColor: 'rgba(36, 90, 147, 0.08)',
    borderRadius: '4px 0px 0px 4px',
    bottom: 27,
    display: 'flex',
    position: 'absolute',
    right: 0,
    width: 208,
    [theme.breakpoints.down(1024)]: {
      width: 34
    },
    [theme.breakpoints.down(644)]: {
      bottom: 31
    },
    [theme.breakpoints.down(576)]: {
      bottom: 28
    }
  },
  messageTag: {
    color: 'rgba(73, 80, 87, 1)',
    fontWeight: 400,
    letterSpacing: '0.4px',
    fontSize: 12
  },
  iconTag: {
    color: '#245A93',
    height: 20,
    margin: '6px 8px',
    width: 20,
    [theme.breakpoints.down(576)]: {
      marginRight: 8
    }
  }
}))

export default useStyles
