export const ORIGIN = {
  LAST_BOOK: 'LAST_BOOK',
  RECOMMENDED: 'RECOMMENDED'
}

export const FORMAT = {
  EPUB: 'E-PUB',
  PDF: 'PDF'
}

export const BOOK_NUMBER = {
  FEW: { value: 8, label: '8 livros por página' },
  AVERAGE: { value: 12, label: '12 livros por página' },
  MANY: { value: 24, label: '24 livros por página' },
  FULL: { value: 32, label: '32 livros por página' }
}

export const BOOK_FORMAT = {
  ALL: { value: 'Todos', label: 'Todos' },
  EPUB: { value: 'E-PUB', label: 'E-PUB' },
  PDF: { value: 'PDF', label: 'PDF' }
}

export const BOOK_SORT = {
  VIEWED: { value: 'Mais Acessados', label: 'Mais Acessados' },
  RELEASED: { value: 'Edições mais recentes', label: 'Edições mais recentes' }
}
