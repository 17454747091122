import React from 'react'
import {
  Box, Typography
} from '@material-ui/core'
import imgError from '../../assets/logo/CL_Plano-de-estudos 04 1.png'
import { useStyles } from './style'

export default function BookError() {
  const classes = useStyles()
  return (
    <>

      <Box className={classes.container}>
        <Box className={classes.content}>
          <Box>
            <Typography variant="subtitle1" className={classes.title}>Livro ausente</Typography>
            <Typography variant="h4" className={classes.body}>Infelizmente o livro que está procurando não faz parte da sua biblioteca.</Typography>
            <Typography variant="h6" className={classes.footer}>Entre em contato com sua Instituição para mais detalhes.</Typography>
          </Box>
        </Box>

        <Box className={classes.image}>
          <img className={classes.img} src={imgError} alt="imagem de erro" />
        </Box>
      </Box>

    </>
  )
}
