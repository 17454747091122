import { useHistory, useLocation } from 'react-router-dom'
import Trackings from '../services/trackings/trackings'

export const useNavigation = () => {
  const history = useHistory()
  const location = useLocation()
  const currentPath = location.pathname
  function swithPage(pagePath) {
    if (currentPath === '/periodics') Trackings.trackOpenPeriodics()
    if (currentPath === '/favorites') Trackings.favoritesTracking()
    history.push(pagePath)
  }
  return [swithPage]
}
