"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.COLOR = void 0;
var COLOR = exports.COLOR = {
  GREEN: {
    id: 'green',
    label: 'Verde',
    hex: '#9EC3B7'
  },
  YELLOW: {
    id: 'yellow',
    label: 'Amarelo',
    hex: '#F0D894'
  },
  RED: {
    id: 'red',
    label: 'Vermelho',
    hex: '#F1ACAE'
  },
  PURPLE: {
    id: 'purple',
    label: 'Roxo',
    hex: '#C0ACF1'
  }
};