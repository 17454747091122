"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _styles = require("@material-ui/core/styles");
const useStyles = (0, _styles.makeStyles)(theme => ({
  header: {
    height: 64,
    [theme.breakpoints.down('xs')]: {
      height: 56
    }
  },
  backdrop: {
    [theme.breakpoints.down('xs')]: {
      top: 56
    }
  }
}));
var _default = exports.default = useStyles;