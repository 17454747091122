"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _styles = require("@material-ui/core/styles");
var useStyles = (0, _styles.makeStyles)(() => ({
  root: {
    marginLeft: 16,
    height: 22
  },
  title: {
    position: 'absolute'
  },
  buttons: {
    margin: '16px auto auto 16px',
    height: 42,
    display: 'flex'
  },
  groupButtons: {
    position: 'relative',
    left: 72,
    bottom: 38,
    width: 'fit-content',
    height: 24
  },
  sizeText: {
    alignSelf: 'center',
    marginRight: 16
  },
  colorContainer: {
    display: 'flex',
    margin: '16px 16px 24px'
  },
  colorCards: {
    border: '1px solid'
  },
  columnContainer: {
    display: 'flex',
    margin: '16px auto 24px 16px'
  },
  spaceBetween: {
    marginRight: 8
  },
  spaceColumns: {
    marginRight: 16
  },
  mobile: {
    display: 'none'
  }
}));
var _default = exports.default = useStyles;