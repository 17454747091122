"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useWrapperStyles = exports.useMenuStyles = exports.useMenuIconButtonStyles = exports.useMediaStyles = exports.useIconButtonStyles = exports.useHoverTextStyle = exports.useFlagStyles = exports.useContentStyles = exports.useCardStyles = exports.useActionAreaStyle = void 0;
var _core = require("@material-ui/core");
const useCardStyles = exports.useCardStyles = (0, _core.makeStyles)(theme => ({
  root: {
    border: `1px solid ${theme.palette.divider}`,
    boxShadow: 'initial',
    padding: props => props.list ? theme.spacing(3) : theme.spacing(2, 2, 1.5),
    [theme.breakpoints.up('sm')]: {
      padding: props => props.list ? theme.spacing(3) : theme.spacing(3, 3, 2)
    },
    '&:hover': {
      boxShadow: theme.shadows[4]
    }
  }
}));
const useWrapperStyles = exports.useWrapperStyles = (0, _core.makeStyles)(() => ({
  root: {
    display: 'flex',
    position: 'relative'
  },
  gallery: {
    flexDirection: 'column'
  },
  list: {
    flexDirection: 'row'
  }
}));
const useMediaStyles = exports.useMediaStyles = (0, _core.makeStyles)(theme => ({
  root: {
    maxWidth: '100%',
    borderRadius: theme.shape.borderRadius,
    position: 'relative',
    '&:hover': {
      [theme.breakpoints.up('sm')]: {
        filter: `brightness(50%)`
      }
    }
  }
}));
const useContentStyles = exports.useContentStyles = (0, _core.makeStyles)(theme => ({
  root: {
    padding: props => props.list ? theme.spacing(0, 2, 0, 3) : 0,
    '&:last-child': {
      padding: props => props.list ? theme.spacing(0, 2, 0, 3) : 0
    }
  }
}));
const useActionAreaStyle = exports.useActionAreaStyle = (0, _core.makeStyles)(() => ({
  root: {
    flex: props => props.list ? '0 0 120px' : 'initial',
    position: 'relative'
  }
}));
const useHoverTextStyle = exports.useHoverTextStyle = (0, _core.makeStyles)(theme => ({
  root: {
    display: 'none',
    pointerEvents: 'none',
    textTransform: 'uppercase',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
      position: 'absolute',
      left: '50%',
      top: '50%',
      transform: `translate(-50%,-50%)`,
      color: theme.palette.common.white,
      fontSize: theme.typography.pxToRem(16),
      fontWeight: 500
    }
  }
}));
const useIconButtonStyles = exports.useIconButtonStyles = (0, _core.makeStyles)(() => ({
  root: {
    position: 'absolute',
    left: props => props.favoritePosition === 'bottomLeft' || props.favoritePosition === 'topLeft' ? 0 : 'initial',
    right: props => props.favoritePosition === 'bottomRight' || props.favoritePosition === 'topRight' ? 0 : 'initial',
    top: props => props.favoritePosition === 'topLeft' || props.favoritePosition === 'topRight' ? 0 : 'initial',
    bottom: props => props.favoritePosition === 'bottomRight' || props.favoritePosition === 'bottomLeft' ? 0 : 'initial'
  }
}));
const useMenuIconButtonStyles = exports.useMenuIconButtonStyles = (0, _core.makeStyles)(theme => ({
  root: {
    position: 'absolute',
    top: theme.spacing(-1.5),
    right: props => props.menuIconPosition === 'right' ? theme.spacing(-1.5) : 'initial',
    left: props => props.menuIconPosition === 'left' ? theme.spacing(-1.5) : 'initial',
    background: theme.palette.grey[300],
    padding: theme.spacing(0.5),
    '&:hover': {
      background: theme.palette.grey[300]
    }
  }
}));
const useMenuStyles = exports.useMenuStyles = (0, _core.makeStyles)(theme => ({
  paper: {
    maxHeight: 200,
    minWidth: 150,
    marginTop: 50,
    marginLeft: theme.spacing(3)
  }
}));
const useFlagStyles = exports.useFlagStyles = (0, _core.makeStyles)(() => ({
  root: {
    position: 'absolute',
    left: props => props.flagPosition === 'bottomLeft' || props.flagPosition === 'topLeft' ? 0 : 'initial',
    right: props => props.flagPosition === 'bottomRight' || props.flagPosition === 'topRight' ? 0 : 'initial',
    top: props => props.flagPosition === 'topLeft' || props.flagPosition === 'topRight' ? 0 : 'initial',
    bottom: props => props.flagPosition === 'bottomRight' || props.flagPosition === 'bottomLeft' ? 0 : 'initial'
  }
}));