"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _styles = require("@material-ui/core/styles");
var _default = exports.default = (0, _styles.makeStyles)(theme => ({
  wrapper: {
    maxWidth: '100%',
    height: '100%',
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    overflowY: 'hidden',
    backgroundColor: 'var(--leme-color-gray-1)',
    [theme.breakpoints.down(770)]: {
      marginLeft: 0,
      maxWidth: '100%',
      padding: '28px 38px'
    }
  }
}));