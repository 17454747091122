/* eslint-disable */
/* eslint-disable react/forbid-prop-types */
import React, { useState, useRef, useContext } from 'react'
import PropTypes from 'prop-types'
import { useHistory, Link } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'
import {
  Grid,
  Card as MuiCard,
  IconButton,
  Menu,
  MenuItem
} from '@material-ui/core'
import { Button } from '@minha-biblioteca/saraiva-ui'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import { Card } from '@minha-biblioteca/saraiva-ui'
import services from '../../../services'
import RecommendationTag from '../../../components/RecommendationTag'

import {
  toCamelCase,
  getEdition,
  getAuthors,
  bookUrl,
  getBookReaderUrl,
  saveLastScrollPosition
} from '../../../utils'
import useStyles from './styles'
import { LibraryContext } from '../../../contexts/LibraryContext'

export default function BookCollectionMode({
  book,
  favoriteClicked,
  favorites,
  hasFavorites,
  fluxo
}) {
  const classes = useStyles()
  const history = useHistory()

  const [anchorEl, setAnchorEl] = useState(null)
  const anchorElRef = useRef()
  const popoverOpened = window.Boolean(anchorEl)
  const isResponsible = useMediaQuery({ maxWidth: 768 })

  const { libraryCode } = useContext(LibraryContext)

  const handleOpenPopover = (evt) => setAnchorEl(evt.currentTarget)

  const handleClosePopover = () => setAnchorEl(null)

  const onClickFavorite = (book, event, tracking) => {
    if (!hasFavorites) return
    favoriteClicked(book, event, tracking)
  }

  const isFavorite = (uuid) => {
    if (!hasFavorites) return
    const iterableArray = favorites
    return iterableArray.find((item) => {
      return item.id === uuid
    })
  }

  const renderContent = (params = {}) => {
    return (
      <>
        <div className={classes.titleContainer}>
          <h1 className={classes.title}>{toCamelCase(book.titleCover)}</h1>
        </div>

        <div className={classes.subtitleContainer}>
          <h6 className={classes.subtitle}>{getEdition(book)}</h6>
        </div>

        <div className={classes.subtitleContainer}>
          <h6 className={classes.subtitle}>
            {getAuthors(book, { displayAll: !!params.displayAll })}
          </h6>
        </div>

        <div className={classes.subtitleContainer}>
          <span className={classes.subtitle}>{book.format}</span>
        </div>

        <div className={classes.buttons}>
          <Button
            color="primary"
            onClick={() => {
              saveLastScrollPosition()
              services.Trackings.trackOpenBook(book, {
                origin: 'Botão',
                viewMode: 'Lista',
                format: book.format
              })
              history.push(getBookReaderUrl(book))
            }}
            variant="contained"
            className={classes.primaryButton}
          >
            Ler Livro
          </Button>

          <Button
            color="primary"
            onClick={() => {
              saveLastScrollPosition()
              services.Trackings.bookDetails(book, {
                origin: 'Botão',
                viewMode: 'Lista',
                format: book.format
              })
              history.push(bookUrl(book))
            }}
            variant="outlined"
            className={classes.secondaryButton}
          >
            Detalhes
          </Button>
          {/* <RecommendationTag /> */}
        </div>
      </>
    )
  }

  const renderDesktopMode = () => {
    return (
      <div className={`${classes.content} ${classes.desktopMode}`}>
        {renderContent({ displayAll: true })}
      </div>
    )
  }

  const renderMobileMode = () => {
    return (
      <div className={classes.contentContainer}>
        <div className={classes.linkContainer}>
          <Link
            to={bookUrl(book)}
            onClick={() => {
              saveLastScrollPosition()
              services.Trackings.bookDetails(book, {
                origin: 'Capa',
                viewMode: 'Lista',
                format: book.format
              })
            }}
            className={`${classes.content} ${classes.mobileMode}`}
          >
            {renderContent()}
          </Link>
        </div>
        <div>
          <IconButton
            aria-label="settings"
            className={classes.iconButton}
            onClick={handleOpenPopover}
          >
            <MoreVertIcon className={classes.icon} />
          </IconButton>
        </div>
        {/* <RecommendationTag /> */}
      </div>
    )
  }

  const onClickEvent = (book) => {
    saveLastScrollPosition()
    history.push(getBookReaderUrl(book))
  }

  const onClickDetalhes = (book) => {
    saveLastScrollPosition()
    const favorited = isFavorite(book.uuid)
    if (favorited) {
      services.Trackings.bookDetails(book, {
        origin: 'Capa',
        viewMode: 'Dropdown Galeria',
        format: book.format
      })
    }
    history.push(bookUrl(book))
  }

  return (
    <Grid item xs={12} className={classes.root}>
      <Card
        imageHoverText={'DETALHES'}
        image={book.imageUrl}
        classNameIconButton={classes.icon}
        size="md"
        list={true}
        hasFavorite={!libraryCode && hasFavorites}
        handleFavorite={() =>
          onClickFavorite(book, 'favorite', 'favoritou_livro')
        }
        handleUnfavorite={() =>
          onClickFavorite(book, 'unfavorite', 'desfavoritou_livro')
        }
        favorite={() => isFavorite(book.uuid)}
        classNameImage={classes.img}
        onClick={() => onClickDetalhes(book)}
      >
        {renderDesktopMode()}
        {renderMobileMode()}
        <Menu
          id="long-menu"
          className={classes.popoverContainer}
          anchorEl={anchorEl}
          keepMounted
          open={popoverOpened}
          onClose={handleClosePopover}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          PaperProps={{
            style: {
              maxHeight: 200,
              width: '20ch',
              marginTop: 53
            }
          }}
        >
          <MenuItem
            className={classes.popoverLink}
            onClick={() => onClickEvent(book)}
          >
            Ler Livro
          </MenuItem>

          <MenuItem
            className={classes.popoverLink}
            onClick={() => {
              saveLastScrollPosition()
              services.Trackings.bookDetails(book, {
                origin: 'Dropdown',
                viewMode: 'Lista',
                format: book.format
              })
              history.push(bookUrl(book))
            }}
          >
            Detalhes
          </MenuItem>
        </Menu>
      </Card>
    </Grid>
  )
}

BookCollectionMode.propTypes = {
  book: PropTypes.shape({
    titleCover: PropTypes.string.isRequired,
    uuid: PropTypes.string.isRequired,
    imageUrl: PropTypes.string.isRequired,
    authors: PropTypes.arrayOf(PropTypes.string)
  }).isRequired
}
