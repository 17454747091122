"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.appTokenCase = appTokenCase;
exports.flattenArray = flattenArray;
exports.fluxoTracking = fluxoTracking;
exports.formatAuthorAbnt = exports.formatAbntText = void 0;
exports.getCfiByHref = getCfiByHref;
exports.getContentLines = void 0;
exports.getDeviceType = getDeviceType;
exports.getIntegrationType = getIntegrationType;
exports.getMobileOperatingSystem = getMobileOperatingSystem;
exports.getSessionToken = void 0;
exports.getUserIp = getUserIp;
exports.handleTextColor = exports.handleBackGroundStyle = exports.handleBackGroundSheet = void 0;
exports.parseJwt = parseJwt;
exports.searchBook = searchBook;
exports.stripHtmlTags = void 0;
Object.defineProperty(exports, "useLocalStorage", {
  enumerable: true,
  get: function get() {
    return _useLocalStorage.useLocalStorage;
  }
});
var _exceptions = require("./exceptions");
var _useLocalStorage = require("./useLocalStorage");
function asyncGeneratorStep(n, t, e, r, o, a, c) { try { var i = n[a](c), u = i.value; } catch (n) { return void e(n); } i.done ? t(u) : Promise.resolve(u).then(r, o); }
function _asyncToGenerator(n) { return function () { var t = this, e = arguments; return new Promise(function (r, o) { var a = n.apply(t, e); function _next(n) { asyncGeneratorStep(a, r, o, _next, _throw, "next", n); } function _throw(n) { asyncGeneratorStep(a, r, o, _next, _throw, "throw", n); } _next(void 0); }); }; }
var testSpecialChars = (param, list) => {
  if (param.includes(',') && list.includes(param.replace(/[.,()]/g, ''))) return true;
  if (param.includes('.') && list.includes(param.replace(/[.,()]/g, ''))) return true;
  if (param.includes('(') && list.includes(param.replace(/[.,()]/g, ''))) return true;
  if (param.includes(')') && list.includes(param.replace(/[.,()]/g, ''))) return true;
  if (param.includes(':') && list.includes(param.replace(/[.,()]/g, ''))) return true;
  return false;
};
var toCamelCase = string => {
  var words = string.split(' ');
  var lowerWords = words.map(word => word.toLowerCase());
  var formatedString = lowerWords.map(word => {
    if (_exceptions.siglas.includes(word) || testSpecialChars(word, _exceptions.siglas)) {
      return word.toUpperCase();
    }
    return _exceptions.stopwords.includes(word) ? word : word && word[0].toUpperCase() + word.substring(1);
  }).join(' ');
  return formatedString.charAt(0).toUpperCase() + formatedString.slice(1);
};
var formatAbntText = abnt => {
  var reference = abnt.match(/<strong[^>]*>([^<]+)<\/strong>/)[1];
  var referenceFormated = "<strong>".concat(toCamelCase(reference), "</strong>");
  return "".concat(abnt.replace(/<strong[^>]*>([^<]+)<\/strong>/, "<strong style=\"letter-spacing:0.5px\">".concat(referenceFormated, "</strong>")));
};
exports.formatAbntText = formatAbntText;
var formatAuthorAbnt = author => {
  var palavras = author.split(' ');
  palavras[0] = palavras[0].charAt(0).toUpperCase() + palavras[0].slice(1).toLowerCase();
  return palavras.join(' ');
};
exports.formatAuthorAbnt = formatAuthorAbnt;
function getUserIp() {
  return _getUserIp.apply(this, arguments);
}
function _getUserIp() {
  _getUserIp = _asyncToGenerator(function* () {
    return fetch('https://api.ipify.org/?format=json').then(res => res.json()).then(data => data.ip);
  });
  return _getUserIp.apply(this, arguments);
}
function getDeviceType() {
  var ua = navigator.userAgent;
  if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
    return 'tablet';
  }
  if (/Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)) {
    return 'mobile';
  }
  return 'desktop';
}
function getMobileOperatingSystem() {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return 'Windows Phone';
  }
  if (/android/i.test(userAgent)) {
    return 'Android';
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return 'iOS';
  }
  return 'unknown';
}
function parseJwt(token) {
  if (!token) {
    return {};
  }
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(atob(base64).split('').map(c => "%".concat("00".concat(c.charCodeAt(0).toString(16)).slice(-2))).join(''));
  return JSON.parse(jsonPayload);
}
var getSessionToken = () => localStorage.getItem('sessionToken');
exports.getSessionToken = getSessionToken;
function getIntegrationType() {
  var token = localStorage.getItem('sessionToken');
  if (!token) {
    return '';
  }
  var {
    user
  } = parseJwt(token);
  return user.integration_type;
}
function flattenArray(array, field) {
  var newArray = [...array];
  for (var i = 0; i < newArray.length; i += 1) {
    if (newArray[i][field].length > 0) {
      newArray = [...newArray.slice(0, i + 1), ...newArray[i][field], ...newArray.slice(i + 1, newArray.length)];
    }
  }
  return newArray;
}
var handleBackGroundStyle = style => {
  if (style === 'default') {
    return '#212529';
  }
  if (style === 'brighter') {
    return '#FFFFFF';
  }
  if (style === 'sepia') {
    return '#FBF0D9';
  }
  return '#424242';
};
exports.handleBackGroundStyle = handleBackGroundStyle;
var handleBackGroundSheet = style => {
  if (style === 'default') {
    return 'secondary';
  }
  if (style === 'brighter') {
    return 'dark';
  }
  if (style === 'sepia') {
    return 'sepia';
  }
  return 'light';
};
exports.handleBackGroundSheet = handleBackGroundSheet;
var handleTextColor = style => {
  if (style === 'default' || style === 'dark') {
    return '#FFFFFF';
  }
  return '#424242';
};
exports.handleTextColor = handleTextColor;
var getContentLines = (element, document) => {
  var height = element.scrollHeight;
  var lineHeight = parseInt(document.defaultView.getComputedStyle(element, null).lineHeight, 10);
  return height / lineHeight;
};
exports.getContentLines = getContentLines;
var stripHtmlTags = text => text.replace(/<\/?[^>]+(>|$)/g, '');
exports.stripHtmlTags = stripHtmlTags;
function getCfiByHref(_x, _x2) {
  return _getCfiByHref.apply(this, arguments);
}
function _getCfiByHref() {
  _getCfiByHref = _asyncToGenerator(function* (rendition, href) {
    var el;
    var section = href.split('#')[0];
    var subItem = href.split('#')[1];
    var item = rendition.book.spine.get(section);
    yield item.load(rendition.book.load.bind(rendition.book));
    if (subItem) {
      el = item.document.getElementById(subItem) || item.document.body;
    } else {
      el = item.document.getElementById(section) || item.document.body;
    }
    return item.cfiFromElement(el);
  });
  return _getCfiByHref.apply(this, arguments);
}
function getBookSections(rendition) {
  return rendition.book.spine.items.map(item => rendition.book.spine.get(item.href));
}
function searchSection(rendition, section, search) {
  return section.load(rendition.book.load.bind(rendition.book)).then(section.find.bind(section, search)).finally(section.unload.bind(section));
}
function searchBook(_x3, _x4) {
  return _searchBook.apply(this, arguments);
}
function _searchBook() {
  _searchBook = _asyncToGenerator(function* (rendition, search) {
    var sections = getBookSections(rendition);
    var promises = sections.map(section => searchSection(rendition, section, search));
    var results = yield Promise.all(promises);
    return results.reduce((current, value) => [...current, ...value], []);
  });
  return _searchBook.apply(this, arguments);
}
function appTokenCase(url) {
  switch (url) {
    case 'https://bds.minhabiblioteca.com.br':
    case 'https://leme.minhabiblioteca.com.br':
      return 'avjt2kFdXk69cLVSFKiZO7oX6rOBtMyn1pkxEqyVZyebboQhxDflLJOwtfQGiD1A';
    case 'https://bds.minhabiblioteca.dev':
    case 'https://leme.minhabiblioteca.dev':
      return 'fAdRowotrazothinlWlsWof3Ipi2utriSivaqlZadasW1tamUtHiwoblC5Uxunob';
    case 'http://localhost:3000':
      return 't7yd9mq0ehz3nkw9l7ztunhm97uhb8m0';
    default:
      return 'Não existe essa aplicação';
  }
}
function fluxoTracking(url) {
  switch (url) {
    case 'https://bds.minhabiblioteca.com.br':
      return '[BDS]';
    case 'https://bds.minhabiblioteca.dev':
      return '[BDS]';
    default:
      return '[Conecta Dev]';
  }
}