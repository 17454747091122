/* eslint-disable */
import httpClient from '../../gateway/http_request'

const Sessions = {
  async loginByLti(idToken) {
    const path = '/accounts/api/v1/sessions'
    const headers = httpClient.buildHeader({})

    const response = await httpClient.post(
      path,
      {
        id_token: idToken,
        integration_type: 'lti',
        external_application_token: process.env.REACT_APP_TOKEN
      },
      headers
    )

    return response
  },

  async loginByJwt(clientName, token) {
    const path = '/accounts/api/v1/sessions'
    const headers = httpClient.buildHeader({})

    const response = await httpClient.post(
      path, {
        integration_type: "jwt",
        token,
        client_name: clientName
      }, headers
    )

    return response
  },

  async loginByMarc(libraryCode, token) {
    const path = '/accounts/api/v1/sessions'
    const headers = httpClient.buildHeader({})

    const response = await httpClient.post(
      path, {
        integration_type: "marc21",
        token,
        client_name: libraryCode
      }, headers
    )

    return response
  }
}

export default Sessions
