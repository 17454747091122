import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({

  sugestionsTitle: {
    color: 'rgba(73, 80, 87, 1)',
    display: 'flex',
    alignSelf: 'flex-start',
    fontSize: 16,
    margin: '16px 16px 24px 16px'
  },
  wrap: {
    width: '100%',
    [theme.breakpoints.down(600)]: {
      position: 'relative',
      right: 10,
      width: '100%'
    }
  },
  container: {
    width: '100%'
  },
  sugestionsContent: {
    alignSelf: 'flex-start',
    display: 'flex',
    minHeight: 45,
    marginTop: 12
  },
  sugestionsImg: {
    height: 45,
    width: 32,
    borderRadius: 4
  },
  titleContainer: {
    marginLeft: 12,
    marginBottom: 22,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '69%'
  },
  bookTitle: {
    fontSize: 14,
    color: 'rgba(33, 37, 41, 1)'
  },
  subtitle: {
    fontSize: 12,
    color: 'rgba(73, 80, 87, 1)'
  },
  subtitleContainer: {
    position: 'absolute',
    left: 61,
    bottom: 10
  },
  showButton: {
    padding: 16,
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'nowrap',
    cursor: 'pointer'
  },
  divider: {
    width: '90%',
    marginBottom: 6,
    marginTop: 24,
    position: 'relative',
    left: 14,
    [theme.breakpoints.down(600)]: {
      width: '99%',
      left: 11
    }
  },
  dividerMobile: {
    display: 'none',
    [theme.breakpoints.down(600)]: {
      display: 'block',
      width: '99%',
      position: 'relative',
      left: 11,
      marginTop: 6
    }
  },
  textBody2: {
    alignSelf: 'self-end',
    fontSize: 14,
    color: 'rgba(73, 80, 87, 1)'
  },
  expandIcon: {
    color: 'rgba(73, 80, 87, 1)'
  },
  noSuggestions: {
    fontSize: 12,
    margin: '12px 0 24px 16px',
    whiteSpace: 'break-spaces',
    marginTop: 24
  },
  caption: {
    marginLeft: 17
  }
}))
