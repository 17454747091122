import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 16,
    [theme.breakpoints.down(835)]: {
      marginTop: 8
    }
  },
  wrapper: {
    display: 'flex',
    width: '100%',
    padding: 32,
    '&:last-child': {
      paddingBottom: 32
    },
    [theme.breakpoints.down(835)]: {
      '&:last-child': {
        padding: '24px 16px'
      }
    }
  },
  mainContent: {
    width: '60%',
    display: 'flex',
    flexFlow: 'column',
    [theme.breakpoints.down(835)]: {
      width: '100%'
    }
  },
  title: {
    fontSize: theme.typography.h4.fontSize,
    [theme.breakpoints.down(576)]: {
      fontSize: theme.typography.h5.fontSize,
      height: 32
    }
  },
  input: {
    marginTop: 36,
    marginBottom: 24,
    width: '100%'
  },
  alert: {
    marginBottom: 36
  },
  button: {
    width: '160px',
    marginTop: 24,
    alignSelf: 'flex-end',
    [theme.breakpoints.down(835)]: {
      width: '100%'
    }
  },
  description: {
    marginTop: 8,
    marginBottom: 29,
    [theme.breakpoints.down(576)]: {
      marginBottom: 40
    }
  },
  imageContainer: {
    width: '40%',
    display: 'flex',
    paddingLeft: 32,
    justifyContent: 'center',
    [theme.breakpoints.down(835)]: {
      display: 'none'
    }
  },
  main: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginTop: 72,
    [theme.breakpoints.down(835)]: {
      marginTop: 32
    }
  }
}))

export default useStyles
