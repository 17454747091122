"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _styles = require("@material-ui/core/styles");
const useStyles = props => (0, _styles.makeStyles)(theme => ({
  CardContext: {
    border: '1px solid rgb(235,235,235)',
    borderRadius: '8px',
    minWidth: '300px',
    '&:first-child': {
      marginTop: '40px'
    },
    ...props.className
  },
  CardMain: {
    padding: '24px 16px',
    paddingBottom: props.paddingBottom
  },
  CardDetails: {
    color: 'rgba(0,0,0,.54)',
    fontSize: '16px',
    lineHeight: '150%'
  },
  CardFooter: {
    borderTop: '1px solid rgb(235,235,235)',
    padding: '12px 16px',
    height: '48px',
    color: props.color || theme.palette.secondary.main,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: 'rgba(0, 0, 0, 0.04)'
    },
    '&:focus': {
      cursor: 'pointer',
      backgroundColor: theme.palette.background.hover
    }
  },
  CardInteractive: {
    width: '100%',
    color: props.color || theme.palette.secondary.main
  },
  CardText: {
    fontWeight: 'normal',
    fontSize: '16px'
  }
}));
var _default = exports.default = useStyles;