"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _styles = require("@material-ui/core/styles");
var useStyles = (0, _styles.makeStyles)(() => ({
  root: {
    fontSize: 16,
    color: '#616161',
    position: 'fixed',
    left: '54%',
    transform: 'translateX(-50%)',
    bottom: '1.4rem',
    zIndex: 10
  },
  container: {
    display: 'flex',
    alignItems: 'center'
  },
  tooltipIcon: {
    marginLeft: 8
  },
  tooltipIconDarkMode: {
    color: '#816AB1'
  },
  tooltipIconLightMode: {
    color: '#212529'
  },
  internalTooltipIcon: {
    marginRight: 10
  },
  tooltip: {
    display: 'flex',
    alignItems: 'center',
    minWidth: 455,
    backgroundColor: '#212529',
    color: 'white',
    fontSize: 14,
    fontWeight: 'normal',
    padding: '10px 16px'
  }
}));
var _default = exports.default = useStyles;