"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TYPE = exports.ORIGIN = void 0;
var ORIGIN = exports.ORIGIN = {
  HIGHLIGHT: 'destaque',
  READER: 'leitor'
};
var TYPE = exports.TYPE = {
  PLAIN_TEXT: 'simples',
  ABNT_TEXT: 'abnt'
};