"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _styles = require("@material-ui/core/styles");
const useStyles = (0, _styles.makeStyles)(theme => ({
  toolbarMenu: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  logo: {
    position: 'absolute',
    padding: 0,
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  avatar: {
    position: 'absolute',
    right: 16,
    padding: 0,
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  avatarMenu: {
    margin: 0,
    padding: 0
  },
  drawer: {
    width: 328,
    flexShrink: 0
  },
  drawerPaper: {
    width: 328,
    paddingBottom: 66,
    [theme.breakpoints.down(321)]: {
      width: 276
    }
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 2),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start'
  },
  drawerMenuHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 2),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end'
  },
  primaryText: {
    color: theme.palette.text.primary,
    width: 200,
    [theme.breakpoints.down(1300)]: {
      marginLeft: 16
    }
  },
  listUserStyle: {
    color: theme.palette.text.primary,
    width: 200,
    [theme.breakpoints.down(1300)]: {
      marginLeft: 16,
      marginTop: 13
    }
  },
  secondaryText: {
    color: theme.palette.text.secondary,
    fontSize: 14
  },
  menuAvatar: {
    marginRight: 16,
    marginBottom: 8
  },
  closeIcon: {
    fontSize: 24,
    marginTop: 16,
    marginBottom: 16
  },
  menuItem: {
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  drawerIcon: {
    color: theme.palette.text.secondary,
    marginRight: 30
  },
  menuItemDrawer: {
    color: theme.palette.text.secondary,
    fontSize: 16,
    height: 48,
    padding: '0 16px'
  },
  menuItemFull: {
    color: theme.palette.text.secondary,
    fontSize: 16,
    height: 'auto',
    padding: '0 16px',
    overflow: 'visible'
  },
  iconMenu: {
    cursor: 'pointer'
  }
}));
var _default = exports.default = useStyles;