/* eslint-disable */

import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  buttonRmv: {
    padding: '21px 8px 4px 0px !important',
    justifyContent: 'flex-end',
  },

  selectedSaraiva: {
    marginBottom: 24
  },
  removeFilter: {
    position: 'relative',
    left: '24%'
  }
}))

export default useStyles
