"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _styles = require("@material-ui/core/styles");
const useStyles = (0, _styles.makeStyles)(theme => ({
  tab: {
    color: theme.palette.text.secondary,
    minWidth: 133,
    width: 133
  },
  growModeTab: {
    width: 'unset !important',
    minWidth: 133,
    display: 'flex',
    alignItems: 'flex-end',
    '& .MuiTab-wrapper': {
      marginBottom: 6
    }
  },
  tabButtonMobile: {
    minWidth: 114,
    width: 114,
    fontSize: 13,
    whiteSpace: 'nowrap'
  }
}));
var _default = exports.default = useStyles;