import React from 'react'
import PropTypes from 'prop-types'
import { Divider } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import image from './image.png'

const useStyles = makeStyles((theme) => ({
  main: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 72,
    [theme.breakpoints.down(835)]: {
      marginTop: 32
    }
  },

  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },

  foundBooksText: {
    padding: 0,
    margin: 0,
    marginBottom: 7,
    fontSize: 20,
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.6,
    letterSpacing: 0.15,
    color: 'rgba(33, 37, 41, 1)',
    [theme.breakpoints.down(576)]: {
      fontSize: 16,
      fontWeight: 'normal',
      marginBottom: 11
    }
  },

  notFoundPrimaryText: {
    margin: 0,
    padding: 0,
    marginBottom: 12,
    fontSize: 24,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.33,
    letterSpacing: 'normal',
    textAlign: 'center',
    color: 'rgba(33, 37, 41, 1)',
    [theme.breakpoints.down(576)]: {
      margin: '0 0 4px',
      fontSize: 20,
      fontWeight: 500,
      lineHeight: 1.6,
      letterSpacing: 0.15
    }
  },

  notFoundSecondaryText: {
    margin: 0,
    padding: 0,
    fontSize: 16,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.5,
    letterSpacing: 0.15,
    textAlign: 'center',
    color: 'rgba(73, 80, 87, 1)',
    [theme.breakpoints.down(576)]: {
      width: 316,
      height: 64,
      margin: '4px 0 0',
      fontSize: 16
    }
  },

  image: {
    marginTop: 100,
    marginBottom: 24,
    width: 408,
    height: 250,
    [theme.breakpoints.down(576)]: {
      width: 266,
      height: 163
    }
  }
}))

function NoResults({ noGutters }) {
  const classes = useStyles()

  return (
    <div className={classes.main} style={{ marginTop: noGutters ? 0 : 72 }}>
      <h1 className={classes.foundBooksText}>Exibindo 0-0 de 0 livros</h1>

      <Divider />

      <div className={classes.container}>
        <img
          src={image}
          className={classes.image}
          alt="Imagem de livro não encontrado."
        />

        <h1 className={classes.notFoundPrimaryText}>
          Nenhum resultado foi encontrado
        </h1>

        <h2 className={classes.notFoundSecondaryText}>
          Tente buscar por outro título, autor, ISBN ou assunto.
        </h2>
      </div>
    </div>
  )
}

NoResults.propTypes = {
  noGutters: PropTypes.bool
}

NoResults.defaultProps = {
  noGutters: false
}

export default NoResults
