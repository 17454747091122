/* eslint-disable */
/* eslint-disable react/forbid-prop-types */
import React, { useContext, useEffect, useRef, useState } from 'react'
import { Menu, MenuItem, Divider, IconButton } from '@material-ui/core'
import queryString from 'query-string'
import { Button } from '@minha-biblioteca/saraiva-ui'
import GalleryIcon from '@material-ui/icons/ViewModule'
import ListIcon from '@material-ui/icons/ViewList'
import { BooksContext } from '../../../contexts/BooksContext'
import useStyles from './styles'
import services from '../../../services'
import LastBooks from './LastBooks'
import Loading from '../../../components/Loading'
import { getLastScrollPosition, setScrollPostion } from '../../../utils'
import { useMediaQuery } from 'react-responsive'
import DesktopFilters from '../../../components/DesktopFilters'
import MobileFilters from '../../../components/MobileFilters'

export default function Header({refresh}) {
  const { data, viewMode, setViewMode, lastBooks, getLastBooks, getFavoriteBooks } =
    useContext(BooksContext)
  const { page, total: totalBooks, page_count: lastPage } = data
  const [loading, setLoading] = useState(true)
  const [pageRefresh, setPageRefresh] = useState(false)
  const searchTerm = queryString.parse(window.location.search).query
  const anchorRef = useRef()
  const totalBooksOnPage = data.books.length
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState(null)
  const popoverOpened = window.Boolean(anchorEl)
  const lastScrollPosition = getLastScrollPosition()
  const isResponsible = useMediaQuery({ query: `(min-width: 600px)` })
  useEffect(async () => {
    await getLastBooks()
    if (page > 1 && !lastScrollPosition) {
      localStorage.setItem('lastBooksPosition', anchorRef.current.offsetTop - 60)
      setScrollPostion(0, localStorage.getItem('lastBooksPosition'))
    }
    localStorage.removeItem('lastBooksPosition')
    setLoading(false)
  }, [page])
  const handleOpenPopover = (evt) => setAnchorEl(evt.currentTarget)

  const handleClosePopover = () => setAnchorEl(null)   

  const renderBooksCountLabel = () => {
    if (page === lastPage) {
      return `${totalBooks - totalBooksOnPage + 1}-${totalBooks}`
    }
    return `${(page - 1) * totalBooksOnPage + 1}-${page * totalBooksOnPage}`
  }

  const getTotalBook = () => {
    return totalBooks
  }

  const renderViewModeIcon = () => {
    if (searchTerm) {
      return null
    }
    return (
      <>
        <div className={classes.viewModeContainer}>
          <Button
            aria-label="more"
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={handleOpenPopover}
            color="secondary"
            startIcon={viewMode.icon}
            className={classes.viewModeBtnDesktop}
          >
            <span className={classes.viewModeLabel}>
              {viewMode.name.toUpperCase()}
            </span>
          </Button>
          <IconButton
            aria-label="more"
            aria-controls="simple-menu"
            aria-haspopup="true"
            color="secondary"
            onClick={handleOpenPopover}
            className={classes.viewModeBtnMobile}
          >
            {viewMode.icon}
          </IconButton>
        </div>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={popoverOpened}
          onClose={handleClosePopover}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          PaperProps={{
            style: { minHeight: 88, width: 180, marginTop: 50 }
          }}
        >
          <MenuItem
            style={{
              cursor: viewMode.name === 'Galeria' ? 'not-allowed' : 'pointer',
              color:
                viewMode.name === 'Galeria' ? 'rgba(0, 0, 0, 0.38)' : 'black'
            }}
            onClick={() => {
              handleClosePopover()
              services.Trackings.changeViewMode('Galeria')
              setViewMode({ name: 'Galeria', icon: <ListIcon /> })
              refresh()
            }}
          >
            <GalleryIcon className={classes.menuItemIcon} /> Galeria
          </MenuItem>
          <MenuItem
            style={{
              cursor: viewMode.name === 'Lista' ? 'not-allowed' : 'pointer',
              color: viewMode.name === 'Lista' ? 'rgba(0, 0, 0, 0.38)' : 'black'
            }}
            onClick={() => {
              handleClosePopover()
              services.Trackings.changeViewMode('Lista')
              setViewMode({ name: 'Lista', icon: <ListIcon /> })
              refresh()
            }}
          >
            <ListIcon className={classes.menuItemIcon} /> Lista
          </MenuItem>
        </Menu>
      </>
    )
  }
  return (
    <>
      {loading && (
        <div className={classes.loadingLastBooks}>
          <Loading />
        </div>
      )}
      <div style={{ visibility: loading ? 'hidden' : 'visible' }}>
        {!searchTerm && lastBooks.length > 0 && (
          <LastBooks lastBooks={lastBooks} />
        )}
        <div className={classes.header} ref={anchorRef}>
          <h2 className={classes.searchResultText}>
            {`Exibindo ${renderBooksCountLabel()} de ${getTotalBook()} livros`}
          </h2>
          {renderViewModeIcon()}
        </div>
        <Divider />
        {/* <span>{isResponsible ? <DesktopFilters /> : <MobileFilters />}</span> */}
        <span className={classes.listByLabel}>
          Listado por: edições mais recentes
        </span>
      </div>
    </>
  )
}