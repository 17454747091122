import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { CircularProgress } from '@material-ui/core'

const useStyles = makeStyles(() => ({
  root: {
    marginTop: 50,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& > span': {
      marginTop: 10
    }
  }
}))

export default function Loading() {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <CircularProgress />
      <span>Carregando...</span>
    </div>
  )
}
