import { getSessionToken } from '../../auth'
import httpClient from '../../gateway/http_request'

const Tickets = {
  async activateTicket(payload) {
    const path = 'tickets/api/v1/activate'
    let headers = { 'session-token': getSessionToken() }

    headers = httpClient.buildHeader(headers)

    const response = await httpClient.post(path, payload, headers)
    return response
  }
}

export default Tickets
