/* eslint-disable */
/* eslint-disable react/jsx-one-expression-per-line, max-len, react/jsx-no-bind */
import React, { useState, useEffect, useContext } from 'react'
import { Breadcrumbs } from '@minha-biblioteca/saraiva-ui'
import { Divider, Typography } from '@material-ui/core'
import useMediaQuery from 'react-responsive'
import * as _ from 'lodash'
import Loading from '../../../components/Loading'
import Pagination from '../../../components/Pagination'
import useStyles from './styles'
import Layout from '../../../layout'
import favoriteNotFoundImage from './homem-favorito-indisponivel.png'
import { setScrollPostion, getLastScrollPosition } from '../../../utils'
import { BooksContext } from '../../../contexts/BooksContext'
import BookGalleryMode from '../BookGalleryMode'
import services from '../../../services'
import SnackBar from '../../../components/SnackBar'

let books = []
function Favorites() {
  const [page, setPage] = useState(1)
  const [search, setSearch] = useState('')
  const [books, setBooks] = useState()
  const [pages, setPages] = useState(0)
  const [from, setFrom] = useState(0)
  const [to, setTo] = useState(0)
  const [total, setTotal] =useState(0)
  const [showAlert, setShowAlert] = useState(false)
  const classes = useStyles()
  const isResponsible = useMediaQuery({ query: '(max-width: 769px)' })
  const [loading, setLoading] = useState(false)
  const [favoritesData, setFavoritesData] = useState([])
  const {
    favorites,
    parseFavorites,
    getFavoriteBooks,
    unfavoriteBook
  } = useContext(BooksContext)

  function parseTo (total, elements, realPage) {
    let value = 0;
    if (total === realPage) {
      value = elements
    }
    else {
      value = 12 * realPage
    }

    return value
  }

  function parseFrom (realPage) {
      let value = 1
      if (realPage !== 1) {
        value = (realPage - 1 )* 12
      }
      return value
  }

  const renderPagination = async () => {
    setLoading(true)
      const data = await parseFavorites(page)
      const _favorites = await getFavoriteBooks(1)
      setFavoritesData(_favorites)
      setBooks(data?.realItems)
      setTotal(data?.totalElements)
      setPages(data?.totalPages)
      setTo(parseTo(data?.totalPages, data?.totalElements, data?.realPage))
      setFrom(parseFrom(data?.realPage))
    setLoading(false)
  }
  useEffect(() => {
    renderPagination()
  },[page])

  function onPaging(newPage) {
    setPage(newPage)
    setScrollPostion(0, 0)
  }
  const onFavoriteClick = async (book, event, tracking) => {
    const data = await getFavoriteBooks(2)
    const id = data.find((item) => {
      return item.favoriteId === book.uuid
    })
    const unfavorite = await unfavoriteBook(id.id)
    if (unfavorite) {
        setShowAlert(true)
        setTimeout(async() => {
        setShowAlert(false)
    }, 800)
    await services.Trackings.favoritesTracking(book, {event: tracking})
    }
    await renderPagination()
  }

  const searching = (e) => {
    const term = e.trim()
    if (term !== "") window.location.assign(`/books?query=${term}`)
  }

  const renderRange = () => {
    if(_.isEmpty(books)) return ""
    return `Exibindo ${from} - ${to} de ${total} `
  }

  return (
    <Layout onSearch={(e) => searching(e)}>
      {showAlert && <SnackBar show={false} severity="success" variant="standart" noClose>Livro removido dos favoritos</SnackBar>}
      {!loading && (
        <>
          <Breadcrumbs color="primary" className={classes.breadcrumb}>
            {[
              { label: 'Catálogo', href: '/' },
              { label: 'Favoritos', href: '/' }
            ]}
          </Breadcrumbs>
          <h2 className={classes.searchResultText}>
            {renderRange()}
          </h2>
          <Divider />
          {!_.isEmpty(books) &&
            (
              <div>
                <div
                  className={`${classes.books} ${classes.galleryBooks}`}
                >
                  {books.map((book, index) => (
                    <BookGalleryMode book={book} key={book.uuid || index} hasFavorites={true} fluxo="Favoritos" favorites={favoritesData} favoriteClicked={(book, event, tracking) => onFavoriteClick(book, event, tracking)}/>
                  ))}
                </div>
                <div className={classes.pagination}>
                <Pagination
                  pageCount={pages}
                  page={page}
                  fetchContents={onPaging}
                  redirect={false}
                />
              </div>
          </div>)}
          {_.isEmpty(books) && (
             <div className={classes.container}>
             <img
               src={favoriteNotFoundImage}
               alt="ilustação de homem andando para a esquerda, olhando para o celular"
               className={classes.image}
             />
             <div className={classes.messageContainer}>
               <Typography
                 variant={isResponsible ? 'h6' : 'body1'}
                 className={classes.message}
               >
                 Ops! Você ainda não possui nenhum livro favorito.
               </Typography>
               <img
                 src={favoriteNotFoundImage}
                 alt="ilustação de homem andando para a esquerda, olhando para o celular"
                 className={classes.imageMobile}
               />
               <Typography variant="body2" className={classes.infoMessage}>
                 Vá até nosso catálogo, favorite as obras que desejar e crie sua
                 estante personalizada!
               </Typography>
             </div>
           </div>
          )
         }
        </>
      )}
      {loading && (  <Loading /> )}
    </Layout>
  )
}
export default Favorites