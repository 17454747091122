"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useStyles = void 0;
var _styles = require("@material-ui/core/styles");
const useStyles = exports.useStyles = (0, _styles.makeStyles)(theme => ({
  cardActions: {
    padding: 0,
    display: "flex",
    justifyContent: "center",
    flexShrink: 0
  },
  cardActionsButton: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
    borderBottom: "none"
  },
  cardContent: {
    display: "flex",
    alignItems: "center",
    flex: "1 1 0",
    minWidth: 0
  },
  card: {
    boxShadow: "0 0 0 1px rgba(0, 0, 0, 0.1)",
    "&:hover": {
      boxShadow: `0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 1px 10px 0 ${theme.palette.action.disabledBackground}, 0 4px 5px 0 rgba(0, 0, 0, 0.14)`
    },
    [theme.breakpoints.down("xs")]: {
      "&:hover": {
        boxShadow: "0 0 0 1px rgba(0, 0, 0, 0.1)"
      }
    }
  },
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative"
  },
  iconButtonMedium: {
    width: 36,
    height: 36,
    backgroundColor: theme.palette.background.default,
    position: "absolute",
    right: 12,
    top: 12,
    "&:hover": {
      backgroundColor: theme.palette.background.hover
    }
  },
  iconButtonSmall: {
    width: 36,
    height: 36,
    backgroundColor: theme.palette.background.default,
    position: "absolute",
    right: -4,
    top: 16,
    "&:hover": {
      backgroundColor: theme.palette.background.hover
    }
  },
  iconList: {
    width: 36,
    height: 36,
    backgroundColor: theme.palette.background.default,
    position: "absolute",
    right: -4,
    top: 16
  },
  title: {
    pointerEvents: "none",
    color: theme.palette.background.paper,
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: `translate(-50%,-50%)`,
    fontSize: 16,
    [theme.breakpoints.down("xs")]: {
      color: "transparent"
    }
  },
  titleList: {
    pointerEvents: "none",
    overflow: "hidden",
    color: theme.palette.background.paper,
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: `translate(-50%,-50%)`,
    fontSize: 16,
    [theme.breakpoints.down("xs")]: {
      color: "transparent"
    }
  },
  media: {
    overflow: "hidden",
    display: "flex",
    borderRadius: 8,
    width: "85%",
    maxHeight: 250,
    height: "auto",
    margin: "24px",
    "&:hover": {
      borderRadius: 8,
      filter: `brightness(50%)`
    },
    [theme.breakpoints.down("xs")]: {
      "&:hover": {
        filter: "brightness(100%)"
      }
    }
  },
  mediaList: {
    overflow: "hidden",
    display: "flex",
    borderRadius: 8,
    width: 120,
    maxHeight: 160,
    margin: "16px",
    "&:hover": {
      borderRadius: 8,
      filter: `brightness(50%)`
    },
    [theme.breakpoints.down("xs")]: {
      "&:hover": {
        filter: "brightness(100%)"
      }
    }
  },
  imageDefault: {
    height: 250,
    width: 164,
    [theme.breakpoints.down("xs")]: {
      height: 170,
      width: 120
    }
  },
  mainContentStories: {
    height: "100%",
    textDecoration: "none",
    paddingRight: 8,
    paddingLeft: 5,
    "& .MuiCardContent-root": {
      paddingTop: "0px !important",
      paddingRight: "24px !important"
    }
  },
  titleContainerStories: {
    overflow: "hidden",
    marginBottom: 8,
    display: "-webkit-box",
    "-webkit-line-clamp": 3,
    "-webkit-box-orient": "vertical"
  },
  titleContentStories: {
    display: "block",
    margin: 0,
    fontWeight: 400,
    lineHeight: 1.5,
    letterSpacing: "0.15px",
    textAlign: "left",
    color: "rgba(33, 37, 41, 1)",
    fontSize: "16px !important",
    [theme.breakpoints.down(1200)]: {
      fontSize: "16px !important"
    },
    [theme.breakpoints.down(348)]: {
      fontSize: "16px !important"
    }
  },
  subtitleContainerStories: {
    overflow: "hidden",
    marginBottom: 4,
    display: "-webkit-box",
    "-webkit-line-clamp": 1,
    "-webkit-box-orient": "vertical"
  },
  subtitleStories: {
    display: "block",
    textAlign: "left",
    color: "rgba(73, 80, 87, 1)",
    fontSize: "14px !important",
    [theme.breakpoints.down(1200)]: {
      fontSize: "14px !important"
    },
    [theme.breakpoints.down(348)]: {
      fontSize: "14px important"
    }
  },
  mediaChildren: {
    position: "absolute",
    width: 36,
    height: 36,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#ffffff",
    borderRadius: "0 8px 0 6px",
    zIndex: 1000,
    border: "1px solid #e1e1e1",
    cursor: "pointer"
  }
}));