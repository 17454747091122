"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _Fusion = _interopRequireDefault(require("../request/Fusion"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function create(applicationToken, sessionToken, payload) {
  return _Fusion.default.post("/booknotes/api/v1/highlights", _objectSpread(_objectSpread({}, payload), {}, {
    text_reference: JSON.stringify(payload.text_reference)
  }), {
    headers: {
      'application-token': applicationToken,
      'session-token': sessionToken
    }
  }).then(response => _objectSpread(_objectSpread({}, response.data.data), {}, {
    text_reference: JSON.parse(response.data.data.text_reference)
  }));
}
function update(applicationToken, sessionToken, id, payload) {
  return _Fusion.default.patch("/booknotes/api/v1/highlights/".concat(id), payload, {
    headers: {
      'application-token': applicationToken,
      'session-token': sessionToken
    }
  }).then(response => _objectSpread(_objectSpread({}, response.data.data), {}, {
    text_reference: JSON.parse(response.data.data.text_reference)
  }));
}
function remove(applicationToken, sessionToken, highlightId) {
  return _Fusion.default.delete("/booknotes/api/v1/highlights/".concat(highlightId), {
    headers: {
      'application-token': applicationToken,
      'session-token': sessionToken
    }
  });
}
function getAll(applicationToken, sessionToken, uuid, userId) {
  return _Fusion.default.get("/booknotes/api/v1/highlights?book_id=".concat(uuid, "&user_id=").concat(userId), {
    headers: {
      'application-token': applicationToken,
      'session-token': sessionToken
    }
  }).then(response => response.data.data.map(item => _objectSpread(_objectSpread({}, item), {}, {
    text_reference: JSON.parse(item.text_reference)
  })));
}
var Highlight = {
  create,
  update,
  remove,
  getAll
};
var _default = exports.default = Highlight;