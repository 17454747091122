import React from 'react'
import PropTypes from 'prop-types'
import { CustomSnackBar, Text, CustomAlert } from './styles'

export const SnackBar = ({
  children, severity, show, handleClose, autoHideDuration = 2000, noClose
}) => (
  <div style={{
    right: 25, position: 'absolute', marginTop: 25, zIndex: '999999'
  }}
  >
    {noClose ? (
      <CustomAlert severity={severity}>{children}</CustomAlert>
    )
      : (
        <CustomSnackBar
          open={show}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          autoHideDuration={autoHideDuration}
          onClose={() => handleClose && handleClose()}
        >
          <CustomAlert
            onClose={() => handleClose && handleClose()}
            severity={severity}
          >
            <Text>
              {children}
            </Text>
          </CustomAlert>
        </CustomSnackBar>
      )}

  </div>
)

SnackBar.propTypes = {
  children: PropTypes.string.isRequired,
  severity: PropTypes.oneOf(['error', 'warning', 'info', 'success']).isRequired,
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func,
  autoHideDuration: PropTypes.number,
  noClose: PropTypes.bool
}
export default SnackBar

SnackBar.defaultProps = {
  handleClose: null,
  autoHideDuration: null,
  noClose: false
}
