"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ACTION = void 0;
exports.default = Highlight;
var ACTION = exports.ACTION = {
  BATCH_CLEAR_INSERT: 'BATCH_CLEAR_INSERT',
  INSERT: 'INSERT',
  UPDATE: 'UPDATE',
  DELETE: 'DELETE'
};
function Highlight(state, action) {
  switch (action.type) {
    case ACTION.BATCH_CLEAR_INSERT:
      {
        return action.payload;
      }
    case ACTION.INSERT:
      {
        return [...state, action.payload];
      }
    case ACTION.UPDATE:
      {
        return state.map(item => item.id === action.payload.id ? action.payload : item);
      }
    case ACTION.DELETE:
      {
        return state.filter(item => item.id !== action.payload.id);
      }
    default:
      {
        return state;
      }
  }
}