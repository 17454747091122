"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useStyles = void 0;
var _core = require("@material-ui/core");
const useStyles = exports.useStyles = (0, _core.makeStyles)(theme => ({
  root: {
    alignItems: 'center',
    '& .MuiAlert-message': {
      color: theme.palette.text.primary
    }
  },
  standardSuccess: {
    '& .MuiAlert-icon': {
      color: theme.palette.success.dark
    }
  },
  standardInfo: {
    '& .MuiAlert-icon': {
      color: theme.palette.info.dark
    }
  },
  standardWarning: {
    '& .MuiAlert-icon': {
      color: theme.palette.warning.dark
    }
  },
  standardError: {
    '& .MuiAlert-icon': {
      color: theme.palette.error.dark
    }
  },
  outlinedSuccess: {
    border: `1px solid ${theme.palette.success.dark}`,
    color: theme.palette.success.dark,
    '& .MuiAlert-icon': {
      color: theme.palette.success.dark
    }
  },
  outlinedInfo: {
    border: `1px solid ${theme.palette.info.dark}`,
    color: theme.palette.info.dark,
    '& .MuiAlert-icon': {
      color: theme.palette.info.dark
    }
  },
  outlinedWarning: {
    border: `1px solid ${theme.palette.warning.dark}`,
    color: theme.palette.warning.dark,
    '& .MuiAlert-icon': {
      color: theme.palette.warning.dark
    }
  },
  outlinedError: {
    border: `1px solid ${theme.palette.error.dark}`,
    color: theme.palette.error.dark,
    '& .MuiAlert-icon': {
      color: theme.palette.error.dark
    }
  }
}));