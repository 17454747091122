"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _styles = require("@material-ui/core/styles");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
var drawerWidth = 360;
var useStyles = (0, _styles.makeStyles)(theme => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    overflowX: 'hidden',
    [theme.breakpoints.down(835)]: {
      width: '100%'
    },
    '& .MuiBackdrop-root': {
      backgroundColor: 'transparent'
    }
  },
  drawerPaper: {
    width: drawerWidth,
    overflow: 'hidden',
    [theme.breakpoints.down(835)]: {
      width: '100%'
    }
  },
  title: {
    whiteSpace: 'nowrap',
    width: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  drawerHeader: _objectSpread(_objectSpread({
    display: 'flex',
    alignItems: 'center',
    height: 64,
    padding: theme.spacing(2, 2)
  }, theme.mixins.toolbar), {}, {
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    }
  }),
  drawerClose: {
    color: 'rgba(33, 37, 41, 1)'
  },
  container: {
    margin: 16,
    [theme.breakpoints.down('xs')]: {
      margin: 0
    }
  },
  clearSearch: {
    padding: 0
  },
  clearSearchIcon: {
    fontSize: 15
  },
  resultsDetail: {
    fontSize: 12,
    marginTop: 12,
    marginBottom: 12
  },
  resultContainer: {
    cursor: 'pointer'
  },
  resultItem: {
    marginBottom: 24,
    '&:hover': {
      backgroundColor: theme.palette.action.hover
    }
  },
  resultTitle: {
    marginTop: 16,
    marginBottom: 16,
    display: 'flex',
    alignItems: 'center',
    '& span': {
      fontSize: 14,
      flex: 1,
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      marginRight: 42
    },
    '& p': {
      fontSize: 12,
      textAlign: 'end'
    }
  },
  excerpt: {
    fontSize: 16
  },
  mark: {
    backgroundColor: '#FFECB3'
  },
  navigation: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 3,
    [theme.breakpoints.down('xs')]: {
      marginLeft: 16,
      marginRight: 16
    }
  },
  buttonLink: {
    background: 'none !important',
    border: 'none',
    padding: '0 !important',
    fontFamily: 'arial, sans-serif',
    color: '#069',
    cursor: 'pointer',
    fontSize: 11,
    '&:disabled': {
      color: 'gray',
      opacity: 0.5,
      cursor: 'not-allowed'
    }
  },
  noResults: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: 143,
    [theme.breakpoints.down('xs')]: {
      paddingTop: 131
    },
    '& img': {
      margin: 0,
      width: 174,
      height: 151,
      [theme.breakpoints.down('xs')]: {
        width: 126,
        height: 124
      }
    },
    '& h6': {
      margin: 0,
      fontSize: 20,
      fontWeight: 500,
      [theme.breakpoints.down('xs')]: {
        fontSize: 16
      }
    },
    '& p': {
      fontSize: 14,
      margin: '16px 0',
      color: theme.palette.action.active,
      [theme.breakpoints.down('xs')]: {
        margin: '8px 0'
      }
    }
  },
  searchDiv: {
    backgroundColor: 'transparent',
    padding: '16px 16px 0 16px',
    [theme.breakpoints.down('xs')]: {
      backgroundColor: theme.palette.primary.main,
      width: '100%',
      padding: '10px 16px',
      '& form': {
        backgroundColor: '#ffffff'
      }
    }
  },
  hideOnMobile: {
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    }
  },
  warning: {
    fontSize: 12,
    margin: 0,
    color: theme.palette.error.main,
    marginTop: 8
  },
  pagination: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 24,
    marginTop: 48,
    [theme.breakpoints.down('xs')]: {
      marginLeft: 16,
      marginRight: 16
    }
  },
  scroll: {
    overflowY: 'auto'
  },
  padded: {
    padding: '0 16px'
  }
}));
var _default = exports.default = useStyles;