/* eslint-disable */
import React, { useContext } from 'react'
import queryString from 'query-string'
import PropTypes from 'prop-types'
import { Pagination as MuiPagination, PaginationItem } from '@material-ui/lab/'
import { makeStyles } from '@material-ui/core/styles'
import { Link } from 'react-router-dom'
import { Route } from 'react-router'
import { BooksContext } from '../../contexts/BooksContext'
import services from '../../services'

const useStyles = makeStyles((theme) => ({
  ul: {
    '& a, button': {
      minWidth: '40px !important',
      height: '40px !important',
      borderRadius: 20,
      [theme.breakpoints.down(576)]: {
        minWidth: '32px !important',
        height: '32px !important',
        borderRadius: 16
      }
    },
    '& li': {
      marginBottom: 14
    },
    '& li .Mui-selected': {
      color: 'white',
      backgroundColor: theme.palette.secondary.main
    }
  }
}))

export default function Pagination({ pageCount, page, fetchContents, redirect = true }) {
  const classes = useStyles()
  const queryStrings = queryString.parse(window.location.search)
  const searchTerm = queryStrings.query || ''
  const { viewMode } = useContext(BooksContext)

  return (
    <Route>
      <MuiPagination
        page={Number(page)}
        count={pageCount}
        variant="outlined"
        classes={{ root: classes.root, ul: classes.ul }}
        siblingCount={3}
        renderItem={(item) => (
          <PaginationItem
            component={redirect ? Link : undefined}
            to={redirect && `/books?query=${searchTerm}&page=${item.page}`}
            {...item}
            onClick={() => {
              services.Trackings.changePage({
                viewMode: viewMode.name,
                page: item.page,
                searchTerm: searchTerm
              })
              fetchContents(item.page)
            }}
          />
        )}
      />
    </Route>
  )
}

Pagination.propTypes = {
  pageCount: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  fetchContents: PropTypes.func.isRequired
}
