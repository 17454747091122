"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _Copy = _interopRequireDefault(require("../api/Copy"));
var _Book = require("../constants/Book");
var _PdfReader = _interopRequireDefault(require("./PdfReader"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
function asyncGeneratorStep(n, t, e, r, o, a, c) { try { var i = n[a](c), u = i.value; } catch (n) { return void e(n); } i.done ? t(u) : Promise.resolve(u).then(r, o); }
function _asyncToGenerator(n) { return function () { var t = this, e = arguments; return new Promise(function (r, o) { var a = n.apply(t, e); function _next(n) { asyncGeneratorStep(a, r, o, _next, _throw, "next", n); } function _throw(n) { asyncGeneratorStep(a, r, o, _next, _throw, "throw", n); } _next(void 0); }); }; }
function isCopyrightWarnDisable(_x, _x2, _x3, _x4) {
  return _isCopyrightWarnDisable.apply(this, arguments);
}
function _isCopyrightWarnDisable() {
  _isCopyrightWarnDisable = _asyncToGenerator(function* (applicationToken, sessionToken, userId, uuid) {
    var response = yield _Copy.default.getCopyright(applicationToken, sessionToken, userId, uuid);
    return response.ok && response.status === 200 && (yield response.json()).data.warn_copyright;
  });
  return _isCopyrightWarnDisable.apply(this, arguments);
}
function getMaxCharsAllowed(num) {
  var totalBookChars = num;
  var percentage = _Book.MAX_COPY_PERCENTAGE / 100;
  return parseInt(percentage * totalBookChars, 10);
}
function getTotalCharsCopied(_x5, _x6, _x7, _x8) {
  return _getTotalCharsCopied.apply(this, arguments);
}
function _getTotalCharsCopied() {
  _getTotalCharsCopied = _asyncToGenerator(function* (applicationToken, sessionToken, userId, uuid) {
    var response = yield _Copy.default.getCharactersCopied(applicationToken, sessionToken, userId, uuid);
    if (response.status !== 200) {
      return 0;
    } else {
      return response.data.data.amount_characters;
    }
  });
  return _getTotalCharsCopied.apply(this, arguments);
}
function getRemainingChars(_x9, _x10, _x11, _x12, _x13) {
  return _getRemainingChars.apply(this, arguments);
}
function _getRemainingChars() {
  _getRemainingChars = _asyncToGenerator(function* (applicationToken, sessionToken, userId, uuid, num) {
    var maxCharsAllowed = getMaxCharsAllowed(num);
    var totalCharsCopied = yield getTotalCharsCopied(applicationToken, sessionToken, userId, uuid);
    return maxCharsAllowed - totalCharsCopied;
  });
  return _getRemainingChars.apply(this, arguments);
}
function getPercentageCharsCopied(_x14, _x15, _x16, _x17, _x18) {
  return _getPercentageCharsCopied.apply(this, arguments);
}
function _getPercentageCharsCopied() {
  _getPercentageCharsCopied = _asyncToGenerator(function* (applicationToken, sessionToken, userId, uuid, num) {
    var totalCharsCopied = yield getTotalCharsCopied(applicationToken, sessionToken, userId, uuid);
    var maxCharsAllowed = getMaxCharsAllowed(num);
    return totalCharsCopied / maxCharsAllowed * 100;
  });
  return _getPercentageCharsCopied.apply(this, arguments);
}
function getCopyValue(_x19, _x20, _x21, _x22, _x23) {
  return _getCopyValue.apply(this, arguments);
}
function _getCopyValue() {
  _getCopyValue = _asyncToGenerator(function* (applicationToken, sessionToken, userId, uuid, pdf) {
    var totalLength = yield _PdfReader.default.getAllContentFromPdf(pdf);
    var copyValue = yield getPercentageCharsCopied(applicationToken, sessionToken, userId, uuid, totalLength.length);
    return copyValue;
  });
  return _getCopyValue.apply(this, arguments);
}
function getCopyrightStatus(_x24, _x25, _x26, _x27) {
  return _getCopyrightStatus.apply(this, arguments);
}
function _getCopyrightStatus() {
  _getCopyrightStatus = _asyncToGenerator(function* (applicationToken, sessionToken, userId, uuid) {
    var response = yield _Copy.default.getCopyright(applicationToken, sessionToken, userId, uuid);
    if (response.status === 204 || response.data.data.warn_copyright === false) {
      return false;
    } else {
      return true;
    }
  });
  return _getCopyrightStatus.apply(this, arguments);
}
var CopyService = {
  isCopyrightWarnDisable,
  getMaxCharsAllowed,
  getTotalCharsCopied,
  getRemainingChars,
  getPercentageCharsCopied,
  getCopyValue,
  getCopyrightStatus
};
var _default = exports.default = CopyService;