/* eslint-disable */

import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column'
  },
  paper: {
    overflowY: 'auto'
  },
  item: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  backContainer: {
    backgroundColor: 'var(--leme-color-gray-1)'
  },
  back: {
    margin: '16px 20px'
  },
  backIcon: {
    '&:first-child': {
      fontSize: 14,
      marginBottom: 3
    }
  }
}))

export default useStyles
