"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _styles = require("@material-ui/core/styles");
var useStyles = (0, _styles.makeStyles)(() => ({
  title: {
    margin: '16px 24px',
    height: 32,
    display: 'flex',
    justifyContent: 'space-between'
  },
  container: {
    margin: '24px 16px'
  },
  scaleContainer: {
    display: 'flex',
    marginTop: 16,
    marginBottom: 24
  },
  scaleText: {
    marginRight: 20,
    alignSelf: 'center'
  },
  themeContainer: {
    marginTop: 16,
    display: 'flex'
  },
  closePopover: {
    padding: 0
  }
}));
var _default = exports.default = useStyles;