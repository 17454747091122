/* eslint-disable */
/* eslint-disable react/jsx-one-expression-per-line, max-len, react/jsx-no-bind */
import React, { useState, useEffect, useContext } from 'react'
import { Breadcrumbs } from '@minha-biblioteca/saraiva-ui'
import { Divider } from '@material-ui/core'
import { useParams, useHistory, useLocation } from 'react-router'
import Loading from '../../../components/Loading'
import Pagination from '../../../components/Pagination'
import useStyles from './styles'
import services from '../../../services'
import BookGalleryMode from '../BookGalleryMode'
import Layout from '../../../layout'
import { setScrollPostion, getLastScrollPosition } from '../../../utils'
import NoResults from '../NoResults'
import { BooksContext } from '../../../contexts/BooksContext'
import { SnackBar as SnackBarSaraiva } from '../../../components/SnackBar'

function Categories() {
  const { category, subcategory } = useParams()
  const history = useHistory()
  const location = useLocation()
  const search = new URLSearchParams(location.search)
  const query = search.get('query') || ''
  const page = parseInt(search.get('page'), 10) || 1
  const [isLoading, setIsLoading] = useState(true)
  const [books, setBooks] = useState()
  const classes = useStyles()
  const [favoritesData, setFavoritesData] = useState([])
  const [showAlert, setShowAlert] = useState(false)
  const [alertMessage, setAlertMessage] = useState(
    'Livro adicionado aos favoritos'
  )
  const { favoriteBook, getFavoriteBooks, unfavoriteBook } = useContext(BooksContext)

  useEffect(() => {
    setIsLoading(true)
    setScrollPostion(0, 0)
    services.Books.listBooksAdvanced(
      page,
      [
        ['category', category],
        ['subcategory', subcategory]
      ],
      query,
      32
    )
      .then((response) => response.json())
      .then(setBooks)
      .then(() => setIsLoading(false))
      .then(() => setScrollPostion(0, getLastScrollPosition() || 0))
      .then(() => localStorage.removeItem('lastScrollPosition'))
      getFavoriteBooks(1).then(setFavoritesData)
  }, [page, query, category, subcategory])

  useEffect(() => {
    if (subcategory) {
      services.Trackings.trackCategory({
        category: `${category}/${subcategory}`
      })
    } else {
      services.Trackings.trackCategory({
        category
      })
    }
  }, [category, subcategory])


  function onPaging(newPage) {
    search.set('page', newPage)
    history.push({ pathname: location.pathname, search: search.toString() })
  }

  function onSearch(newSearch) {
    search.set('query', newSearch)
    search.set('page', 1)
    history.push({ pathname: location.pathname, search: search.toString() })
  }

  function onClickCategory() {
    search.set('page', 1)
    history.push({
      pathname: `/categories/${category}`,
      search: search.toString()
    })
  }

  const onFavoriteClick = async (book, event, tracking) => {
    switch (event) {
      case "favorite":
          const favorite = await favoriteBook(book.uuid)
          if (favorite) {
            setAlertMessage('Livro adicionado aos favoritos')
            await services.Trackings.favoritesTracking(book, {event: tracking})
          }
      break;
      case "unfavorite":
        const data = await getFavoriteBooks(2)
        const id = data.find((item) => item.favoriteId === book.uuid)
        const unfavorite = await unfavoriteBook(id.id)
        if (unfavorite) {
          setAlertMessage('Livro removido dos favoritos')
        }
      break
      default:
    }
    setShowAlert(true)
    setTimeout(async () => {
      setShowAlert(false)
    }, 800)
  }

  function renderBooksCountLabel() {
    const {
      total,
      books: { length }
    } = books
    if (page === books.page_count) {
      return `${total - length + 1}-${total}`
    }
    return `${(page - 1) * length + 1}-${page * length}`
  }

  return (
    <Layout
      onSearch={onSearch}
      searchPlaceHolder="Buscar na categoria ou subcategoria"
    >
      {isLoading ? (
        <Loading />
      ) : (
        <>
          {showAlert && (
            <SnackBarSaraiva
              show={false}
              severity="success"
              variant="standart"
              noClose
            >
              {alertMessage}
            </SnackBarSaraiva>
          )}
          <Breadcrumbs color="primary" className={classes.breadcrumb}>
            {[
              { label: 'Catálogo', onClick: () => history.push('/') },
              { label: category, onClick: onClickCategory },
              { label: subcategory }
            ].filter((item) => item.label)}
          </Breadcrumbs>
          {books.books.length > 0 ? (
            <>
              <h2 className={classes.searchResultText}>
                Exibindo {renderBooksCountLabel()} de {books.total}
              </h2>
              <Divider />
              <span className={classes.listByLabel}>
                Listado por: edições mais recentes
              </span>
              <div className={`${classes.galleryBooks}`}>
                {books.books.map((book, index) => (
                  <BookGalleryMode
                    book={book}
                    key={book.uuid || index}
                    hasFavorites={true}
                    fluxo="Favoritos"
                    favorites={favoritesData}
                    favoriteClicked={(book, event, tracking) =>
                      onFavoriteClick(book, event, tracking)
                    }
                  />
                ))}
              </div>
              <div className={classes.pagination}>
                <Pagination
                  pageCount={books.page_count}
                  page={books.page}
                  fetchContents={onPaging}
                  redirect={false}
                />
              </div>
            </>
          ) : (
            <NoResults noGutters />
          )}
        </>
      )}
    </Layout>
  )
}

export default Categories
