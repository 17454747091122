/* eslint-disable react/jsx-no-bind, implicit-arrow-linebreak */
/* eslint-disable no-use-before-define, react/no-array-index-key */
import {
  List,
  MenuItem,
  Paper
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { ArrowBackIos } from '@material-ui/icons'
import PropTypes from 'prop-types'
import { Button } from '@minha-biblioteca/saraiva-ui'
import useStyles from './styles'

const MenuListCascade = (props) => {
  const {
    items,
    onLabel,
    onKey,
    onChildren,
    onClick,
    rootProps,
    backProps,
    listProps
  } = props
  const [list, setList] = useState([items])
  const classes = useStyles()

  useEffect(() => {
    setList([items])
  }, [items])

  function isParent(xIndex, yIndex) {
    return xIndex > 0 && yIndex === 0
  }

  function onClickItem(item, index) {
    const isItemParent = isParent(list.length - 1, index)
    const children = onChildren(item)
    if (!isItemParent && children) {
      setList([...list, [item, ...children]])
    } else {
      onClick([
        ...list.slice(1, isItemParent ? list.length - 1 : list.length).map((value) => value[0]),
        item
      ])
    }
  }

  function onClickBack() {
    setList(list.slice(0, list.length - 1))
  }

  return (
    <>
      <div
        {...rootProps}
        className={`${classes.root} ${rootProps.className}`}
      >
        {
          list.length > 1 && (
            <div
              {...backProps}
              className={`${classes.backContainer} ${backProps.className}`}
            >
              <Button
                color="primary"
                onClick={onClickBack}
                startIcon={<ArrowBackIos className={classes.backIcon} />}
                className={classes.back}
              >
                Voltar
              </Button>
            </div>
          )
        }
        <Paper
          elevation={12}
          square
          {...listProps}
          className={`${classes.paper} ${listProps.className}`}
        >
          <List>
            {
              list[list.length - 1].map((item, index) => (
                <MenuItem
                  key={`${onKey(item, index)}-${index}-${list.length}`}
                  className={classes.item}
                  onClick={() => onClickItem(item, index)}
                >
                  {onLabel(item, index)}
                  {!isParent(list.length - 1, index) && onChildren(item)}
                </MenuItem>
              ))
            }
          </List>
        </Paper>
      </div>
    </>
  )
}

export default MenuListCascade

MenuListCascade.propTypes = {
  items: PropTypes.instanceOf(Array).isRequired,
  onLabel: PropTypes.func.isRequired,
  onKey: PropTypes.func.isRequired,
  onChildren: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  rootProps: PropTypes.instanceOf(Object),
  backProps: PropTypes.instanceOf(Object),
  listProps: PropTypes.instanceOf(Object)
}

MenuListCascade.defaultProps = {
  rootProps: {},
  backProps: {},
  listProps: {}
}
