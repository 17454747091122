"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useStyles = exports.snackBarStyles = exports.reactReaderStyles = exports.epubViewStyles = exports.alertStyles = void 0;
var _styles = require("@material-ui/core/styles");
var _reactReader = require("react-reader");
var _themes = require("../../themes");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
var useStyles = exports.useStyles = (0, _styles.makeStyles)(() => ({
  container: {
    position: 'relative',
    minHeight: '100vh',
    overflow: 'hidden'
  },
  readerContainer: {
    height: '100vh'
  },
  snackBar: {
    width: 450,
    position: 'absolute',
    zIndex: 1000,
    top: 24,
    right: 24
  }
}));
var reactReaderStyles = (backgroundStyle, isDesktopView, epubFile) => {
  var hexColor = _themes.EPUB_READER_THEMES[backgroundStyle].body.backgroundColor;
  var styles = _objectSpread(_objectSpread({}, _reactReader.ReactReaderStyle), {}, {
    readerArea: _objectSpread(_objectSpread({}, _reactReader.ReactReaderStyle.readerArea), {}, {
      backgroundColor: hexColor
    })
  });
  if (epubFile) {
    styles = _objectSpread(_objectSpread({}, styles), {}, {
      arrow: _objectSpread(_objectSpread({}, _reactReader.ReactReaderStyle.arrow), {}, {
        color: backgroundStyle === 'dark' ? 'rgba(255, 255, 255, 0.7)' : 'rgba(73, 80, 87, 1)'
      })
    });
  } else {
    styles = _objectSpread(_objectSpread({}, styles), {}, {
      arrow: {
        display: 'none'
      }
    });
  }
  if (!isDesktopView) {
    styles = _objectSpread(_objectSpread({}, styles), {}, {
      reader: _objectSpread(_objectSpread({}, _reactReader.ReactReaderStyle.reader), {}, {
        bottom: 68
      })
    });
  }
  return styles;
};
exports.reactReaderStyles = reactReaderStyles;
var epubViewStyles = exports.epubViewStyles = _objectSpread(_objectSpread({}, _reactReader.EpubViewStyle), {}, {
  view: _objectSpread(_objectSpread({}, _reactReader.EpubViewStyle.view), {}, {
    display: 'flex',
    justifyContent: 'center',
    height: '96%'
  })
});
var snackBarStyles = exports.snackBarStyles = (0, _styles.makeStyles)(theme => ({
  root: {
    [theme.breakpoints.up('md')]: {
      height: 40
    }
  }
}));
var alertStyles = exports.alertStyles = (0, _styles.makeStyles)(theme => ({
  root: {
    marginTop: 56,
    [theme.breakpoints.up('sm')]: {
      padding: '2px 16px',
      marginTop: 0
    },
    [theme.breakpoints.up('md')]: {
      height: 40
    }
  }
}));