/* eslint-disable */
import React , {useState, useEffect, useContext }from 'react'
import { Divider } from '@material-ui/core'
import useStyles from './stylesLastBooks'
import LastBookMode from '../LastBookMode'
import services from '../../../services'
import { BooksContext } from '../../../contexts/BooksContext'
import Loading from '../../../components/Loading'
import SnackBar from '../../../components/SnackBar'

export default function LastBooks({ lastBooks }) {
  const classes = useStyles()
  const {
    favoriteBook,
    unfavoriteBook,
    getFavoriteBooks
  } = useContext(BooksContext)
  const [alertMessage, setAlertMessage] = useState("Livro adicionado aos favoritos")
  const [showAlert, setShowAlert ] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [data, setData] = useState([])

  const onFavoriteClick = async (book, event, tracking) => {
    switch (event) {
      case "favorite":
          const favorite = await favoriteBook(book.uuid)
          if (favorite) {
            setAlertMessage('Livro adicionado aos favoritos')
            await services.Trackings.favoritesTracking(book, {event: tracking})
          }
      break;
      case "unfavorite":
        const data = await getFavoriteBooks(2)
        const id = data.find((item) => {
          return item.favoriteId === book.uuid
        })
        const unfavorite = await unfavoriteBook(id.id)
        if (unfavorite) {
          setAlertMessage('Livro removido dos favoritos')
        }
      break;
      default:
    }
    setShowAlert(true)
    setTimeout(async() => {
      setShowAlert(false)
    }, 800)
  }

  async function handleFavorites() {
    setIsLoading(true)
    const data = await getFavoriteBooks(1)
    setData(data)
    setIsLoading(false)
  }

  useEffect(() => {
    handleFavorites()
  },[])

  return (
    <>{showAlert && <SnackBar show={false} severity="success" variant="standart" noClose>{alertMessage}</SnackBar>}
      <h2 className={classes.recentOpened}>Continue lendo</h2>
      <Divider className={classes.divider} />
      <div className={classes.recentOpenedContainer}>
        {!isLoading && (
          <div style={{display:"flex", gap: 20, width: "100%"}}>
            {lastBooks.map((book, index) =>
              <div key={index}>
                <LastBookMode book={book} fluxo="Abertos Recentemente" favorites={data} favoriteClicked={(book, event, tracking) => onFavoriteClick(book, event, tracking)}/>
              </div>
            )}
          </div>
          )
        }
        {isLoading && (<Loading/>)}
      </div>
    </>
  )
}